<template>
  <div class="contacts">
    <v-container fluid>
      <v-card>
        <v-card-title>
          <v-row dense style="max-width:800px">
            <v-btn small color="primary" @click="form_id = 'new'" style='margin:10px'>New</v-btn>
            <v-col cols="12" sm="12" md="4">
              <v-text-field outlined dense v-model="search" append-icon="mdi-magnify" label="Search Contacts..." single-line hide-details autocomplete="off"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-select outlined dense v-model="filter" :items="['All Contacts', 'My Contacts']"></v-select>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table dense :mobile-breakpoint="0" no-data-text="No data available" :search="search" must-sort :headers="!isMobile ? headers : $_.filter(headers, {show_mobile: true})" :items="contacts_table_arr" :items-per-page="500" :footer-props="{'items-per-page-options': [25,50,100,500,-1]}" :height="$vuetify.breakpoint.height - 300 + 'px'" fixed-header>
          <template v-slot:item="row">
              <tr>
                <td><span class="table-link" v-on:click="$router.push({name: 'Contact', params: {contactId: row.item.id}})">{{row.item.name}}</span></td>
                <td v-if="!isMobile">{{row.item.email}}</br>{{row.item.phone}}</td>
                <td v-if="!isMobile"><span class="table-link" v-on:click="$router.push({name: 'Account', params: {accountId: row.item.account}})">{{row.item.account_name}}</span></td>
                <td v-if="!isMobile"><span class="table-link" v-on:click="$router.push({name: 'User', params: {userId: row.item.owner}})">{{row.item.owner_name}}</span></td>
              </tr>
          </template>
        </v-data-table>

      </v-card>
    </v-container>

    <ContactForm v-if="form_id" :id="form_id" @close="buildTable()" />

  </div>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import ContactForm from '../components/forms/ContactForm.vue'

export default {
  name: 'contacts',
  components: {ContactForm},
  data(){
    return{
      form_id: null,
      search: '',
      filter: 'All Contacts',
      headers: [
        { text: 'Contact Name', align: 'start', value: 'name', show_mobile: true},
        { text: 'Contact Info', value: 'email', sortable: false},
        { text: 'Account Name', value: 'account_name'},
        { text: 'Contact Owner', value: 'owner_name' }
      ],
      contacts_table_arr: []
    }
  },
  beforeMount(){
    var stored_filter = this.$store.state.contacts_table_filter
    if(stored_filter){
      this.filter = stored_filter
    }else{
      var user_role = this.db.users[this.current_user].role
      if(user_role === 'Administrator'){
        this.filter = 'All Contacts'
      }
      if(user_role === 'Sales'){
        this.filter = 'My Contacts'
      }
      if(user_role === 'Estimating'){
        this.filter = 'All Contacts'
      }
    }
  },
  mounted(){
    this.buildTable()
  },
  watch: {
    'filter': function (val) {
      this.buildTable()
      this.$store.state.contacts_table_filter = val
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      active_contacts: 'active_contacts',
      isMobile: 'isMobile'
    }),
  },
  methods:{
    buildTable(){
      var self = this
      self.form_id = null
      self.contacts_table_arr = []
      Firebase.database().ref('contacts').once('value',function(snapshot){
        var contacts_db_clone = JSON.parse( JSON.stringify( snapshot.val() ) )
        for(var contact_id in contacts_db_clone){
          var contact_object = contacts_db_clone[contact_id]
          var table_row = {}
          if(!contact_object.disabled){
            table_row.name = contact_object.last_name + ', ' + contact_object.first_name
            table_row.id = contact_id
            table_row.email = contact_object.email
            table_row.phone = contact_object.phone
            table_row.account = contact_object.account
            table_row.account_name = self.db.accounts[contact_object.account].name
            table_row.owner = contact_object.owner
            table_row.owner_name = self.db.users[contact_object.owner].first_name + ' ' + self.db.users[contact_object.owner].last_name
            if(self.filter === 'All Contacts' || (self.filter === 'My Contacts' && table_row.owner === self.current_user)){
              self.contacts_table_arr.push(table_row)
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>


