<template>
  <div class="users">
    <v-container fluid>
      <v-card>

        <v-card-title>
          <v-row dense style="max-width:800px">
            <v-btn small color="primary" @click="form_id = 'new'" style='margin:10px'>New</v-btn>
            <v-col cols="12" sm="12" md="4">
              <v-text-field outlined dense v-model="search" append-icon="mdi-magnify" label="Search Users..." single-line hide-details autocomplete="off"></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <v-switch v-model="show_only_active_users" label="Show Only Active Users" style="margin:0px 20px;width:250px;"></v-switch>

        <v-data-table dense :mobile-breakpoint="0" :search="search" must-sort :headers="!isMobile ? headers : $_.filter(headers, {show_mobile: true})" :items="filtered_users" :items-per-page="-1" hide-default-footer :footer-props="{'items-per-page-options': [25,50,100,500,-1]}" :height="$vuetify.breakpoint.height - 264 + 'px'" fixed-header>

          <template v-slot:item="row">
              <tr>
                <td :class="{'inactive': row.item.disabled}">
                  <span class="table-link" v-on:click="$router.push({name: 'User', params: {userId: row.item.id}})" v-if="!row.item.disabled">{{row.item.name}}</span>
                  <span v-if="row.item.disabled">{{row.item.name}}</span>
                </td>
                <td :class="{'inactive': row.item.disabled}" v-if="!isMobile">{{row.item.email}}</td>
                <td :class="{'inactive': row.item.disabled}" v-if="!isMobile">{{row.item.role}}</td>

                <td v-if="!row.item.disabled">
                  <v-tooltip right color="#223773">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-icon v-bind="attrs" v-on="on">
                        <v-icon style="cursor:pointer" @click="disableUser(row.item.id)" v-if="row.item.id !== current_user">mdi-trash-can</v-icon>
                      </v-list-item-icon>
                    </template>
                    <span>Deactivate User</span>
                  </v-tooltip>
                </td>

                <td v-if="row.item.disabled">
                  <v-tooltip right color="#223773" style="height:20px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-icon v-bind="attrs" v-on="on">
                        <v-icon style="cursor:pointer" @click="enableUser(row.item.id)">mdi-restore</v-icon>
                      </v-list-item-icon>
                    </template>
                    <span>Reactivate User</span>
                  </v-tooltip>
                </td>

              </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>


    <UserForm v-if="form_id" :id="form_id" @close="form_id = null" />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UserForm from '../components/forms/UserForm.vue'
import axios from 'axios'
import qs from 'qs'

export default {
  name: 'users',
  components: {UserForm},
  data(){
    return{
      show_only_active_users: true,
      form_id: null,
      search: '',
      filter: 'All Users',
      headers: [
        { text: 'Name', align: 'start', value: 'name'},
        { text: 'Email', value: 'email' },
        { text: 'Role', value: 'role' },
        { text: '', value: '', sortable: false}
      ]
    }
  },
  beforeMount(){
    if(!this.is_admin){
      this.$router.push('/home')
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      active_users: 'active_users',
      inactive_users: 'inactive_users',
      all_users: 'all_users',
      isMobile: 'isMobile',
      users_function_url: 'users_function_url',
      is_admin: 'is_admin'
    }),
    filtered_users: function(){
      return this.show_only_active_users ? this.active_users : this.all_users
    }
  },
  methods:{
    disableUser(uid){
      var self = this
      var url = self.users_function_url+'disableUser?uid='+uid
      var headers = {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'}
      var confirm_disable = confirm('Are you sure you want to disable access for ' + self.db.users[uid].first_name + ' ' + self.db.users[uid].last_name + '?')

      if(!confirm_disable){return}

      self.$store.state.isLoading = true

      axios.post(url, {headers})
      .then(function (response) {
        self.$store.state.isLoading = false
        if(response.data.success){
          self.$store.state.notification = response.data.success.message
        }else if(response.data.error){
          self.$store.state.notification = response.data.error.message
        }else{
          self.$store.state.notification = 'Unknown error disabling user.'
        }
      })
      .catch(function (error) {
        self.$store.state.isLoading = false
        self.$store.state.notification = 'Unknown error disabling user.'
      });
    },
    enableUser(uid){
      var self = this
      var url = self.users_function_url+'enableUser?uid='+uid
      var headers = {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'}
      var confirm_disable = confirm('Are you sure you want to reactivate access for ' + self.db.users[uid].first_name + ' ' + self.db.users[uid].last_name + '?')

      if(!confirm_disable){return}

      self.$store.state.isLoading = true

      axios.post(url, {headers})
      .then(function (response) {
        self.$store.state.isLoading = false
        if(response.data.success){
          self.$store.state.notification = response.data.success.message
        }else if(response.data.error){
          self.$store.state.notification = response.data.error.message
        }else{
          self.$store.state.notification = 'Unknown error reactivating user.'
        }
      })
      .catch(function (error) {
        self.$store.state.isLoading = false
        self.$store.state.notification = 'Unknown error reactivating user.'
      });
    }
  }
}
</script>

<style lang="scss" scoped>

  .v-list-item__icon{height: 0px !important}

  .inactive{
    color: #C0C0C0;
  }
</style>


