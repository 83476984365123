<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-body" style="padding:20px">

              <div class="header">Please enter your email address.</div>

              <v-text-field v-model="email" dense outlined autocomplete="off" v-on:keyup="clearError" :rules="emailRules" label="Email" required></v-text-field>

              <div style="font-size:14px;line-height:100%;min-height:25px;">{{feedback_message}}</div>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#243872" small class="mr-0 white--text" @click="$emit('close')">CANCEL</v-btn>
              <v-btn color="#243872" small class="mr-0 white--text" @click="submitPasswordReset()">SUBMIT</v-btn>
            </v-card-actions>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<script>
import Firebase from 'firebase/app'
export default {
    name: 'forgot_password',
    components: {},
    props: {

    },
    data(){
      return {
        email: '',
        feedback_message: '',
        emailRules: [
          v => !!v || 'Email is required',
          v => /.+@.+\..+/.test(v) || 'Email must be valid',
        ],
      }
    },
    computed: {

    },
    mounted(){

    },
    methods: {
      clearError(event){
        event.key !== 'Enter' ? this.feedback_message = null : false
      },
      submitPasswordReset(){
        var self = this

        Firebase.auth().sendPasswordResetEmail(self.email).then(function() {
          self.$store.state.notification = 'A reset password link has been emailed to ' + self.email + '. If you do not receive email please check your spam folder.'
          self.$emit('close')
        }).catch(function(error) {
          self.feedback_message = 'Error: The specified user does not exist.';
          // vm.email = null
        });
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "../styles/global.scss";

  .modal-mask {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    display: table;
  }

  .modal-wrapper {
    position: relative;
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    position: relative;
    max-width: 550px;
    margin: 0px auto;
    padding: 20px 10px 10px 10px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    font-family: Helvetica, Arial, sans-serif;
    overflow: hidden;
  }

  .modal-body {
    text-align: left;
    font-size: 18px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    line-height: 120%;
    padding-bottom: 20px;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .header{
    margin-bottom: 20px;
  }
</style>
