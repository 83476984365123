<template>
  <v-dialog scrollable persistent v-model="dialog" width="500px">
    <v-form ref="form" lazy-validation @keydown="$event.keyCode === 13 ? $event.preventDefault() : false">
      <v-card>
        <div class="form-header">Save New Report</div>

        <v-card-text>
            <v-card>
              <v-card-text style="text-align:left">
                <v-text-field dense @focus="$event.target.select()" v-model="report_object.name" hide-details="auto" label="Report Name" outlined v-on:keydown.enter.prevent=''></v-text-field>
              </v-card-text>

              <v-radio-group v-model="report_object.access" style="width:100px;margin-left:20px">
                <v-radio label="Public" value="Public"></v-radio>
                <v-radio label="Private" value="Private"></v-radio>
              </v-radio-group>

            </v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('close')" small>CANCEL</v-btn>
          <v-btn color="primary" @click="$emit('save',report_object)" small :disabled="!report_object.name.trim()">SAVE</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SaveReport',
  components: {},
  mixins: [],
  data(){
    return{
      dialog: true,
      report_object: {
        name: '',
        access: 'Public'
      }
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user'
    })
  }
}
</script>

<style lang="scss">

</style>


