<template>
  <v-dialog scrollable persistent v-model="dialog" width="100%">
    <v-form ref="form" lazy-validation>
      <v-card style="background:#eeeeee" v-if="form_mounted">
        <div class="form-header">Civil Quote Sheet</div>
        <v-card-text>
          <!-- PREVIOUS VERSIONS -->
           <v-card style="margin-top:20px" v-if="quote_archive && !isNew">
            <v-card-text style="text-align:left">
              <div class="section-header">PREVIOUS VERSIONS</div>

                <v-simple-table dense style="width:600px;">
                  <tbody>
                    <tr v-for="(archived_quote) in sorted_archive">
                      <td style="width:75px" v-if="view_version !== archived_quote.key"><v-btn color="primary" @click="viewPreviousVersion(archived_quote.key)" x-small>VIEW</v-btn></td>
                      <td style="width:75px;text-align:center" v-if="view_version === archived_quote.key">viewing</td>
                      <td>{{archived_quote.quote_title}} (created on {{formatDate(archived_quote.created)}})</td>
                    </tr>
                  </tbody>
                </v-simple-table>
            </v-card-text>
          </v-card>

          <!-- GENERAL -->
          <v-card class="section">
            <v-card-text>
              <div class="section-header">GENERAL</div>
              <v-row>
                <v-col cols="6">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Quote Title*" outlined v-model="quote.quote_title"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select dense hide-details="auto" :items="ou_departments" item-text="department" outlined label="Department*" v-model="quote.department">
                    <template slot="selection" slot-scope="data">{{data.item.department}} ({{ data.item.code }})</template>
                    <template slot="item" slot-scope="data">{{data.item.department}} ({{ data.item.code }})</template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-autocomplete dense hide-details="auto" :items="customers" outlined label="Customer*" v-model="quote.customer" @change="setLabor()"></v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-select dense hide-details="auto" :items="work_schedule" outlined label="Work Schedule" v-model="quote.work_schedule"></v-select>
                </v-col>
                <v-col cols="4">
                  <v-select dense hide-details="auto" :items="work_hours" outlined label="Work Hours" v-model="quote.work_hours"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-textarea dense hide-details="auto" label="Scope of Work" outlined v-model="quote.scope_of_work" auto-grow></v-textarea>
                </v-col>
                <v-col cols="6">
                  <v-textarea dense hide-details="auto" label="Clarifications" outlined v-model="quote.clarifications" auto-grow></v-textarea>
                </v-col>
              </v-row>

              <hr>

              <v-row dense>
                <v-col cols="6">
                  <v-checkbox dense label="Use Master Phase" hide-details v-model="quote.use_master_phase"></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense v-if="quote.use_master_phase">
                <v-col cols="2">
                  <v-select dense hide-details="auto" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Master Phase" v-model="quote.master_phase"></v-select></v-col>
                </v-col>
              </v-row>

              <hr>

              <v-row dense>
                <v-col cols="6">
                  <v-checkbox dense label="Use Master Markup % (Materials, Rentals, Subcontractors)" hide-details v-model="quote.use_master_markup"></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense v-if="quote.use_master_markup">
                <v-col cols="2">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Master Markup %" min="0" outlined v-model.number="quote.master_markup_percentage" @change="" suffix="%"></v-text-field>
                </v-col>
              </v-row>

              <hr>
              <v-row>
                <v-col cols="3">
                  <v-checkbox dense label="Quote Completed" hide-detail v-model="quote.completed" style="margin:0px 0px 0px 10px"></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-checkbox dense label="Quote Reviewed" hide-detail v-model="quote.reviewed" style="margin:0px 0px 0px 10px"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- LABOR -->
          <v-card class="section">
            <v-card-text>
              <div class="section-header">LABOR</div>

              <draggable v-model="quote.tasks" handle=".handle">
                <transition-group>
                  <div v-for="(task, index) in quote.tasks" :key="index" style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:20px;position:relative;background:white">
                    <v-icon large class="handle">mdi-reorder-horizontal</v-icon>
                    <v-btn color="primary" @click="deleteTask(index)" x-small fab style="position:absolute;top:5px;right:5px;width:20px;height:20px;"><v-icon x-small>mdi-close</v-icon></v-btn>
                    <v-row style='margin:0px'>
                      <v-col cols="6"><v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Description" outlined v-model="quote.tasks[index].description" style=""></v-text-field></v-col>
                      <v-col cols="2"><v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Duration" class="number-field" min="0" v-model.number="quote.tasks[index].lead_time_shifts" @change="setLabor()"></v-text-field></v-col>
                      <v-col cols="2"><v-select dense hide-details="auto" :items="['Day','Night']" outlined label="Day or Night?" v-model="quote.tasks[index].day_night" @change="setLabor()"></v-select></v-col>
                      <v-col cols="2"><v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.tasks[index].phase" @change="setLabor()"></v-select></v-col>
                      <!-- <v-col cols="2" v-if="quote.use_master_phase" style="font-weight: bold">Phase {{quote.master_phase}}</v-col> -->
                    </v-row>
                    <v-simple-table>
                      <thead>
                        <td style="width:220px"></td>
                        <td>Number of Employees Per Shift</td>
                        <td>Standard Time</td>
                        <td>Overtime</td>
                        <td>Double Time</td>
                        <td>Number of Shifts</td>
                        <td>Total Hours</td>
                        <td>Total Labor Cost</td>
                        <td>Total Labor Revenue</td>
                      </thead>
                      <tbody >
                        <tr v-for="(role,role_id) in tasks_roles" v-show="quote.tasks[index].show_task_detail">
                          <td>
                            <div v-if="!role_id.includes('specialty_craft')">{{role.name}}</div>
                            <div style="margin:5px"><v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" v-if="role_id.includes('specialty_craft')" v-model="quote.tasks[index][role_id].name" @change="setLabor()"></v-text-field></div>
                            <div style="margin-left:20px;color:#00000099">ST Revenue Rate: <span class="editable-rate" @click="editSpecialtyCraftRate(index, role_id ,'st')" v-if="role_id.includes('specialty_craft')">{{formatCurrency(quote.tasks[index][role_id].revenue_rate_st)}}</span><span v-if="!role_id.includes('specialty_craft')">{{formatCurrency(quote.rates.labor_revenue_rates[quote.customer+'ST'][role_id])}}</span></div>
                            <div style="margin-left:20px;color:#00000099">OT Revenue Rate: <span class="editable-rate" @click="editSpecialtyCraftRate(index, role_id ,'ot')" v-if="role_id.includes('specialty_craft')">{{formatCurrency(quote.tasks[index][role_id].revenue_rate_ot)}}</span><span v-if="!role_id.includes('specialty_craft')">{{formatCurrency(quote.rates.labor_revenue_rates[quote.customer+'OT'][role_id])}}</span></div>
                            <div style="margin-left:20px;color:#00000099">DT Revenue Rate: <span class="editable-rate" @click="editSpecialtyCraftRate(index, role_id ,'dt')" v-if="role_id.includes('specialty_craft')">{{formatCurrency(quote.tasks[index][role_id].revenue_rate_dt)}}</span><span v-if="!role_id.includes('specialty_craft')">{{formatCurrency(quote.rates.labor_revenue_rates[quote.customer+'DT'][role_id])}}</span></div>
                          </td>

                          <td><v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" class="number-field" min="0" v-model.number="quote.tasks[index][role_id].employees_per_shift" @change="setLabor()"></v-text-field></td>
                          <td><v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" class="number-field" min="0" v-model.number="quote.tasks[index][role_id].standard_time" @change="setLabor()"></v-text-field></td>
                          <td><v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" class="number-field" min="0" v-model.number="quote.tasks[index][role_id].overtime" @change="setLabor()"></v-text-field></td>
                          <td><v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" class="number-field" min="0" v-model.number="quote.tasks[index][role_id].double_time" @change="setLabor()"></v-text-field></td>
                          <td><v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" class="number-field" min="0" v-model.number="quote.tasks[index][role_id].number_of_shifts" @change="setLabor()"></v-text-field></td>

                          <td style="text-align:center;font-weight:bold">{{quote.tasks[index][role_id].hours}}</td>
                          <td style="text-align:center;font-weight:bold">{{formatCurrency(quote.tasks[index][role_id].cost)}}</td>
                          <td style="text-align:center;font-weight:bold">{{formatCurrency(quote.tasks[index][role_id].revenue)}}</td>
                        </tr>
                        <tr>
                          <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                          <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.tasks[index].total_employees_per_shift}}</td>
                          <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.tasks[index].total_standard_time}}</td>
                          <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.tasks[index].total_overtime}}</td>
                          <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.tasks[index].total_double_time}}</td>
                          <td style="font-weight:bold;border-top:1px solid;text-align:center"></td>
                          <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.tasks[index].total_number_of_hours}}</td>
                          <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.tasks[index].total_cost)}}</td>
                          <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.tasks[index].total_revenue)}}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>

                    <div style="float:right"><v-btn x-small color="primary" style="margin-bottom:10px" @click="cloneTask(index)">CLONE TASK</v-btn></div>
                    <div><v-btn color="white" @click="quote.tasks[index].show_task_detail = !quote.tasks[index].show_task_detail" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-{{!quote.tasks[index].show_task_detail ? 'plus' : 'minus'}}</v-icon>{{!quote.tasks[index].show_task_detail ? 'SHOW' : 'HIDE'}} DETAIL</v-btn></div>
                  </div>
                </transition-group>
              </draggable>

              <!-- <div v-if="quote.tasks.length"><v-btn color="white" @click="show_all_tasks_detail = !show_all_tasks_detail" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-{{!show_all_tasks_detail ? 'plus' : 'minus'}}</v-icon>{{!show_all_tasks_detail ? 'SHOW' : 'HIDE'}} ALL TASK DETAIL</v-btn></div> -->
              <div v-if="quote.tasks.length > 1">
                <v-btn v-if="hiddenTaskDetail" color="white" @click="showAllTasksDetail()" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-plus</v-icon>SHOW ALL TASK DETAIL</v-btn>
                <v-btn v-if="shownTaskDetail" color="white" @click="hideAllTasksDetail()" x-small style="width:215px;margin-bottom:10px;margin-left:10px;color:#223773"><v-icon small class="mr-1">mdi-minus</v-icon>HIDE ALL TASK DETAIL</v-btn>
              </div>


              <v-btn color="primary" @click="addTask()" small>ADD TASK</v-btn>
            </v-card-text>
          </v-card>

          <!-- MATERIAL -->
          <v-card class="section">
            <v-card-text>
              <div class="section-header">MATERIAL</div>

              <div style="width:200px;margin:10px 0px">
                <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Material Markup %" min="0" outlined v-model.number="quote.material_markup_percentage" suffix="%" :disabled="quote.use_master_markup"></v-text-field>
              </div>

              <div style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:10px 10px;position:relative;">
                <div style="width:250px;"><v-checkbox dense label="Small Tools & Consumables" v-model="quote.require_small_tools_consumables" @change="setToolsAndConsumables()"></v-checkbox></div>
                <v-simple-table dense style="padding-top:25px;width:300px;" v-if="quote.require_small_tools_consumables">
                  <thead>
                    <td style="text-align:left"></td>
                    <td style="text-align:left;font-weight:bold">Cost</td>
                    <td style="text-align:left;font-weight:bold">Revenue</td>
                  </thead>
                  <tbody>
                    <tr v-if="quote.small_tools_consumables_material[phase].cost > 0" v-for="phase in ['phase_1','phase_2','phase_3','phase_4','phase_5','phase_6','phase_7','phase_8','phase_9','phase_10','phase_11','phase_12','phase_13','phase_14','phase_15','phase_16','phase_17','phase_18','phase_19','phase_20','phase_21','phase_22','phase_23','phase_24','phase_25','phase_26','phase_27','phase_28','phase_29','phase_30','phase_31','phase_32','phase_33','phase_34','phase_35','phase_36','phase_37','phase_38','phase_39','phase_40','phase_41','phase_42','phase_43','phase_44','phase_45','phase_46','phase_47','phase_48','phase_49','phase_50']">
                      <td style="padding:0px;">Phase {{phase.split('_')[1]}}</td>
                      <td style="padding:0px">{{formatCurrency(quote.small_tools_consumables_material[phase].cost)}}</td>
                      <td style="padding:0px">{{formatCurrency(quote.small_tools_consumables_material[phase].revenue)}}</td>
                    </tr>
                    <tr>
                      <td style="padding:0px;border-top:2px solid"></td>
                      <td style="padding:0px;font-weight:bold;border-top:2px solid">{{formatCurrency(quote.small_tools_consumables_material.total_cost)}}</td>
                      <td style="padding:0px;font-weight:bold;border-top:2px solid">{{formatCurrency(quote.small_tools_consumables_material.total_revenue)}}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <div style="margin-top:25px;font-size:12px;line-height:120%" v-if="quote.require_small_tools_consumables">Small Tools Rate: <span class="editable-rate" @click="editQuoteRate(['small_tool_consumables_rates','small_tools_cost_rate'])">{{quote.rates.small_tool_consumables_rates.small_tools_cost_rate}}%</span> of Labor Revenue</div>
                <div style="font-size:12px;line-height:120%" v-if="quote.require_small_tools_consumables">Consumables Rate: <span class="editable-rate" @click="editQuoteRate(['small_tool_consumables_rates','consumables_cost_rate'])">{{quote.rates.small_tool_consumables_rates.consumables_cost_rate}}%</span> of Labor Revenue</div>
              </div>

              <div v-for="(material, index) in quote.material" style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:30px 20px;position:relative">
                <v-btn color="primary" @click="deleteMaterial(index)" x-small fab style="position:absolute;top:5px;right:5px;width:20px;height:20px;"><v-icon x-small>mdi-close</v-icon></v-btn>
                <v-row dense>
                  <template v-if="quote.material[index].description !== 'Other'">
                    <v-col cols="4">
                      <v-autocomplete v-model="quote.material[index].description" :items="material_arr" item-text="item" label="Material" dense outlined hide-details="auto" attach @change="setMaterial()"></v-autocomplete>
                      <div v-if="quote.material[index].description">Price Per Unit: <span class="editable-rate" @click="editQuoteRate(['material_rates',quote.material[index].material_id, 'cost'])">{{formatCurrency(quote.material[index].item_cost)}}</span></div>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Quantity" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.material[index].quantity" @change="setMaterial()"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-select dense :disabled="quote.use_master_phase" hide-details="auto" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.material[index].phase" @change="setMaterial()"></v-select>
                    </v-col>
                    <v-col cols="1" style="text-align:right">
                      <div style="line-height:100%">Total Cost</div>
                      {{formatCurrency(quote.material[index].total_cost)}}
                    </v-col>
                    <v-col cols="1" style="text-align:right">
                      <div style="line-height:100%">Total Revenue</div>
                      {{formatCurrency(quote.material[index].total_revenue)}}
                    </v-col>
                  </template>
                  <template v-if="quote.material[index].description === 'Other'">
                    <v-col cols="3">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Other Material" outlined v-model="quote.material[index].other_name"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Vendor" outlined v-model="quote.material[index].vendor"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Price Per Unit" prefix="$" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0"  v-model.number="quote.material[index].item_cost" @change="setMaterial()"></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Quantity" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.material[index].quantity" @change="setMaterial()"></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.material[index].phase" @change="setMaterial()"></v-select>
                    </v-col>
                    <v-col cols="1" style="text-align:right">
                      <div style="line-height:100%">Total Cost</div>
                      {{formatCurrency(quote.material[index].total_cost)}}
                    </v-col>
                    <v-col cols="1" style="text-align:right">
                      <div style="line-height:100%">Total Revenue</div>
                      {{formatCurrency(quote.material[index].total_revenue)}}
                    </v-col>
                  </template>
                </v-row>
              </div>
              <v-btn color="primary" @click="addMaterial()" small>ADD MATERIAL</v-btn>
            </v-card-text>
          </v-card>

          <!-- EQUIPMENT -->
          <v-card class="section">
            <v-card-text>
              <div class="section-header">EQUIPMENT</div>
              <v-row>
                <v-col cols="6">
                  <div style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:10px 10px;position:relative;height:275px">
                    <div>Fuel Requirements</div>
                    <v-simple-table style="padding-top:25px">
                      <thead>
                        <td style="width:150px"></td>
                        <td style="width:150px">Hours Required</td>
                        <td style="width:150px">Phase</td>
                        <td style="width:150px">Total Cost</td>
                        <td style="width:150px">Total Revenue</td>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Welding
                            <div style="color:gray;line-height:100%;font-size:10px">Cost Per Hour: <span class="editable-rate" @click="editQuoteRate(['fuel_cost_rates','welding'])">{{formatCurrency(quote.rates.fuel_cost_rates.welding)}}</span></div>
                          </td>
                          <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" label="" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.welding_fuel_hours" @change="setFuel()"></v-text-field></td>
                          <td><v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="" v-model="quote.welding_fuel_phase"></v-select></td>
                          <td>{{formatCurrency(quote.welding_fuel_cost)}}</td>
                          <td>{{formatCurrency(quote.welding_fuel_revenue)}}</td>
                        </tr>
                        <tr>
                          <td>
                            Forklift
                            <div style="color:gray;line-height:100%;font-size:10px">Cost Per Hour: <span class="editable-rate" @click="editQuoteRate(['fuel_cost_rates','forklift'])">{{formatCurrency(quote.rates.fuel_cost_rates.forklift)}}</span></div>
                          </td>
                          <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" label="" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()"  min="0" v-model.number="quote.forklift_fuel_hours" @change="setFuel()"></v-text-field></td>
                          <td><v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="" v-model="quote.forklift_fuel_phase"></v-select></td>
                          <td>{{formatCurrency(quote.forklift_fuel_cost)}}</td>
                          <td>{{formatCurrency(quote.forklift_fuel_revenue)}}</td>
                        </tr>
                        <tr>
                          <td>
                            Air Compressor
                            <div style="color:gray;line-height:100%;font-size:10px">Cost Per Hour: <span class="editable-rate" @click="editQuoteRate(['fuel_cost_rates','air_compressor'])">{{formatCurrency(quote.rates.fuel_cost_rates.air_compressor)}}</span></div>
                          </td>
                          <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" label="" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()"  min="0" v-model.number="quote.air_compressor_fuel_hours" @change="setFuel()"></v-text-field></td>
                          <td><v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="" v-model="quote.air_compressor_fuel_phase"></v-select></td>
                          <td>{{formatCurrency(quote.air_compressor_fuel_cost)}}</td>
                          <td>{{formatCurrency(quote.air_compressor_fuel_revenue)}}</td>
                        </tr>
                        <tr>
                          <td>
                            Manlift
                            <div style="color:gray;line-height:100%;font-size:10px">Cost Per Hour: <span class="editable-rate" @click="editQuoteRate(['fuel_cost_rates','manlift'])">{{formatCurrency(quote.rates.fuel_cost_rates.manlift)}}</span></div>
                          </td>
                          <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" label="" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()"  min="0" v-model.number="quote.manlift_fuel_hours" @change="setFuel()"></v-text-field></td>
                          <td><v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="" v-model="quote.manlift_fuel_phase"></v-select></td>
                          <td>{{formatCurrency(quote.manlift_fuel_cost)}}</td>
                          <td>{{formatCurrency(quote.manlift_fuel_revenue)}}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:0px 10px;position:relative;height:275px">
                    <div style="width:200px;"><v-checkbox dense label="Truck Requirements" v-model="quote.require_trucks" @change="setTruckRequirements()"></v-checkbox></div>

                    <v-simple-table style="padding-top:25px">
                      <thead>
                        <td style="width:250px"></td>
                        <td style="width:115px">Trucks</td>
                        <td style="width:115px">Mileage Per Round Trip</td>
                        <td style="width:115px">Total Cost</td>
                        <td style="width:115px">Total Revenue</td>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Pickup Truck
                            <div style="color:gray;line-height:150%;font-size:10px">Revenue Per Truck: <span class="editable-rate" @click="editQuoteRate(['truck_rates','pickup_truck_revenue_per_truck'])">{{formatCurrency(quote.rates.truck_rates.pickup_truck_revenue_per_truck)}}</span></div>
                            <div style="color:gray;line-height:150%;font-size:10px">Revenue Per Mile: <span class="editable-rate" @click="editQuoteRate(['truck_rates','pickup_truck_revenue_per_mile'])">{{formatCurrency(quote.rates.truck_rates.pickup_truck_revenue_per_mile)}}</span></div>
                            <div style="color:gray;line-height:150%;font-size:10px">Cost Per Mile: <span class="editable-rate" @click="editQuoteRate(['truck_rates','pickup_truck_cost_per_mile'])">{{formatCurrency(quote.rates.truck_rates.pickup_truck_cost_per_mile)}}</span></div>
                          </td>
                          <td>{{quote.pickup_trucks.total_trucks}}</td>
                          <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" label="" :disabled="!quote.require_trucks" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.pickup_trucks.total_mileage" @change="setTruckRequirements()"></v-text-field></td>
                          <td>{{formatCurrency(quote.pickup_trucks.total_cost)}}</td>
                          <td>{{formatCurrency(quote.pickup_trucks.total_revenue)}}</td>
                        </tr>
                        <tr>
                          <td>
                            Service Truck
                            <div style="color:gray;line-height:150%;font-size:10px">Revenue Per Truck: <span class="editable-rate" @click="editQuoteRate(['truck_rates','service_truck_revenue_per_truck'])">{{formatCurrency(quote.rates.truck_rates.service_truck_revenue_per_truck)}}</span></div>
                            <div style="color:gray;line-height:150%;font-size:10px">Revenue Per Mile: <span class="editable-rate" @click="editQuoteRate(['truck_rates','service_truck_revenue_per_mile'])">{{formatCurrency(quote.rates.truck_rates.service_truck_revenue_per_mile)}}</span></div>
                            <div style="color:gray;line-height:150%;font-size:10px">Cost Per Mile: <span class="editable-rate" @click="editQuoteRate(['truck_rates','service_truck_cost_per_mile'])">{{formatCurrency(quote.rates.truck_rates.service_truck_cost_per_mile)}}</span></div>
                          </td>
                          <td>{{quote.service_trucks.total_trucks}}</td>
                          <td><v-text-field dense @focus="$event.target.select()" hide-details="auto" label="" :disabled="!quote.require_trucks" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.service_trucks.total_mileage" @change="setTruckRequirements()"></v-text-field></td>
                          <td>{{formatCurrency(quote.service_trucks.total_cost)}}</td>
                          <td>{{formatCurrency(quote.service_trucks.total_revenue)}}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </div>
                </v-col>
              </v-row>

              <v-row style="width: 500px">
                <!-- <v-col sm="6"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" label="Mid-State Equipment Markup %" outlined v-model.number="quote.mid_state_equipment_markup_percentage" @change="setEquipment()" suffix="%"></v-text-field></v-col> -->
                <v-col sm="6"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" label="Rental Markup %" outlined v-model.number="quote.rental_equipment_markup_percentage" :disabled="quote.use_master_markup" suffix="%"></v-text-field></v-col>
              </v-row>

              <v-row style="width: 500px" v-if="quote.mid_state_equipment_markup_percentage">
                <v-col sm="6" dense>Mid-State Equipment Markup: {{quote.mid_state_equipment_markup_percentage}}%</v-col>
              </v-row>

              <div v-for="(equipment, index) in quote.equipment" style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:30px 20px;position:relative">
                <v-btn color="primary" @click="deleteEquipment(index)" x-small fab style="position:absolute;top:5px;right:5px;width:20px;height:20px;"><v-icon x-small>mdi-close</v-icon></v-btn>
                <v-row dense>
                  <template v-if="quote.equipment[index].description !== 'Other'">
                    <v-col cols="3">
                      <v-autocomplete v-model="quote.equipment[index].description" :items="equipment_arr" item-text="item" label="Equipment" dense outlined hide-details="auto" attach @change="setEquipment()"></v-autocomplete>
                      <div v-if="quote.equipment[index].description">Owner: {{quote.equipment[index].owner}}</div>
                    </v-col>

                    <v-col cols="2">
                      <v-select dense hide-details="auto" :items="['Daily','Weekly','Monthly']" outlined label="Rate Type" v-model="quote.equipment[index].rate_type" @change="setEquipment()"></v-select>
                      <div v-if="quote.equipment[index].description">{{quote.equipment[index].rate_type}} Cost: <span class="editable-rate" @click="editQuoteRate(['equipment_rates',quote.equipment[index].equipment_id, 'cost' + '_' + quote.equipment[index].rate_type.toLocaleLowerCase()])">{{formatCurrency(quote.equipment[index].item_cost)}}</span></div>
                    </v-col>

                    <v-col cols="2" v-if="quote.equipment[index].owner === 'United'">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="In/Out Charges" prefix="$" min="0" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model.number="quote.equipment[index].in_out_charges" @change="setEquipment()"></v-text-field>
                    </v-col>

                    <v-col cols="2" v-if="quote.equipment[index].owner !== 'United'">

                    </v-col>

                    <v-col cols="1">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Quantity" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.equipment[index].quantity" @change="setEquipment()"></v-text-field>
                    </v-col>

                    <v-col cols="1">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Duration" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.equipment[index].duration" @change="setEquipment()"></v-text-field>
                    </v-col>

                    <v-col cols="1">
                      <v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.equipment[index].phase"></v-select>
                    </v-col>

                    <v-col cols="1" style="text-align:right">
                      <div style="line-height:100%">Total Cost</div>
                      {{formatCurrency(quote.equipment[index].total_cost)}}
                    </v-col>

                    <v-col cols="1" style="text-align:right">
                      <div style="line-height:100%">Total Revenue</div>
                      {{formatCurrency(quote.equipment[index].total_revenue)}}
                    </v-col>

                  </template>

                  <template v-if="quote.equipment[index].description === 'Other'">
                    <v-row dense>
                      <v-col cols="4">
                        <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Other Equipment" outlined v-model="quote.equipment[index].other_name"></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Price Per Unit" prefix="$" min="0" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model.number="quote.equipment[index].item_cost" @change="setEquipment()"></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-select dense hide-details="auto" :items="['Daily','Weekly','Monthly','Lot']" outlined label="Rate Type" v-model="quote.equipment[index].rate_type" @change="setEquipment()"></v-select>
                      </v-col>

                      <v-col cols="2">
                        <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="In/Out Charges" prefix="$" min="0" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model.number="quote.equipment[index].in_out_charges" @change="setEquipment()"></v-text-field>
                      </v-col>

                      <v-col cols="1">
                        <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Quantity" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.equipment[index].quantity" @change="setEquipment()"></v-text-field>
                      </v-col>

                      <v-col cols="1">
                        <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Duration" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.equipment[index].duration" @change="setEquipment()"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="4">
                        <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Vendor" outlined v-model="quote.equipment[index].vendor"></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.equipment[index].phase" @change="setEquipment()"></v-select>
                      </v-col>
                      <v-col cols="2"></v-col>
                      <v-col cols="2"></v-col>
                      <v-col cols="1" style="text-align:right">
                        <div style="line-height:100%">Total Cost</div>
                        {{formatCurrency(quote.equipment[index].total_cost)}}
                      </v-col>
                      <v-col cols="1" style="text-align:right">
                        <div style="line-height:100%">Total Revenue</div>
                        {{formatCurrency(quote.equipment[index].total_revenue)}}
                      </v-col>
                    </v-row>
                  </template>
                </v-row>
              </div>

              <v-btn color="primary" @click="addEquipment()" small>ADD EQUIPMENT</v-btn>
            </v-card-text>
          </v-card>

          <!-- SUBCONTRACTORS -->
          <v-card class="section">
            <v-card-text>
              <div class="section-header">SUBCONTRACTORS</div>

              <div style="width:200px;margin:10px 0px">
                <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Subcontractor Markup %" :disabled="quote.use_master_markup" outlined v-model.number="quote.subcontractor_markup_percentage" min="0" suffix="%"></v-text-field>
              </div>

              <div v-for="(subcontractor, index) in quote.subcontractors" style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:30px 20px;position:relative">
                <v-btn color="primary" @click="deleteSubcontractor(index)" x-small fab style="position:absolute;top:5px;right:5px;width:20px;height:20px;"><v-icon x-small>mdi-close</v-icon></v-btn>
                <v-row dense>
                    <v-col cols="3">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Subcontractor" outlined v-model="quote.subcontractors[index].subcontractor" @change="setSubcontractor()"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Description" outlined v-model="quote.subcontractors[index].description" @change="setSubcontractor()"></v-text-field>
                    </v-col>

                    <v-col cols="1">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Cost" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.subcontractors[index].cost" prefix="$" @change="setSubcontractor()"></v-text-field>
                    </v-col>

                    <v-col cols="1">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Quantity" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.subcontractors[index].quantity" @change="setSubcontractor()"></v-text-field>
                    </v-col>

                    <v-col cols="1">
                      <v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.subcontractors[index].phase" @change="setSubcontractor()"></v-select>
                    </v-col>

                    <v-col cols="1">
                      <div style="line-height:100%">Total Cost</div>
                      {{formatCurrency(quote.subcontractors[index].total_cost)}}
                    </v-col>
                    <v-col cols="1">
                      <div style="line-height:100%">Total Revenue</div>
                      {{formatCurrency(quote.subcontractors[index].total_revenue)}}
                    </v-col>
                </v-row>
              </div>
              <v-btn color="primary" @click="addSubcontractor()" small>ADD SUBCONTRACTOR</v-btn>
            </v-card-text>
          </v-card>

          <!-- PER DIEM & TRAVEL -->
          <v-card class="section">
            <v-card-text>
              <div class="section-header">PER DIEM & TRAVEL</div>

              <div style="margin:10px 0px;border:2px solid #223773;border-radius:4px;padding:10px 20px;position:relative;">
                <div style="width:200px"><v-checkbox dense label="Per Diem Available" hide-details v-model="quote.require_per_diem" @change="setPerDiemAndTravel()"></v-checkbox></div>
                <div v-if="quote.require_per_diem">
                  <div style="width:240px"><v-checkbox dense label="Auto Calculate Per Diem" hide-details v-model="quote.auto_calculate_per_diem" @change="setPerDiemAndTravel()"></v-checkbox></div>
                  <div style="margin:20px 0px 10px;width:200px"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" style="" label="Per Diem Markup %" min="0" outlined v-model.number="quote.per_diem_markup_percentage" @change="setPerDiemAndTravel()" suffix="%"></v-text-field></div>
                  <div style="color:gray;line-height:150%;font-size:12px">Cost Per Meal: <span class="editable-rate" @click="editQuoteRate(['per_diem_rates','meals'])">{{formatCurrency(quote.rates.per_diem_rates.meals)}}</span></div>
                  <div style="color:gray;line-height:150%;font-size:12px">Cost Per Hotel: <span class="editable-rate" @click="editQuoteRate(['per_diem_rates','hotels'])">{{formatCurrency(quote.rates.per_diem_rates.hotels)}}</span></div>

                  <div style="margin-top:20px;" v-if="!quote.auto_calculate_per_diem">
                    <v-btn v-if="!show_per_diem_detail" color="white" @click="show_per_diem_detail = true" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-plus</v-icon>SHOW PER DIEM DETAIL</v-btn>
                    <v-btn v-if="show_per_diem_detail" color="white" @click="show_per_diem_detail = false" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-minus</v-icon>HIDE PER DIEM DETAIL</v-btn>
                  </div>

                  <div v-if="show_per_diem_detail">
                    <v-simple-table style="padding-top:25px" v-if="!quote.auto_calculate_per_diem || perDiemTotalCost > 0">
                      <thead>
                        <td></td>
                        <td>Project Manager</td>
                        <td>General Superintendent</td>
                        <td>Foreman</td>
                        <td>Journeymen</td>
                        <td>Helper/FW/HW</td>
                        <td>Safety Officer</td>
                        <td>QAQC</td>
                        <td>Specialty Craft</td>
                        <td>Total Cost</td>
                        <td>Total Revenue</td>
                      </thead>
                      <tbody>
                        <tr v-for="phase in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" style="text-align:center" v-if="quote.shift_counts['phase_'+phase].total > 0 || !quote.auto_calculate_per_diem">
                          <td style="text-align:right;width:100px">{{'Phase ' + phase}}</td>
                          <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" :disabled="quote.auto_calculate_per_diem" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.shift_counts['phase_'+phase]['project_manager']" @change="setPerDiemAndTravel()" label="Shifts" min="0" outlined></v-text-field></td>
                          <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" :disabled="quote.auto_calculate_per_diem" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.shift_counts['phase_'+phase]['general_superintendent']" @change="setPerDiemAndTravel()" label="Shifts" min="0" outlined></v-text-field></td>
                          <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" :disabled="quote.auto_calculate_per_diem" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.shift_counts['phase_'+phase]['foreman']" @change="setPerDiemAndTravel()" label="Shifts" min="0" outlined></v-text-field></td>
                          <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" :disabled="quote.auto_calculate_per_diem" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.shift_counts['phase_'+phase]['journeymen']" @change="setPerDiemAndTravel()" label="Shifts" min="0" outlined></v-text-field></td>
                          <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" :disabled="quote.auto_calculate_per_diem" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.shift_counts['phase_'+phase]['helper']" @change="setPerDiemAndTravel()" label="Shifts" min="0" outlined></v-text-field></td>
                          <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" :disabled="quote.auto_calculate_per_diem" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.shift_counts['phase_'+phase]['safety_officer']" @change="setPerDiemAndTravel()" label="Shifts" min="0" outlined></v-text-field></td>
                          <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" :disabled="quote.auto_calculate_per_diem" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.shift_counts['phase_'+phase]['qaqc']" @change="setPerDiemAndTravel()" label="Shifts" min="0" outlined></v-text-field></td>
                          <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" :disabled="quote.auto_calculate_per_diem" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.shift_counts['phase_'+phase]['specialty_craft']" @change="setPerDiemAndTravel()" label="Shifts" min="0" outlined></v-text-field></td>
                          <td style="padding-top:10px;padding-bottom:10px">{{formatCurrency(perDiemPhaseCost('phase_'+phase))}}</td>
                          <td style="padding-top:10px;padding-bottom:10px">{{formatCurrency(perDiemPhaseRevenue('phase_'+phase))}}</td>
                        </tr>
                        <tr>
                          <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                          <td style="font-weight:bold;text-align:center">{{formatCurrency(perDiemTotalCost)}}</td>
                          <td style="font-weight:bold;text-align:center">{{formatCurrency(perDiemTotalRevenue)}}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <div v-if="!quote.auto_calculate_per_diem">
                      <v-btn v-if="!show_per_diem_detail" color="white" @click="show_per_diem_detail = true" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-plus</v-icon>SHOW PER DIEM DETAIL</v-btn>
                      <v-btn v-if="show_per_diem_detail" color="white" @click="show_per_diem_detail = false" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-minus</v-icon>HIDE PER DIEM DETAIL</v-btn>
                    </div>
                  </div>
                </div>


              </div>

              <div style="margin:10px 0px;border:2px solid #223773;border-radius:4px;padding:10px 20px;position:relative;">
                <div style="width:200px"><v-checkbox dense label="Travel Available" hide-details v-model="quote.require_travel" @change="setPerDiemAndTravel()"></v-checkbox></div>

                <div v-if="quote.require_travel">

                  <div style="margin:20px 0px 10px;width:200px">
                    <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Travel Markup %" min="0" outlined v-model.number="quote.travel_markup_percentage" @change="setPerDiemAndTravel()" suffix="%"></v-text-field>
                  </div>

                  <div style="margin:20px 0px 10px;width:200px">
                    <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Round Trip Miles" min="0" outlined v-model.number="quote.travel_round_trip_miles" @change="setPerDiemAndTravel()" suffix="miles"></v-text-field>
                  </div>

                  <div style="color:gray;line-height:100%;font-size:12px">
                    Project Manager: <span class="editable-rate" @click="editQuoteRate(['travel_rates','project_manager'])">{{formatCurrency(quote.rates.travel_rates['project_manager'])}}</span> |
                    General Superintendent: <span class="editable-rate" @click="editQuoteRate(['travel_rates','general_superintendent'])">{{formatCurrency(quote.rates.travel_rates['general_superintendent'])}}</span> |
                    Foreman: <span class="editable-rate" @click="editQuoteRate(['travel_rates','foreman'])">{{formatCurrency(quote.rates.travel_rates['foreman'])}}</span> |
                    Journeymen: <span class="editable-rate" @click="editQuoteRate(['travel_rates','journeymen'])">{{formatCurrency(quote.rates.travel_rates['journeymen'])}}</span> |
                    Helper: <span class="editable-rate" @click="editQuoteRate(['travel_rates','helper'])">{{formatCurrency(quote.rates.travel_rates['helper'])}}</span> |
                    Safety Officer: <span class="editable-rate" @click="editQuoteRate(['travel_rates','safety_officer'])">{{formatCurrency(quote.rates.travel_rates['safety_officer'])}}</span> |
                    QAQC: <span class="editable-rate" @click="editQuoteRate(['travel_rates','qaqc'])">{{formatCurrency(quote.rates.travel_rates['qaqc'])}}</span> |
                    Specialty Craft: <span class="editable-rate" @click="editQuoteRate(['travel_rates','specialty_craft'])">{{formatCurrency(quote.rates.travel_rates['specialty_craft'])}}</span>
                  </div>

                  <div style="margin-top:20px;">
                    <v-btn v-if="!show_travel_detail" color="white" @click="show_travel_detail = true" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-plus</v-icon>SHOW TRAVEL DETAIL</v-btn>
                    <v-btn v-if="show_travel_detail" color="white" @click="show_travel_detail = false" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-minus</v-icon>HIDE TRAVEL DETAIL</v-btn>
                  </div>

                  <v-simple-table style="padding-top:25px" v-if="show_travel_detail">
                    <thead>
                      <td></td>
                      <td>Project Manager</td>
                      <td>General Superintendent</td>
                      <td>Foreman</td>
                      <td>Journeymen</td>
                      <td>Helper/FW/HW</td>
                      <td>Safety Officer</td>
                      <td>QAQC</td>
                      <td>Specialty Craft</td>
                      <td>Total Cost</td>
                      <td>Total Revenue</td>
                    </thead>
                    <tbody>
                      <tr v-for="phase in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" style="text-align:center" v-if="quote.require_travel">
                        <td style="text-align:right;width:100px">{{'Phase ' + phase}}</td>
                        <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.trip_counts['phase_'+phase]['project_manager']" @change="setPerDiemAndTravel()" label="Trips" min="0" outlined></v-text-field></td>
                        <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.trip_counts['phase_'+phase]['general_superintendent']" @change="setPerDiemAndTravel()" label="Trips" min="0" outlined></v-text-field></td>
                        <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.trip_counts['phase_'+phase]['foreman']" @change="setPerDiemAndTravel()" label="Trips" min="0" outlined></v-text-field></td>
                        <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.trip_counts['phase_'+phase]['journeymen']" @change="setPerDiemAndTravel()" label="Trips" min="0" outlined></v-text-field></td>
                        <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.trip_counts['phase_'+phase]['helper']" @change="setPerDiemAndTravel()" label="Trips" min="0" outlined></v-text-field></td>
                        <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.trip_counts['phase_'+phase]['safety_officer']" @change="setPerDiemAndTravel()" label="Trips" min="0" outlined></v-text-field></td>
                        <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.trip_counts['phase_'+phase]['qaqc']" @change="setPerDiemAndTravel()" label="Trips" min="0" outlined></v-text-field></td>
                        <td style="padding-top:10px;padding-bottom:10px"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model="quote.trip_counts['phase_'+phase]['specialty_craft']" @change="setPerDiemAndTravel()" label="Trips" min="0" outlined></v-text-field></td>
                        <td style="padding-top:10px;padding-bottom:10px">{{formatCurrency(travelPhaseCost('phase_'+phase))}}</td>
                        <td style="padding-top:10px;padding-bottom:10px">{{formatCurrency(travelPhaseRevenue('phase_'+phase))}}</td>
                      </tr>
                      <tr>
                        <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                        <td style="font-weight:bold;text-align:center">{{formatCurrency(travelTotalCost)}}</td>
                        <td style="font-weight:bold;text-align:center">{{formatCurrency(travelTotalRevenue)}}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>

                  <div v-if="show_travel_detail">
                    <v-btn v-if="!show_travel_detail" color="white" @click="show_travel_detail = true" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-plus</v-icon>SHOW TRAVEL DETAIL</v-btn>
                    <v-btn v-if="show_travel_detail" color="white" @click="show_travel_detail = false" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-minus</v-icon>HIDE TRAVEL DETAIL</v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <!-- SUMMARY -->
          <div id="summary-section">
            <h4>Account: {{db.accounts[quote.account].name}}</h4>
            <h4>Opportunity: {{opportunity.name}}</h4>
            <h4>Quote: {{quote.quote_title}}</h4>
            <h4>Department: {{quote.department}}</h4>

            <div class="section-subheader">LABOR SUMMARY</div>

            <div class="report-page" v-for="(task,index) in quote.tasks" style="border:1px solid #223773;padding:10px;margin:10px 0px;background:white">
              <div>Task {{task.description}}</div>
              <div>Phase {{task.phase}}</div>
              <div>Duration {{task.lead_time_shifts}}</div>
              <v-simple-table dense>
                <thead style="font-weight:bold">
                  <td></td>
                  <td>Number of Employees Per Shift</td>
                  <td>Standard Time</td>
                  <td>Overtime</td>
                  <td>Double Time</td>
                  <td>Number of Shifts</td>
                  <td>Total Hours</td>
                  <td>Total Labor Cost</td>
                  <td>Total Labor Revenue</td>
                </thead>
                <tbody>
                  <tr v-for="(role,role_id) in tasks_roles">
                    <td style="text-align:left">{{task[role_id].name}}</td>
                    <td style="text-align:center">{{task[role_id].employees_per_shift}}</td>
                    <td style="text-align:center">{{task[role_id].standard_time}}</td>
                    <td style="text-align:center">{{task[role_id].overtime}}</td>
                    <td style="text-align:center">{{task[role_id].double_time}}</td>
                    <td style="text-align:center">{{task[role_id].number_of_shifts}}</td>
                    <td style="text-align:center">{{task[role_id].hours}}</td>
                    <td style="text-align:center">{{formatCurrency(task[role_id].cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(task[role_id].revenue)}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.tasks[index].total_employees_per_shift}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.tasks[index].total_standard_time}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.tasks[index].total_overtime}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.tasks[index].total_double_time}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">-</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.tasks[index].total_number_of_hours}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.tasks[index].total_cost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.tasks[index].total_revenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="report-page" style="border:4px solid #223773;font-weight:bold;padding:10px;margin:10px 0px;background:white">
              <div>Consolidated Tasks</div>
              <v-simple-table dense>
                <thead>
                  <td style=""></td>
                  <td>Standard Time</td>
                  <td>Overtime</td>
                  <td>Double Time</td>
                  <td>Total Hours</td>
                  <td>Total Labor Cost</td>
                  <td>Total Labor Revenue</td>
                </thead>
                <tbody>
                  <tr v-for="(role,role_name) in quote.consolidated_labor" v-if="role_name !== 'total_row_for_table'">
                    <td>{{role_name}}</td>
                    <td style="text-align:center">{{role.standard_time}}</td>
                    <td style="text-align:center">{{role.overtime}}</td>
                    <td style="text-align:center">{{role.double_time}}</td>
                    <td style="text-align:center">{{role.hours}}</td>
                    <td style="text-align:center">{{formatCurrency(role.cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(role.revenue)}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.consolidated_labor.total_row_for_table.total_standard_time}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.consolidated_labor.total_row_for_table.total_overtime}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.consolidated_labor.total_row_for_table.total_double_time}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.consolidated_labor.total_row_for_table.total_number_of_hours}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.consolidated_labor.total_row_for_table.total_cost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.consolidated_labor.total_row_for_table.total_revenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="section-subheader">MATERIAL SUMMARY</div>
            <div class="report-page" style="border:4px solid #223773;padding:10px;margin:10px 0px;background:white">
              <v-simple-table dense>
                <thead style="font-weight:bold">
                  <td>Material</td>
                  <td>Quantity</td>
                  <td>Cost Per Unit</td>
                  <td>Phase</td>
                  <td>Total Cost</td>
                  <td>Total Revenue</td>
                </thead>
                <tbody>
                  <tr v-for="(material,index) in quote.material">
                    <td>
                      <div v-if="material.description !== 'Other'">{{material.description}}</div>
                      <div v-if="material.description === 'Other'">{{material.other_name}}</div>
                      <div v-if="material.description === 'Other'">Vendor: {{material.vendor}}</div>
                    </td>
                    <td>{{material.quantity}}</td>
                    <td>{{formatCurrency(material.item_cost)}}</td>
                    <td>{{material.phase}}</td>
                    <td style="text-align:center">{{formatCurrency(material.total_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(material.total_revenue)}}</td>
                  </tr>
                  <tr>
                    <td style="width:250px;">Small Tools & Consumables</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style="text-align:center">{{formatCurrency(quote.small_tools_consumables_material.total_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.small_tools_consumables_material.total_revenue)}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.consolidated_material.total_cost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.consolidated_material.total_revenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="section-subheader">EQUIPMENT SUMMARY</div>
            <div class="report-page" style="border:4px solid #223773;padding:10px;margin:10px 0px;background:white">
              <v-simple-table dense>
                <thead style="font-weight:bold">
                  <td>Equipment</td>
                  <td>Owner</td>
                  <td>Rate Type</td>
                  <td>Quantity</td>
                  <td>Duration</td>
                  <td>Phase</td>
                  <td>Total Cost</td>
                  <td>Total Revenue</td>
                </thead>
                <tbody>
                  <tr v-for="(equipment,index) in quote.equipment">
                    <td>
                      <div v-if="equipment.description !== 'Other'">{{equipment.description}}</div>
                      <div v-if="equipment.description === 'Other'">{{equipment.other_name}}</div>
                      <div v-if="equipment.description === 'Other'">Vendor: {{equipment.vendor}}</div>
                    </td>
                    <td>{{equipment.owner}}</td>
                    <td>{{equipment.rate_type}}</td>
                    <td>{{equipment.quantity}}</td>
                    <td>{{equipment.duration}}</td>
                    <td>{{equipment.phase}}</td>
                    <td style="text-align:center">{{formatCurrency(equipment.total_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(equipment.total_revenue)}}</td>
                  </tr>

                  <tr>
                    <td>Fuel: Welding</td>
                    <td></td>
                    <td>Hourly</td>
                    <td>{{quote.welding_fuel_hours}}</td>
                    <td></td>
                    <td>{{quote.welding_fuel_phase}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.welding_fuel_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.welding_fuel_revenue)}}</td>
                  </tr>
                  <tr>
                    <td>Fuel: Forklift</td>
                    <td></td>
                    <td>Hourly</td>
                    <td>{{quote.forklift_fuel_hours}}</td>
                    <td></td>
                    <td>{{quote.forklift_fuel_phase}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.forklift_fuel_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.forklift_fuel_revenue)}}</td>
                  </tr>
                  <tr>
                    <td>Fuel: Air Compressor</td>
                    <td></td>
                    <td>Hourly</td>
                    <td>{{quote.air_compressor_fuel_hours}}</td>
                    <td></td>
                    <td>{{quote.air_compressor_fuel_phase}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.air_compressor_fuel_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.air_compressor_fuel_revenue)}}</td>
                  </tr>
                  <tr>
                    <td>Fuel: Manlift</td>
                    <td></td>
                    <td>Hourly</td>
                    <td>{{quote.manlift_fuel_hours}}</td>
                    <td></td>
                    <td>{{quote.manlift_fuel_phase}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.manlift_fuel_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.manlift_fuel_revenue)}}</td>
                  </tr>
                  <tr>
                    <td>Pickup Truck</td>
                    <td></td>
                    <td><div>Trucks</div>Mileage</td>
                    <td><div>{{quote.pickup_trucks.total_trucks}}</div>{{quote.pickup_trucks.total_mileage}}</td>
                    <td></td>
                    <td>split</td>
                    <td style="text-align:center">{{formatCurrency(quote.pickup_trucks.total_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.pickup_trucks.total_revenue)}}</td>
                  </tr>
                  <tr>
                    <td>Service Truck</td>
                    <td></td>
                    <td><div>Trucks</div>Mileage</td>
                    <td><div>{{quote.service_trucks.total_trucks}}</div>{{quote.service_trucks.total_mileage}}</td>
                    <td></td>
                    <td>split</td>
                    <td style="text-align:center">{{formatCurrency(quote.service_trucks.total_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.service_trucks.total_revenue)}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(equipmentTotalCost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(equipmentTotalRevenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="section-subheader">SUBCONTRACTOR SUMMARY</div>
            <div class="report-page" style="border:4px solid #223773;padding:10px;margin:10px 0px;background:white">
              <v-simple-table dense>
                <thead style="font-weight:bold">
                  <td>Subcontractor</td>
                  <td>Description</td>
                  <td>Cost</td>
                  <td>Quantity</td>
                  <td>Phase</td>
                  <td>Total Cost</td>
                  <td>Total Revenue</td>
                </thead>
                <tbody>
                  <tr v-for="(subcontractor,index) in quote.subcontractors">
                    <td>{{subcontractor.subcontractor}}</td>
                    <td>{{subcontractor.description}}</td>
                    <td>{{formatCurrency(subcontractor.cost)}}</td>
                    <td>{{subcontractor.quantity}}</td>
                    <td>{{subcontractor.phase}}</td>
                    <td style="text-align:center">{{formatCurrency(subcontractor.total_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(subcontractor.total_revenue)}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.consolidated_subcontractors.total_cost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.consolidated_subcontractors.total_revenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="section-subheader">PER DIEM SUMMARY</div>
            <div class="report-page" style="border:4px solid #223773;padding:10px;margin:10px 0px;background:white">

              <v-simple-table dense>
                <thead style="font-weight:bold">
                  <td>Phase</td>
                  <td>Project Manager</td>
                  <td>General Superintendent</td>
                  <td>Foreman</td>
                  <td>Journeymen</td>
                  <td>Safety Officer</td>
                  <td>QAQC</td>
                  <td>Specialty Craft</td>
                  <td>Total Cost</td>
                  <td>Total Revenue</td>
                </thead>
                <tbody>
                  <!-- <tr v-for="(per_diem,phase) in quote.per_diem"> -->
                  <tr v-for="phase in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" v-if="quote.per_diem['phase_'+phase] && perDiemPhaseCost('phase_'+phase) > 0 && perDiemPhaseRevenue('phase_'+phase) > 0">
                    <td>{{phase}}</td>
                    <td>{{formatCurrency(quote.per_diem['phase_'+phase].project_manager)}}</td>
                    <td>{{formatCurrency(quote.per_diem['phase_'+phase].general_superintendent)}}</td>
                    <td>{{formatCurrency(quote.per_diem['phase_'+phase].foreman)}}</td>
                    <td>{{formatCurrency(quote.per_diem['phase_'+phase].journeymen)}}</td>
                    <td>{{formatCurrency(quote.per_diem['phase_'+phase].safety_officer)}}</td>
                    <td>{{formatCurrency(quote.per_diem['phase_'+phase].qaqc)}}</td>
                    <td>{{formatCurrency(quote.per_diem['phase_'+phase].specialty_craft)}}</td>
                    <td style="text-align:center">{{formatCurrency(perDiemPhaseCost('phase_'+phase))}}</td>
                    <td style="text-align:center">{{formatCurrency(perDiemPhaseRevenue('phase_'+phase))}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(perDiemTotalCost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(perDiemTotalRevenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="section-subheader">TRAVEL SUMMARY</div>
            <div class="report-page" style="border:4px solid #223773;padding:10px;margin:10px 0px;background:white">
              <v-simple-table dense>
                <thead style="font-weight:bold">
                  <td>Phase</td>
                  <td>Project Manager</td>
                  <td>General Superintendent</td>
                  <td>Foreman</td>
                  <td>Journeymen</td>
                  <td>Safety Officer</td>
                  <td>QAQC</td>
                  <td>Specialty Craft</td>
                  <td>Total Cost</td>
                  <td>Total Revenue</td>
                </thead>
                <tbody>
                  <tr v-for="phase in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" v-if="quote.travel['phase_'+phase] && travelPhaseCost('phase_'+ phase) > 0">
                    <td>{{phase}}</td>
                    <td>{{formatCurrency(quote.travel['phase_'+phase].project_manager)}}</td>
                    <td>{{formatCurrency(quote.travel['phase_'+phase].general_superintendent)}}</td>
                    <td>{{formatCurrency(quote.travel['phase_'+phase].foreman)}}</td>
                    <td>{{formatCurrency(quote.travel['phase_'+phase].journeymen)}}</td>
                    <td>{{formatCurrency(quote.travel['phase_'+phase].safety_officer)}}</td>
                    <td>{{formatCurrency(quote.travel['phase_'+phase].qaqc)}}</td>
                    <td>{{formatCurrency(quote.travel['phase_'+phase].specialty_craft)}}</td>
                    <td style="text-align:center">{{formatCurrency(travelPhaseCost('phase_'+phase))}}</td>
                    <td style="text-align:center">{{formatCurrency(travelPhaseRevenue('phase_'+phase))}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(travelTotalCost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(travelTotalRevenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="printJobReport()" small>PRINT JOB REPORT</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeForm()" small>CLOSE</v-btn>
          <v-btn color="primary" @click="saveQuote('replace')" small v-if="!view_version" :disabled="!unsavedChanges">SAVE</v-btn>
          <v-btn color="primary" @click="saveQuote('revision')" small v-if="!isNew && !view_version" :disabled="!unsavedChanges">SAVE AS REVISION</v-btn>
          <v-btn color="primary" @click="saveQuote('revert')" small v-if="view_version" :disabled="!unsavedChanges">REVERT BACK TO THIS VERSION</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
    <EditQuoteRate v-if='edit_quote_rate' @close='edit_quote_rate = false' @saveQuoteRate='saveQuoteRate' :initialrate='edit_quote_rate_initial_val'/>
  </v-dialog>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import { formatCurrency } from '../../mixins/formatCurrency.js'
import EditQuoteRate from '../EditQuoteRate.vue'
import { jsPDF } from "jspdf";
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas'
import draggable from 'vuedraggable'

export default {
  name: 'Field',
  components: {EditQuoteRate,draggable},
  mixins: [formatCurrency],
  props:{
    opportunityid: String,
    quoteid: [String, Boolean]
  },
  data(){
    return{
      form_mounted: false,
      dialog: true,
      edit_quote_rate: false,
      edit_quote_rate_path_arr: [],
      edit_quote_rate_initial_val: null,
      show_per_diem_detail: false,
      show_travel_detail: false,
      // customers: ['Aarmaz', 'Anuvia', 'Argos', 'Base Comp', 'Cemex', 'Chemours', 'City of St Pete', 'Conrad', 'Covanta', 'Duke Energy','Elmco','Evoqua', 'FPL', 'Garney', 'General Industrial', 'Georgia Pacific', 'Gopher', 'Gopher E&I', 'Lakeland Electric', 'MED', 'Mineral Technologies', 'Mitsubishi','Mosaic E&I', 'Mosaic MSA', 'NAM', 'Nooter Erickson', 'Nutrien', 'OUC', 'Ostrara', 'Palm Beach Agg', 'Republic Metals', 'SMS 2022 T&M Rates', 'SouthWest Stainless', 'TECO', 'Titan', 'Twin Pines', 'US AGG', 'Vogt Power', 'Weir Mineral', 'Whiting Turner'],
      customers: [],
      quote: {
        quote_title: '',
        quote_sheet: 'Civil',
        department: 'MSI CF Civil',
        job_id_code: '039',
        customer: 'General Industrial',
        account: '',
        opportunity: '',
        created_by: '',
        created: '',
        scope_of_work: '',
        clarifications: '',
        work_schedule: 'Monday Thru Friday Excluding Holidays',
        work_hours: '8 Hours Per Shift',
        subcontractor_markup_percentage: 11,
        material_markup_percentage: 11,
        mid_state_equipment_markup_percentage: 0,
        rental_equipment_markup_percentage: 11,
        per_diem_markup_percentage: 0,
        travel_markup_percentage: 0,
        total_cost: 0,
        total_revenue: 0,
        rates: {},
        tasks: [],
        material: [],
        subcontractors: [],
        equipment: [],
        per_diem: {},
        travel: {},
        require_per_diem: false,
        require_travel: false,
        travel_round_trip_miles: 0,
        auto_calculate_per_diem: true,
        show_in_master: true,
        master_markup_percentage: 11,
        use_master_markup: true,
        use_master_phase: false,
        master_phase: 1,
        shift_counts: {
          phase_1: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_2: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_3: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_4: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_5: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_6: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_7: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_8: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_9: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_10: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},

          phase_11: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_12: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_13: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_14: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_15: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_16: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_17: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_18: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_19: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_20: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},

          phase_21: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_22: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_23: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_24: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_25: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_26: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_27: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_28: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_29: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_30: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},

          phase_31: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_32: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_33: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_34: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_35: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_36: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_37: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_38: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_39: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_40: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},

          phase_41: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_42: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_43: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_44: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_45: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_46: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_47: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_48: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_49: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_50: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},

        },
        trip_counts: {
          phase_1: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_2: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_3: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_4: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_5: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_6: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_7: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_8: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_9: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_10: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},

          phase_11: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_12: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_13: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_14: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_15: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_16: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_17: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_18: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_19: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_20: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},

          phase_21: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_22: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_23: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_24: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_25: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_26: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_27: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_28: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_29: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_30: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},

          phase_31: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_32: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_33: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_34: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_35: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_36: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_37: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_38: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_39: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_40: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},

          phase_41: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_42: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_43: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_44: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_45: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_46: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_47: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_48: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_49: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_50: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
        },
        welding_fuel_hours: 0,
        welding_fuel_phase: 1,
        welding_fuel_cost: 0,
        welding_fuel_revenue: 0,
        forklift_fuel_hours: 0,
        forklift_fuel_phase: 1,
        forklift_fuel_cost: 0,
        forklift_fuel_revenue: 0,
        air_compressor_fuel_hours: 0,
        air_compressor_fuel_phase: 1,
        air_compressor_fuel_cost: 0,
        air_compressor_fuel_revenue: 0,
        manlift_fuel_hours: 0,
        manlift_fuel_phase: 1,
        manlift_fuel_cost: 0,
        manlift_fuel_revenue: 0,
        require_trucks: true,
        pickup_trucks: {
          phase_1: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_2: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_3: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_4: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_5: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_6: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_7: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_8: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_9: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_10: {trucks:0, mileage:0, cost: 0, revenue: 0},

          phase_11: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_12: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_13: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_14: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_15: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_16: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_17: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_18: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_19: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_20: {trucks:0, mileage:0, cost: 0, revenue: 0},

          phase_21: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_22: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_23: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_24: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_25: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_26: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_27: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_28: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_29: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_30: {trucks:0, mileage:0, cost: 0, revenue: 0},

          phase_31: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_32: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_33: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_34: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_35: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_36: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_37: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_38: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_39: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_40: {trucks:0, mileage:0, cost: 0, revenue: 0},

          phase_41: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_42: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_43: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_44: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_45: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_46: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_47: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_48: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_49: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_50: {trucks:0, mileage:0, cost: 0, revenue: 0},

          total_trucks: 0,
          total_mileage: 0,
          total_cost: 0,
          total_revenue: 0
        },
        service_trucks: {
          phase_1: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_2: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_3: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_4: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_5: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_6: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_7: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_8: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_9: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_10: {trucks:0, mileage:0, cost: 0, revenue: 0},

          phase_11: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_12: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_13: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_14: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_15: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_16: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_17: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_18: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_19: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_20: {trucks:0, mileage:0, cost: 0, revenue: 0},

          phase_21: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_22: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_23: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_24: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_25: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_26: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_27: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_28: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_29: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_30: {trucks:0, mileage:0, cost: 0, revenue: 0},

          phase_31: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_32: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_33: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_34: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_35: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_36: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_37: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_38: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_39: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_40: {trucks:0, mileage:0, cost: 0, revenue: 0},

          phase_41: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_42: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_43: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_44: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_45: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_46: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_47: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_48: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_49: {trucks:0, mileage:0, cost: 0, revenue: 0},
          phase_50: {trucks:0, mileage:0, cost: 0, revenue: 0},


          total_trucks: 0,
          total_mileage: 0,
          total_cost: 0,
          total_revenue: 0
        },
        require_small_tools_consumables: true,
        small_tools_consumables_material: {
          phase_1: {cost: 0, revenue: 0},
          phase_2: {cost: 0, revenue: 0},
          phase_3: {cost: 0, revenue: 0},
          phase_4: {cost: 0, revenue: 0},
          phase_5: {cost: 0, revenue: 0},
          phase_6: {cost: 0, revenue: 0},
          phase_7: {cost: 0, revenue: 0},
          phase_8: {cost: 0, revenue: 0},
          phase_9: {cost: 0, revenue: 0},
          phase_10: {cost: 0, revenue: 0},

          phase_11: {cost: 0, revenue: 0},
          phase_12: {cost: 0, revenue: 0},
          phase_13: {cost: 0, revenue: 0},
          phase_14: {cost: 0, revenue: 0},
          phase_15: {cost: 0, revenue: 0},
          phase_16: {cost: 0, revenue: 0},
          phase_17: {cost: 0, revenue: 0},
          phase_18: {cost: 0, revenue: 0},
          phase_19: {cost: 0, revenue: 0},
          phase_20: {cost: 0, revenue: 0},

          phase_21: {cost: 0, revenue: 0},
          phase_22: {cost: 0, revenue: 0},
          phase_23: {cost: 0, revenue: 0},
          phase_24: {cost: 0, revenue: 0},
          phase_25: {cost: 0, revenue: 0},
          phase_26: {cost: 0, revenue: 0},
          phase_27: {cost: 0, revenue: 0},
          phase_28: {cost: 0, revenue: 0},
          phase_29: {cost: 0, revenue: 0},
          phase_30: {cost: 0, revenue: 0},

          phase_31: {cost: 0, revenue: 0},
          phase_32: {cost: 0, revenue: 0},
          phase_33: {cost: 0, revenue: 0},
          phase_34: {cost: 0, revenue: 0},
          phase_35: {cost: 0, revenue: 0},
          phase_36: {cost: 0, revenue: 0},
          phase_37: {cost: 0, revenue: 0},
          phase_38: {cost: 0, revenue: 0},
          phase_39: {cost: 0, revenue: 0},
          phase_40: {cost: 0, revenue: 0},

          phase_41: {cost: 0, revenue: 0},
          phase_42: {cost: 0, revenue: 0},
          phase_43: {cost: 0, revenue: 0},
          phase_44: {cost: 0, revenue: 0},
          phase_45: {cost: 0, revenue: 0},
          phase_46: {cost: 0, revenue: 0},
          phase_47: {cost: 0, revenue: 0},
          phase_48: {cost: 0, revenue: 0},
          phase_49: {cost: 0, revenue: 0},
          phase_50: {cost: 0, revenue: 0},

          total_cost: 0,
          total_revenue: 0
        },
        consolidated_labor: {
          total_row_for_table : {
            total_cost: 0,
            total_revenue: 0,
            total_standard_time: 0,
            total_overtime: 0,
            total_double_time: 0,
            total_number_of_hours: 0,
          }
        },
        consolidated_subcontractors:{
          total_cost: 0,
          total_revenue: 0
        },
        consolidated_material:{
          total_cost: 0,
          total_revenue: 0
        },
        completed: false,
        reviewed: false
      },
      quote_archive: {},
      initial_quote: {},
      isNew: true,
      view_version: false,
      opportunity: {},
      tasks_roles:{
        'project_manager' : {name: 'Project Manager'},
        'general_superintendent' : {name: 'General Superintendent'},
        'foreman' : {name: 'Foreman'},
        'journeymen' : {name: 'Journeymen'},
        'helper' : {name: 'Help'},
        'safety_officer' : {name: 'Safety Officer'},
        'qaqc' : {name: 'QAQC'},
        'specialty_craft_1' : {name: 'Specialty Craft 1'},
        'specialty_craft_2' : {name: 'Specialty Craft 2'},
        'specialty_craft_3' : {name: 'Specialty Craft 3'},
      },
      material_arr: [],
      equipment_arr: [],
    }
  },
  beforeMount(){

    var self = this


    Firebase.database().ref('opportunities').child(self.opportunityid).once('value',function(snapshot){
      self.opportunity = JSON.parse(JSON.stringify(snapshot.val()))
      self.isNew = self.quoteid ? false : true

      if(self.isNew){ // new quote
        self.quote.created_by = self.current_user
        self.quote.account = self.opportunity.account
        self.quote.opportunity = self.opportunityid
        self.quote.created = Date.now()
        self.quote.rates = JSON.parse(JSON.stringify(self.db.field_rates))
        self.initial_quote = JSON.parse(JSON.stringify(self.quote))
        self.form_mounted = true

        self.material_arr = [{item: 'Other'}] // set material options dropdown
        for(var m in self.quote.rates.material_rates){
          self.material_arr.push({item: self.quote.rates.material_rates[m].item, cost: self.quote.rates.material_rates[m].cost})
        }

        self.equipment_arr = [{item: 'Other'}]
        for(var e in self.quote.rates.equipment_rates){
          self.equipment_arr.push({item: self.quote.rates.equipment_rates[e].description, daily_cost: self.quote.rates.equipment_rates[e].cost_daily, weekly_cost: self.quote.rates.equipment_rates[e].cost_weekly, monthly_cost: self.quote.rates.equipment_rates[e].cost_monthly })
        }

        for(var r in self.quote.rates.labor_revenue_rates){
          var customer = r.substring(0, r.length - 2)
          if(self.customers.indexOf(customer) === -1){
            self.customers.push(customer)
          }
        }

      }

      if(!self.isNew){ // edit quote
        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).once('value',function(quote_snapshot){
          self.quote = JSON.parse(JSON.stringify(quote_snapshot.val()))

          self.material_arr = [{item: 'Other'}] // set material options dropdown
          for(var m in self.quote.rates.material_rates){
            self.material_arr.push({item: self.quote.rates.material_rates[m].item, cost: self.quote.rates.material_rates[m].cost})
          }

          self.equipment_arr = [{item: 'Other'}]
          for(var e in self.quote.rates.equipment_rates){
            self.equipment_arr.push({item: self.quote.rates.equipment_rates[e].description, daily_cost: self.quote.rates.equipment_rates[e].cost_daily, weekly_cost: self.quote.rates.equipment_rates[e].cost_weekly, monthly_cost: self.quote.rates.equipment_rates[e].cost_monthly })
          }

          for(var r in self.quote.rates.labor_revenue_rates){
            var customer = r.substring(0, r.length - 2)
            if(self.customers.indexOf(customer) === -1){
              self.customers.push(customer)
            }
          }

          self.setEmptyQuoteData()
          self.setLabor()

        }).then(function(){
          Firebase.database().ref('quotes_archive').child(self.opportunityid).child(self.quoteid).once('value',function(quotes_archive_snapshot){
            self.quote_archive = quotes_archive_snapshot.val()
            self.initial_quote = JSON.parse(JSON.stringify(self.quote))
            self.form_mounted = true
          })
        })
      }
    })
  },
  watch: {
    'quote.use_master_phase': function(val){
      if(!this.quote.master_phase){
        this.quote.master_phase = 1
      }
      this.setLabor()
      this.setMaterial()
      this.setEquipment()
      this.setFuel()
      this.setSubcontractor()
    },
    'quote.master_phase': function(val){
      this.setLabor()
      this.setMaterial()
      this.setEquipment()
      this.setFuel()
      this.setSubcontractor()
    },
    'quote.department': function(val){
      this.quote.job_id_code = _.find(this.ou_departments, {department: val}).code
    },
    'quote.use_master_markup': function(val){
      this.setMasterMarkups()
    },
    'quote.material_markup_percentage': function(val){
      this.setMaterial()
    },
    'quote.rental_equipment_markup_percentage': function(val){
      this.setEquipment()
    },
    'quote.subcontractor_markup_percentage': function(val){
      this.setSubcontractor()
    },
    'quote.master_markup_percentage': function(val){
      this.setMasterMarkups()
    }

  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      active_accounts: 'active_accounts',
      active_users: 'active_users',
      ou_departments: 'ou_departments',
      work_schedule: 'work_schedule',
      work_hours: 'work_hours'
    }),
    sorted_archive(){
      var arr = []
      var archive = this.quote_archive
      for(var a in archive){
        archive[a].key = a
        arr.push(archive[a])
      }
      return _.orderBy(arr, ['archived'],['asc']);
    },
    shownTaskDetail(){
      for(var i = 0; i < this.quote.tasks.length;i++){
        if(this.quote.tasks[i].show_task_detail === true){
          return true
        }
      }
      return false
    },
    hiddenTaskDetail(){
      for(var i = 0; i < this.quote.tasks.length;i++){
        if(this.quote.tasks[i].show_task_detail === false){
          return true
        }
      }
      return false
    },
    travelPhaseCost(){
      return function (phase) {
        var total = 0
        var roles = ['project_manager','general_superintendent','foreman','journeymen','helper','safety_officer','qaqc','specialty_craft']
        for(var i = 0; i < roles.length; i++){
          var role = roles[i]
          total += (this.quote.travel[phase] ? this.quote.travel[phase][role] : 0)
        }
        return total
      }
    },
    travelPhaseRevenue(){
      return function (phase) {
        var total = 0
        var travel_markup = this.quote.travel_markup_percentage ? this.quote.travel_markup_percentage / 100 : 0
        var roles = ['project_manager','general_superintendent','foreman','journeymen','helper','safety_officer','qaqc','specialty_craft']
        for(var i = 0; i < roles.length; i++){
          var role = roles[i]

          total += (this.quote.travel[phase] ? this.quote.travel[phase][role] * travel_markup + this.quote.travel[phase][role] : 0)
        }
        return total
      }
    },
    perDiemPhaseCost(){
      return function (phase) {
        var total = 0
        var roles = ['project_manager','general_superintendent','foreman','journeymen','helper','safety_officer','qaqc','specialty_craft']
        for(var i = 0; i < roles.length; i++){
          var role = roles[i]
          total += (this.quote.per_diem[phase] ? this.quote.per_diem[phase][role] : 0)
        }
        return total
      }
    },
    perDiemPhaseRevenue(){
      return function (phase) {
        var total = 0
        var per_diem_markup = this.quote.per_diem_markup_percentage ? this.quote.per_diem_markup_percentage / 100 : 0
        var roles = ['project_manager','general_superintendent','foreman','journeymen','helper','safety_officer','qaqc','specialty_craft']
        for(var i = 0; i < roles.length; i++){
          var role = roles[i]
          total += (this.quote.per_diem[phase] ? this.quote.per_diem[phase][role] * per_diem_markup + this.quote.per_diem[phase][role] : 0)
        }
        return total
      }
    },
    perDiemTotalCost(){
      var total = 0
      if(this.quote.require_per_diem){
        for(var p in this.quote.per_diem){
          var roles = ['project_manager','general_superintendent','foreman','journeymen','helper','safety_officer','qaqc','specialty_craft']
          for(var i = 0; i < roles.length; i++){
            var role = roles[i]
            total += this.quote.per_diem[p][role]
          }
        }
      }
      return total
    },
    perDiemTotalRevenue(){
      var total = 0
      var per_diem_markup = this.quote.per_diem_markup_percentage ? this.quote.per_diem_markup_percentage / 100 : 0
      if(this.quote.require_per_diem){
        for(var p in this.quote.per_diem){
          var roles = ['project_manager','general_superintendent','foreman','journeymen','helper','safety_officer','qaqc','specialty_craft']
          for(var i = 0; i < roles.length; i++){
            var role = roles[i]
            total += this.quote.per_diem[p][role] * per_diem_markup + this.quote.per_diem[p][role]
          }
        }
      }
      return total
    },
    travelTotalCost(){
      var total = 0
      for(var p in this.quote.travel){
        var roles = ['project_manager','general_superintendent','foreman','journeymen','helper','safety_officer','qaqc','specialty_craft']
        for(var i = 0; i < roles.length; i++){
          var role = roles[i]
          total += this.quote.travel[p][role]
        }
      }
      return total
    },
    travelTotalRevenue(){
      var total = 0
      var travel_markup = this.quote.travel_markup_percentage ? this.quote.travel_markup_percentage / 100 : 0
      for(var p in this.quote.travel){
        var roles = ['project_manager','general_superintendent','foreman','journeymen','helper','safety_officer','qaqc','specialty_craft']
        for(var i = 0; i < roles.length; i++){
          var role = roles[i]
          total += this.quote.travel[p][role] * travel_markup + this.quote.travel[p][role]
        }
      }
      return total
    },
    unsavedChanges() {
      var o_q = JSON.parse(JSON.stringify(this.initial_quote))
      var q = JSON.parse(JSON.stringify(this.quote))
      delete o_q.small_tools_consumables_material
      delete q.small_tools_consumables_material
      return JSON.stringify(o_q) !== JSON.stringify(q)
    },
    equipmentTotalCost(){
      var total = 0
      for(var i = 0; i < this.quote.equipment.length; i++){
        total += this.quote.equipment[i].total_cost
      }
      total += this.quote.welding_fuel_cost
      total += this.quote.forklift_fuel_cost
      total += this.quote.air_compressor_fuel_cost
      total += this.quote.manlift_fuel_cost
      total += this.quote.require_trucks ? this.quote.pickup_trucks.total_cost : 0
      total += this.quote.require_trucks ? this.quote.service_trucks.total_cost : 0
      return total
    },
    equipmentTotalRevenue(){
      var total = 0
      for(var i = 0; i < this.quote.equipment.length; i++){
        total += this.quote.equipment[i].total_revenue
      }
      total += this.quote.welding_fuel_revenue
      total += this.quote.forklift_fuel_revenue
      total += this.quote.air_compressor_fuel_revenue
      total += this.quote.manlift_fuel_revenue
      total += this.quote.require_trucks ? this.quote.pickup_trucks.total_revenue : 0
      total += this.quote.require_trucks ? this.quote.service_trucks.total_revenue : 0
      return total
    }
  },
  methods:{
    setMasterMarkups(){

      if(this.quote.use_master_markup){
        this.quote.material_markup_percentage = this.quote.master_markup_percentage
        this.quote.rental_equipment_markup_percentage = this.quote.master_markup_percentage
        this.quote.subcontractor_markup_percentage = this.quote.master_markup_percentage
      }

    },
    closeForm(){
      if(this.unsavedChanges){
        var confirm_close = confirm('You have unsaved changes. Are you sure you want to close this form? Any unsaved changes will be lost.')
        if(confirm_close){
          this.$emit('close')
        }
      }else{
        this.$emit('close')
      }
    },
    showAllTasksDetail(){
      for(var i = 0; i < this.quote.tasks.length;i++){
        this.quote.tasks[i].show_task_detail = true
      }
    },
    hideAllTasksDetail(){
      for(var i = 0; i < this.quote.tasks.length;i++){
        this.quote.tasks[i].show_task_detail = false
      }
    },
    formatDate(date){
      return Moment(date).format('MM/DD/YYYY h:mm:ssa')
    },
    editSpecialtyCraftRate(task_index,role_id,time){
      this.edit_quote_rate_path_arr = task_index + '_' + role_id + '_' + time
      this.edit_quote_rate_initial_val = this.quote.tasks[task_index][role_id]['revenue_rate_'+time]
      this.edit_quote_rate = true
    },
    editQuoteRate(rate_path_arr){
      this.edit_quote_rate_path_arr = rate_path_arr
      if(rate_path_arr.length === 1){
        this.edit_quote_rate_initial_val = this.quote.rates[rate_path_arr[0]]
      }
      if(rate_path_arr.length === 2){
        this.edit_quote_rate_initial_val = this.quote.rates[rate_path_arr[0]][rate_path_arr[1]]
      }
      if(rate_path_arr.length === 3){
        this.edit_quote_rate_initial_val = this.quote.rates[rate_path_arr[0]][rate_path_arr[1]][rate_path_arr[2]]
      }
      this.edit_quote_rate = true
    },
    viewPreviousVersion(id){
      var previous_quote = this.quote_archive[id]
      this.quote = previous_quote
      this.view_version = id
      this.setEmptyQuoteData()
    },
    saveQuoteRate(val){

      if(this.edit_quote_rate_path_arr.includes('specialty_craft') && !this.edit_quote_rate_path_arr.includes('travel_rates')){
        var task_index = this.edit_quote_rate_path_arr.split('_')[0]
        var specialty_craft_number = this.edit_quote_rate_path_arr.split('_')[3]
        var time = this.edit_quote_rate_path_arr.split('_')[4]
        this.quote.tasks[task_index]['specialty_craft_' + specialty_craft_number]['revenue_rate_' + time] = val
        this.setLabor()
      }else{
        if(this.edit_quote_rate_path_arr.length === 1){
          this.quote.rates[this.edit_quote_rate_path_arr[0]] = val
        }
        if(this.edit_quote_rate_path_arr.length === 2){
          this.quote.rates[this.edit_quote_rate_path_arr[0]][this.edit_quote_rate_path_arr[1]] = val
        }
        if(this.edit_quote_rate_path_arr.length === 3){
          this.quote.rates[this.edit_quote_rate_path_arr[0]][this.edit_quote_rate_path_arr[1]][this.edit_quote_rate_path_arr[2]] = val
        }
      }
      if(this.edit_quote_rate_path_arr.includes('small_tool_consumables_rates')){
        this.setToolsAndConsumables()
      }
      if(this.edit_quote_rate_path_arr.includes('material_rates')){
        this.setMaterial()
      }
      if(this.edit_quote_rate_path_arr.includes('equipment_rates')) {
        this.setEquipment()
      }
      if(this.edit_quote_rate_path_arr.includes('fuel_cost_rates')){
        this.setFuel()
      }
      if(this.edit_quote_rate_path_arr.includes('truck_rates')){
        this.setTruckRequirements()
      }
      if(this.edit_quote_rate_path_arr.includes('travel_rates') || this.edit_quote_rate_path_arr.includes('per_diem_rates')){
        this.setPerDiemAndTravel()
      }
      this.edit_quote_rate_path_arr = []
      this.edit_quote_rate = false
      this.edit_quote_rate_initial_val = null
    },
    addSubcontractor(){
      var subcontractor_obj = {
        subcontractor: '',
        description: '',
        phase: this.quote.use_master_phase ? this.quote.master_phase : 1,
        quantity: 1,
        cost: 0,
        total_cost: 0,
        total_revenue: 0
      }
      this.quote.subcontractors = this.quote.subcontractors ? this.quote.subcontractors : []
      this.quote.subcontractors.push(subcontractor_obj)
    },
    deleteSubcontractor(index){
      var confirm_delete = confirm('Are you sure you want to delete this subcontractor?')
      if(confirm_delete){
        this.quote.subcontractors.splice(index,1)
        this.setSubcontractor()
      }
    },
    addTask(){
      var task_obj = {
                      description: '',
                      phase: this.quote.use_master_phase ? this.quote.master_phase : 1,
                      day_night: 'Day',
                      total_cost: 0,
                      total_revenue: 0,
                      total_employees_per_shift: 0,
                      total_standard_time: 0,
                      total_overtime: 0,
                      total_double_time: 0,
                      total_number_of_shifts: 0,
                      total_number_of_hours: 0,
                      show_task_detail: false,
                      lead_time_shifts: 1
                     }
      for(var role in this.tasks_roles){
        task_obj[role] = {name: this.tasks_roles[role].name, employees_per_shift: 0, number_of_shifts: 0, standard_time: 0, overtime: 0, double_time: 0, hours: 0, revenue: 0, cost: 0}
        if(role.includes('specialty_craft')){
          task_obj[role].revenue_rate_st = 0
          task_obj[role].revenue_rate_ot = 0
          task_obj[role].revenue_rate_dt = 0
        }
      }
      this.quote.tasks = this.quote.tasks ? this.quote.tasks : []
      this.quote.tasks.push(task_obj)
    },
    cloneTask(index){
      var task_clone = JSON.parse(JSON.stringify(this.quote.tasks[index]))
      task_clone.description = '(CLONE) ' + task_clone.description
      this.quote.tasks.push(task_clone)
      this.setLabor()
    },
    deleteTask(index){
      var confirm_delete = confirm('Are you sure you want to delete this task?')
      if(confirm_delete){
        this.quote.tasks.splice(index,1)
        this.setLabor()
      }
    },
    addMaterial(){
      var material_obj = {
        description: '',
        material_id: '',
        phase: this.quote.use_master_phase ? this.quote.master_phase : 1,
        quantity: 0,
        item_cost: 0,
        total_cost: 0,
        total_revenue: 0
      }
      this.quote.material = this.quote.material ? this.quote.material : []
      this.quote.material.push(material_obj)
    },
    deleteMaterial(index){
      var confirm_delete = confirm('Are you sure you want to delete this material?')
      if(confirm_delete){
        this.quote.material.splice(index,1)
        this.setMaterial()
      }
    },
    addEquipment(){
      var equipment_obj = {
        description: '',
        equipment_id: '',
        phase: this.quote.use_master_phase ? this.quote.master_phase : 1,
        quantity: 1,
        duration: 1,
        owner: '',
        rate_type: 'Daily',
        in_out_charges: this.quote.rates.rental_in_out_charges,
        item_cost: 0,
        total_cost: 0,
        total_revenue: 0
      }
      this.quote.equipment = this.quote.equipment ? this.quote.equipment : []
      this.quote.equipment.push(equipment_obj)
    },
    deleteEquipment(index){
      var confirm_delete = confirm('Are you sure you want to delete this equipment?')
      if(confirm_delete){
        this.quote.equipment.splice(index,1)
        this.setEquipment()
      }
    },
    setPerDiemAndTravel(){
      var per_diem = {}
      var travel = {}
      var meal_cost = this.quote.rates.per_diem_rates.meals
      var hotel_cost = this.quote.rates.per_diem_rates.hotels
      var travel_cost = this.quote.rates.travel_rates
      // var shift_counts = this.quote.shift_counts

      // this.quote.per_diem

      if(this.quote.auto_calculate_per_diem){
        this.quote.shift_counts = {
          phase_1: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_2: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_3: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_4: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_5: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_6: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_7: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_8: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_9: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_10: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},

          phase_11: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_12: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_13: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_14: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_15: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_16: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_17: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_18: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_19: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_20: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},

          phase_21: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_22: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_23: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_24: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_25: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_26: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_27: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_28: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_29: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_30: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},

          phase_31: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_32: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_33: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_34: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_35: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_36: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_37: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_38: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_39: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_40: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},


          phase_41: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_42: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_43: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_44: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_45: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_46: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_47: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_48: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_49: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
          phase_50: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0, total: 0},
        }

        for(var i = 0; i < this.quote.tasks.length; i++){
          var task = this.quote.tasks[i]
          this.quote.shift_counts['phase_' + task.phase].project_manager += (task.project_manager.employees_per_shift * task.project_manager.number_of_shifts)
          this.quote.shift_counts['phase_' + task.phase].general_superintendent += (task.general_superintendent.employees_per_shift * task.general_superintendent.number_of_shifts)
          this.quote.shift_counts['phase_' + task.phase].foreman += (task.foreman.employees_per_shift * task.foreman.number_of_shifts)
          this.quote.shift_counts['phase_' + task.phase].journeymen += (task.journeymen.employees_per_shift * task.journeymen.number_of_shifts)
          this.quote.shift_counts['phase_' + task.phase].helper += (task.helper.employees_per_shift * task.helper.number_of_shifts)
          this.quote.shift_counts['phase_' + task.phase].safety_officer += (task.safety_officer.employees_per_shift * task.safety_officer.number_of_shifts)
          this.quote.shift_counts['phase_' + task.phase].qaqc += (task.qaqc.employees_per_shift * task.qaqc.number_of_shifts)
          this.quote.shift_counts['phase_' + task.phase].specialty_craft += ( (task.specialty_craft_1.employees_per_shift * task.specialty_craft_1.number_of_shifts) + (task.specialty_craft_2.employees_per_shift * task.specialty_craft_2.number_of_shifts) + (task.specialty_craft_3.employees_per_shift * task.specialty_craft_3.number_of_shifts) )
        }
      }

      if(!this.quote.require_travel){
        this.quote.trip_counts = {
          phase_1: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_2: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_3: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_4: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_5: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_6: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_7: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_8: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_9: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_10: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},

          phase_11: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_12: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_13: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_14: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_15: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_16: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_17: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_18: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_19: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_20: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},

          phase_21: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_22: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_23: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_24: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_25: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_26: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_27: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_28: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_29: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_30: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},

          phase_31: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_32: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_33: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_34: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_35: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_36: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_37: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_38: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_39: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_40: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},

          phase_41: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_42: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_43: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_44: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_45: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_46: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_47: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_48: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_49: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
          phase_50: {project_manager: 0, general_superintendent: 0, foreman: 0, journeymen: 0, helper: 0, safety_officer: 0, qaqc: 0, specialty_craft: 0},
        }
      }

      for(var phase_shifts in this.quote.shift_counts){
        if(this.quote.require_per_diem){
          per_diem[phase_shifts] = {}
          per_diem[phase_shifts].project_manager = (meal_cost  + hotel_cost) * this.quote.shift_counts[phase_shifts].project_manager
          per_diem[phase_shifts].general_superintendent = (meal_cost  + hotel_cost) * this.quote.shift_counts[phase_shifts].general_superintendent
          per_diem[phase_shifts].foreman = (meal_cost  + hotel_cost) * this.quote.shift_counts[phase_shifts].foreman
          per_diem[phase_shifts].journeymen = (meal_cost  + hotel_cost) * this.quote.shift_counts[phase_shifts].journeymen
          per_diem[phase_shifts].helper = (meal_cost  + hotel_cost) * this.quote.shift_counts[phase_shifts].helper
          per_diem[phase_shifts].safety_officer = (meal_cost  + hotel_cost) * this.quote.shift_counts[phase_shifts].safety_officer
          per_diem[phase_shifts].qaqc = (meal_cost  + hotel_cost) * this.quote.shift_counts[phase_shifts].qaqc
          per_diem[phase_shifts].specialty_craft = (meal_cost  + hotel_cost) * this.quote.shift_counts[phase_shifts].specialty_craft
        }
        this.quote.shift_counts[phase_shifts].total = 0
        this.quote.shift_counts[phase_shifts].total += Number.isInteger(parseFloat(this.quote.shift_counts[phase_shifts].project_manager)) ? parseFloat(this.quote.shift_counts[phase_shifts].project_manager) : 0
        this.quote.shift_counts[phase_shifts].total += Number.isInteger(parseFloat(this.quote.shift_counts[phase_shifts].general_superintendent)) ? parseFloat(this.quote.shift_counts[phase_shifts].general_superintendent) : 0
        this.quote.shift_counts[phase_shifts].total += Number.isInteger(parseFloat(this.quote.shift_counts[phase_shifts].foreman)) ? parseFloat(this.quote.shift_counts[phase_shifts].foreman) : 0
        this.quote.shift_counts[phase_shifts].total += Number.isInteger(parseFloat(this.quote.shift_counts[phase_shifts].journeymen)) ? parseFloat(this.quote.shift_counts[phase_shifts].journeymen) : 0
        this.quote.shift_counts[phase_shifts].total += Number.isInteger(parseFloat(this.quote.shift_counts[phase_shifts].helper)) ? parseFloat(this.quote.shift_counts[phase_shifts].helper) : 0
        this.quote.shift_counts[phase_shifts].total += Number.isInteger(parseFloat(this.quote.shift_counts[phase_shifts].safety_officer)) ? parseFloat(this.quote.shift_counts[phase_shifts].safety_officer) : 0
        this.quote.shift_counts[phase_shifts].total += Number.isInteger(parseFloat(this.quote.shift_counts[phase_shifts].qaqc)) ? parseFloat(this.quote.shift_counts[phase_shifts].qaqc) : 0
        this.quote.shift_counts[phase_shifts].total += Number.isInteger(parseFloat(this.quote.shift_counts[phase_shifts].specialty_craft)) ? parseFloat(this.quote.shift_counts[phase_shifts].specialty_craft) : 0
      }

      for(var phase_shifts in this.quote.trip_counts){
        if(this.quote.require_travel){
          travel[phase_shifts] = {}
          travel[phase_shifts].project_manager = travel_cost['project_manager'] * this.quote.trip_counts[phase_shifts].project_manager * (this.quote.travel_round_trip_miles / 60)
          travel[phase_shifts].general_superintendent = travel_cost['general_superintendent'] * this.quote.trip_counts[phase_shifts].general_superintendent * (this.quote.travel_round_trip_miles / 60)
          travel[phase_shifts].foreman = travel_cost['foreman'] * this.quote.trip_counts[phase_shifts].foreman * (this.quote.travel_round_trip_miles / 60)
          travel[phase_shifts].journeymen = travel_cost['journeymen'] * this.quote.trip_counts[phase_shifts].journeymen * (this.quote.travel_round_trip_miles / 60)
          travel[phase_shifts].helper = travel_cost['helper'] * this.quote.trip_counts[phase_shifts].helper * (this.quote.travel_round_trip_miles / 60)
          travel[phase_shifts].safety_officer = travel_cost['safety_officer'] * this.quote.trip_counts[phase_shifts].safety_officer * (this.quote.travel_round_trip_miles / 60)
          travel[phase_shifts].qaqc = travel_cost['qaqc'] * this.quote.trip_counts[phase_shifts].qaqc * (this.quote.travel_round_trip_miles / 60)
          travel[phase_shifts].specialty_craft = travel_cost['specialty_craft'] * this.quote.trip_counts[phase_shifts].specialty_craft * (this.quote.travel_round_trip_miles / 60)
        }
      }

      this.quote.per_diem = per_diem
      this.quote.travel = travel
      this.initial_quote.per_diem = this.quote.per_diem // hack for unsavedChanges because these are set based on shifts
      this.initial_quote.travel = this.quote.travel     // hack for unsavedChanges because these are set based on shifts
    },
    setTruckRequirements(){

      this.quote.pickup_trucks.phase_1 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_2 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_3 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_4 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_5 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_6 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_7 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_8 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_9 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_10 = {trucks:0, mileage:0, cost: 0, revenue: 0}

      this.quote.pickup_trucks.phase_11 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_12 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_13 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_14 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_15 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_16 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_17 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_18 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_19 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_20 = {trucks:0, mileage:0, cost: 0, revenue: 0}

      this.quote.pickup_trucks.phase_21 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_22 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_23 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_24 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_25 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_26 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_27 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_28 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_29 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_30 = {trucks:0, mileage:0, cost: 0, revenue: 0}

      this.quote.pickup_trucks.phase_31 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_32 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_33 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_34 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_35 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_36 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_37 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_38 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_39 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_40 = {trucks:0, mileage:0, cost: 0, revenue: 0}

      this.quote.pickup_trucks.phase_41 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_42 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_43 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_44 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_45 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_46 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_47 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_48 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_49 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.pickup_trucks.phase_50 = {trucks:0, mileage:0, cost: 0, revenue: 0}

      this.quote.pickup_trucks.total_trucks = 0
      this.quote.pickup_trucks.total_cost = 0
      this.quote.pickup_trucks.total_revenue = 0

      this.quote.service_trucks.phase_1 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_2 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_3 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_4 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_5 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_6 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_7 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_8 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_9 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_10 = {trucks:0, mileage:0, cost: 0, revenue: 0}

      this.quote.service_trucks.phase_11 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_12 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_13 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_14 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_15 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_16 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_17 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_18 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_19 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_20 = {trucks:0, mileage:0, cost: 0, revenue: 0}

      this.quote.service_trucks.phase_21 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_22 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_23 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_24 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_25 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_26 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_27 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_28 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_29 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_30 = {trucks:0, mileage:0, cost: 0, revenue: 0}

      this.quote.service_trucks.phase_31 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_32 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_33 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_34 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_35 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_36 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_37 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_38 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_39 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_40 = {trucks:0, mileage:0, cost: 0, revenue: 0}

      this.quote.service_trucks.phase_41 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_42 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_43 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_44 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_45 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_46 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_47 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_48 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_49 = {trucks:0, mileage:0, cost: 0, revenue: 0}
      this.quote.service_trucks.phase_50 = {trucks:0, mileage:0, cost: 0, revenue: 0}

      this.quote.service_trucks.total_trucks = 0
      this.quote.service_trucks.total_cost = 0
      this.quote.service_trucks.total_revenue = 0

      if(this.quote.require_trucks){
        for(var i = 0; i < this.quote.tasks.length; i++){
          var task = this.quote.tasks[i]
          var task_pickup_trucks = (task.project_manager.employees_per_shift * task.project_manager.number_of_shifts) + (task.general_superintendent.employees_per_shift * task.general_superintendent.number_of_shifts) + (task.safety_officer.employees_per_shift * task.safety_officer.number_of_shifts) + (task.qaqc.employees_per_shift * task.qaqc.number_of_shifts)
          var task_service_trucks = (task.foreman.employees_per_shift * task.foreman.number_of_shifts)
          this.quote.pickup_trucks['phase_'+task.phase].trucks += task_pickup_trucks
          this.quote.service_trucks['phase_'+task.phase].trucks += task_service_trucks
          this.quote.pickup_trucks.total_trucks += task_pickup_trucks
          this.quote.service_trucks.total_trucks += task_service_trucks
        }

        for(var i = 1; i <= 50; i++){
          var phase_pickup_truck_revenue;
          var phase_service_truck_revenue;
          var phase_pickup_truck_cost;
          var phase_service_truck_cost;

          this.quote.pickup_trucks['phase_'+i].mileage = this.quote.pickup_trucks['phase_'+i].trucks * this.quote.pickup_trucks.total_mileage
          this.quote.service_trucks['phase_'+i].mileage = this.quote.service_trucks['phase_'+i].trucks * this.quote.service_trucks.total_mileage

          phase_pickup_truck_cost = this.quote.pickup_trucks['phase_'+i].mileage * this.quote.rates.truck_rates.pickup_truck_cost_per_mile * this.quote.rates.truck_rates.pickup_truck_cost_per_mile
          phase_service_truck_cost = this.quote.service_trucks['phase_'+i].mileage * this.quote.rates.truck_rates.service_truck_cost_per_mile * this.quote.rates.truck_rates.service_truck_cost_per_mile

          phase_pickup_truck_revenue = (this.quote.pickup_trucks['phase_'+i].trucks * this.quote.rates.truck_rates.pickup_truck_revenue_per_truck) + (this.quote.rates.truck_rates.pickup_truck_revenue_per_mile * this.quote.pickup_trucks['phase_'+i].mileage)
          phase_service_truck_revenue = (this.quote.service_trucks['phase_'+i].trucks * this.quote.rates.truck_rates.service_truck_revenue_per_truck) + (this.quote.rates.truck_rates.service_truck_revenue_per_mile * this.quote.service_trucks['phase_'+i].mileage)
          
          this.quote.pickup_trucks['phase_'+i].revenue = phase_pickup_truck_revenue
          this.quote.service_trucks['phase_'+i].revenue = phase_service_truck_revenue
          this.quote.pickup_trucks['phase_'+i].cost = phase_pickup_truck_cost
          this.quote.service_trucks['phase_'+i].cost = phase_service_truck_cost
          this.quote.pickup_trucks.total_revenue += phase_pickup_truck_revenue
          this.quote.service_trucks.total_revenue += phase_service_truck_revenue
          this.quote.pickup_trucks.total_cost += phase_pickup_truck_cost
          this.quote.service_trucks.total_cost += phase_service_truck_cost
        }
      }else{
        this.quote.pickup_trucks.total_mileage = 0
        this.quote.service_trucks.total_mileage = 0
      }
    },
    setFuel(){
      this.quote.welding_fuel_cost = this.quote.welding_fuel_hours * this.quote.rates.fuel_cost_rates.welding
      this.quote.forklift_fuel_cost = this.quote.forklift_fuel_hours * this.quote.rates.fuel_cost_rates.forklift
      this.quote.air_compressor_fuel_cost = this.quote.air_compressor_fuel_hours * this.quote.rates.fuel_cost_rates.air_compressor
      this.quote.manlift_fuel_cost = this.quote.manlift_fuel_hours * this.quote.rates.fuel_cost_rates.manlift
      this.quote.welding_fuel_revenue = this.quote.welding_fuel_cost
      this.quote.forklift_fuel_revenue = this.quote.forklift_fuel_cost
      this.quote.air_compressor_fuel_revenue = this.quote.air_compressor_fuel_cost
      this.quote.manlift_fuel_revenue = this.quote.manlift_fuel_cost

      if(this.quote.use_master_phase){
        this.quote.welding_fuel_phase = this.quote.master_phase
        this.quote.forklift_fuel_phase = this.quote.master_phase
        this.quote.air_compressor_fuel_phase = this.quote.master_phase
        this.quote.manlift_fuel_phase = this.quote.master_phase
      }
    },
    setToolsAndConsumables(){
      var small_tools_rate;
      var consumables_rate;

      this.quote.small_tools_consumables_material = {
        phase_1: {cost: 0, revenue: 0},
        phase_2: {cost: 0, revenue: 0},
        phase_3: {cost: 0, revenue: 0},
        phase_4: {cost: 0, revenue: 0},
        phase_5: {cost: 0, revenue: 0},
        phase_6: {cost: 0, revenue: 0},
        phase_7: {cost: 0, revenue: 0},
        phase_8: {cost: 0, revenue: 0},
        phase_9: {cost: 0, revenue: 0},
        phase_10: {cost: 0, revenue: 0},

        phase_11: {cost: 0, revenue: 0},
        phase_12: {cost: 0, revenue: 0},
        phase_13: {cost: 0, revenue: 0},
        phase_14: {cost: 0, revenue: 0},
        phase_15: {cost: 0, revenue: 0},
        phase_16: {cost: 0, revenue: 0},
        phase_17: {cost: 0, revenue: 0},
        phase_18: {cost: 0, revenue: 0},
        phase_19: {cost: 0, revenue: 0},
        phase_20: {cost: 0, revenue: 0},

        phase_21: {cost: 0, revenue: 0},
        phase_22: {cost: 0, revenue: 0},
        phase_23: {cost: 0, revenue: 0},
        phase_24: {cost: 0, revenue: 0},
        phase_25: {cost: 0, revenue: 0},
        phase_26: {cost: 0, revenue: 0},
        phase_27: {cost: 0, revenue: 0},
        phase_28: {cost: 0, revenue: 0},
        phase_29: {cost: 0, revenue: 0},
        phase_30: {cost: 0, revenue: 0},

        phase_31: {cost: 0, revenue: 0},
        phase_32: {cost: 0, revenue: 0},
        phase_33: {cost: 0, revenue: 0},
        phase_34: {cost: 0, revenue: 0},
        phase_35: {cost: 0, revenue: 0},
        phase_36: {cost: 0, revenue: 0},
        phase_37: {cost: 0, revenue: 0},
        phase_38: {cost: 0, revenue: 0},
        phase_39: {cost: 0, revenue: 0},
        phase_40: {cost: 0, revenue: 0},

        phase_41: {cost: 0, revenue: 0},
        phase_42: {cost: 0, revenue: 0},
        phase_43: {cost: 0, revenue: 0},
        phase_44: {cost: 0, revenue: 0},
        phase_45: {cost: 0, revenue: 0},
        phase_46: {cost: 0, revenue: 0},
        phase_47: {cost: 0, revenue: 0},
        phase_48: {cost: 0, revenue: 0},
        phase_49: {cost: 0, revenue: 0},
        phase_50: {cost: 0, revenue: 0},

        total_cost: 0,
        total_revenue: 0
      }
      small_tools_rate = (this.quote.rates.small_tool_consumables_rates.small_tools_cost_rate / 100)
      consumables_rate = (this.quote.rates.small_tool_consumables_rates.consumables_cost_rate / 100)

      if(this.quote.require_small_tools_consumables){
        for(var i = 0; i < this.quote.tasks.length; i++){
          var phase_number = this.quote.tasks[i].phase
          this.quote.small_tools_consumables_material['phase_' + phase_number].cost += ( (small_tools_rate * this.quote.tasks[i].total_revenue) + (consumables_rate * this.quote.tasks[i].total_revenue) )
          this.quote.small_tools_consumables_material['phase_' + phase_number].revenue = this.quote.small_tools_consumables_material['phase_' + phase_number].cost
          this.quote.small_tools_consumables_material.total_cost += ( (small_tools_rate * this.quote.tasks[i].total_revenue) + (consumables_rate * this.quote.tasks[i].total_revenue) )
        }
        this.quote.small_tools_consumables_material.total_revenue = this.quote.small_tools_consumables_material.total_cost
      }

      this.setConsolidatedMaterial()
    },
    setConsolidatedMaterial(){
      this.quote.consolidated_material.total_cost = this.quote.small_tools_consumables_material.total_cost
      this.quote.consolidated_material.total_revenue = this.quote.small_tools_consumables_material.total_revenue
      for(var i = 0; i < this.quote.material.length; i++){
        this.quote.consolidated_material.total_cost += this.quote.material[i].total_cost
        this.quote.consolidated_material.total_revenue += this.quote.material[i].total_revenue
      }
    },
    setLabor(){
      this.quote.consolidated_labor = {
        total_row_for_table : {
          total_cost: 0,
          total_revenue: 0,
          total_standard_time: 0,
          total_overtime: 0,
          total_double_time: 0,
          total_number_of_hours: 0,
        }
      }
      for(var i = 0; i < this.quote.tasks.length; i++){
        this.quote.tasks[i].total_cost = 0
        this.quote.tasks[i].total_revenue = 0
        this.quote.tasks[i].total_employees_per_shift = 0
        this.quote.tasks[i].total_standard_time = 0
        this.quote.tasks[i].total_overtime = 0
        this.quote.tasks[i].total_double_time = 0
        this.quote.tasks[i].total_number_of_shifts = 0
        this.quote.tasks[i].total_number_of_hours = 0
        this.quote.tasks[i].lead_time_shifts = this.quote.tasks[i].lead_time_shifts ? this.quote.tasks[i].lead_time_shifts : 0

        if(this.quote.use_master_phase){
          this.quote.tasks[i].phase = this.quote.master_phase
        }

        for(var role in this.tasks_roles){
          this.quote.tasks[i][role].employees_per_shift = typeof(this.quote.tasks[i][role].employees_per_shift) === 'number' ? this.quote.tasks[i][role].employees_per_shift : 0
          this.quote.tasks[i][role].standard_time = typeof(this.quote.tasks[i][role].standard_time) === 'number' ? this.quote.tasks[i][role].standard_time : 0
          this.quote.tasks[i][role].overtime = typeof(this.quote.tasks[i][role].overtime) === 'number' ? this.quote.tasks[i][role].overtime : 0
          this.quote.tasks[i][role].double_time = typeof(this.quote.tasks[i][role].double_time) === 'number' ? this.quote.tasks[i][role].double_time : 0
          this.quote.tasks[i][role].number_of_shifts = typeof(this.quote.tasks[i][role].number_of_shifts) === 'number' ? this.quote.tasks[i][role].number_of_shifts : 0

          if(!role.includes('specialty_craft')){
            var st_revenue = this.quote.tasks[i][role].standard_time * (this.quote.rates.labor_revenue_rates[this.quote.customer+'ST'][role] + (this.quote.tasks[i].day_night === 'Night' ? this.quote.rates.labor_night_shift_differentials['st'] : 0))
            var ot_revenue = this.quote.tasks[i][role].overtime * (this.quote.rates.labor_revenue_rates[this.quote.customer+'OT'][role] + (this.quote.tasks[i].day_night === 'Night' ? this.quote.rates.labor_night_shift_differentials['ot'] : 0))
            var dt_revenue = this.quote.tasks[i][role].double_time * (this.quote.rates.labor_revenue_rates[this.quote.customer+'DT'][role] + (this.quote.tasks[i].day_night === 'Night' ? this.quote.rates.labor_night_shift_differentials['dt'] : 0))
          }else{
            var st_revenue = this.quote.tasks[i][role].standard_time * (this.quote.tasks[i][role].revenue_rate_st + (this.quote.tasks[i].day_night === 'Night' ? this.quote.tasks[i][role].revenue_rate_st : 0))
            var ot_revenue = this.quote.tasks[i][role].overtime * (this.quote.tasks[i][role].revenue_rate_ot + (this.quote.tasks[i].day_night === 'Night' ? this.quote.tasks[i][role].revenue_rate_ot : 0))
            var dt_revenue = this.quote.tasks[i][role].double_time * (this.quote.tasks[i][role].revenue_rate_dt + (this.quote.tasks[i].day_night === 'Night' ? this.quote.tasks[i][role].revenue_rate_dt : 0))
          }

          var st_cost = this.quote.tasks[i][role].standard_time * this.quote.rates.labor_cost_rates['st']
          var ot_cost = this.quote.tasks[i][role].overtime * this.quote.rates.labor_cost_rates['ot']
          var dt_cost = this.quote.tasks[i][role].double_time * this.quote.rates.labor_cost_rates['dt']
          this.quote.tasks[i][role].cost = (st_cost + ot_cost + dt_cost) * this.quote.tasks[i][role].employees_per_shift * this.quote.tasks[i][role].number_of_shifts
          this.quote.tasks[i][role].revenue = (st_revenue + ot_revenue + dt_revenue) * this.quote.tasks[i][role].employees_per_shift * this.quote.tasks[i][role].number_of_shifts
          this.quote.tasks[i][role].hours = this.quote.tasks[i][role].employees_per_shift * this.quote.tasks[i][role].number_of_shifts * (this.quote.tasks[i][role].standard_time + this.quote.tasks[i][role].overtime + this.quote.tasks[i][role].double_time)
          this.quote.tasks[i].total_cost += this.quote.tasks[i][role].cost
          this.quote.tasks[i].total_revenue += this.quote.tasks[i][role].revenue
          this.quote.tasks[i].total_employees_per_shift += this.quote.tasks[i][role].employees_per_shift
          this.quote.tasks[i].total_standard_time += this.quote.tasks[i][role].standard_time
          this.quote.tasks[i].total_overtime += this.quote.tasks[i][role].overtime
          this.quote.tasks[i].total_double_time += this.quote.tasks[i][role].double_time
          this.quote.tasks[i].total_number_of_shifts += this.quote.tasks[i][role].number_of_shifts
          this.quote.tasks[i].total_number_of_hours += this.quote.tasks[i][role].hours

          var st_hours = this.quote.tasks[i][role].employees_per_shift * this.quote.tasks[i][role].number_of_shifts * this.quote.tasks[i][role].standard_time
          var ot_hours = this.quote.tasks[i][role].employees_per_shift * this.quote.tasks[i][role].number_of_shifts * this.quote.tasks[i][role].overtime
          var dt_hours = this.quote.tasks[i][role].employees_per_shift * this.quote.tasks[i][role].number_of_shifts * this.quote.tasks[i][role].double_time
          this.quote.consolidated_labor.total_row_for_table.total_standard_time += st_hours
          this.quote.consolidated_labor.total_row_for_table.total_overtime += ot_hours
          this.quote.consolidated_labor.total_row_for_table.total_double_time += dt_hours
          this.quote.consolidated_labor.total_row_for_table.total_number_of_hours += (st_hours + ot_hours + dt_hours)
          this.quote.consolidated_labor.total_row_for_table.total_cost += this.quote.tasks[i][role].cost
          this.quote.consolidated_labor.total_row_for_table.total_revenue += this.quote.tasks[i][role].revenue

          if(this.quote.consolidated_labor[this.quote.tasks[i][role].name]){
            this.quote.consolidated_labor[this.quote.tasks[i][role].name]['standard_time'] += st_hours,
            this.quote.consolidated_labor[this.quote.tasks[i][role].name]['overtime'] += ot_hours,
            this.quote.consolidated_labor[this.quote.tasks[i][role].name]['double_time'] += dt_hours,
            this.quote.consolidated_labor[this.quote.tasks[i][role].name]['hours'] += (st_hours + ot_hours + dt_hours)
            this.quote.consolidated_labor[this.quote.tasks[i][role].name]['cost'] += this.quote.tasks[i][role].cost,
            this.quote.consolidated_labor[this.quote.tasks[i][role].name]['revenue'] += this.quote.tasks[i][role].revenue
          }else{
            this.quote.consolidated_labor[this.quote.tasks[i][role].name] = {

              'standard_time': st_hours,
              'overtime': ot_hours,
              'double_time': dt_hours,
              'hours': (st_hours + ot_hours + dt_hours),
              'cost': this.quote.tasks[i][role].cost,
              'revenue': this.quote.tasks[i][role].revenue,
            }
          }


        }
      }
      this.setToolsAndConsumables()
      this.setTruckRequirements()
      this.setPerDiemAndTravel()
    },
    setSubcontractor(){
      var markup = this.quote.subcontractor_markup_percentage / 100
      var total_cost = 0
      var total_revenue = 0
      for(var i = 0; i < this.quote.subcontractors.length; i++){
        if(this.quote.use_master_phase){
          this.quote.subcontractors[i].phase = this.quote.master_phase
        }
        this.quote.subcontractors[i].cost = typeof(this.quote.subcontractors[i].cost) === 'number' ? this.quote.subcontractors[i].cost : 0
        this.quote.subcontractors[i].quantity = typeof(this.quote.subcontractors[i].quantity) === 'number' ? this.quote.subcontractors[i].quantity : 0
        this.quote.subcontractors[i].total_cost = this.quote.subcontractors[i].cost * this.quote.subcontractors[i].quantity
        this.quote.subcontractors[i].total_revenue = this.quote.subcontractors[i].total_cost * markup + this.quote.subcontractors[i].total_cost
        total_cost += this.quote.subcontractors[i].total_cost
        total_revenue += this.quote.subcontractors[i].total_revenue
      }
      this.quote.consolidated_subcontractors.total_cost = total_cost
      this.quote.consolidated_subcontractors.total_revenue = total_revenue
    },
    setMaterial(){
      var markup = this.quote.material_markup_percentage / 100

      for(var i = 0; i < this.quote.material.length; i++){
        var item = this.quote.material[i]
        var description = item.description
        var quantity = item.quantity

        if(this.quote.use_master_phase){
          this.quote.material[i].phase = this.quote.master_phase
        }

        if(item.description !== 'Other'){
          for(var m in this.quote.rates.material_rates){
            if(this.quote.rates.material_rates[m].item === description){
              this.quote.material[i].material_id = m
              this.quote.material[i].item_cost = this.quote.rates.material_rates[m].cost
              this.quote.material[i].total_cost = this.quote.rates.material_rates[m].cost * quantity
              this.quote.material[i].total_revenue = this.quote.material[i].total_cost * markup + this.quote.material[i].total_cost
            }
          }
        }else{
          this.quote.material[i].material_id = '-other-'
          this.quote.material[i].total_cost = this.quote.material[i].item_cost * quantity
          this.quote.material[i].total_revenue = this.quote.material[i].total_cost * markup + this.quote.material[i].total_cost
        }
      }
      this.setToolsAndConsumables()
      this.setConsolidatedMaterial()
    },
    setEquipment(){
      var ms_markup = this.quote.mid_state_equipment_markup_percentage / 100
      var rental_markup = this.quote.rental_equipment_markup_percentage / 100
      var total_cost = 0
      var total_revenue = 0

      for(var i = 0; i < this.quote.equipment.length; i++){
        var item = this.quote.equipment[i]
        var description = item.description
        var rate_type = item.rate_type

        if(this.quote.use_master_phase){
          this.quote.equipment[i].phase = this.quote.master_phase
        }

        if(item.description !== 'Other'){
          for(var e in this.quote.rates.equipment_rates){
            if(this.quote.rates.equipment_rates[e].description === description){
              this.quote.equipment[i].equipment_id = e
              this.quote.equipment[i].owner = this.quote.rates.equipment_rates[e].owner
              this.quote.equipment[i].item_cost = this.quote.rates.equipment_rates[e]['cost_'+rate_type.toLowerCase()]
              this.quote.equipment[i].total_cost = (this.quote.equipment[i].item_cost * this.quote.equipment[i].quantity * this.quote.equipment[i].duration) + (this.quote.equipment[i].owner === 'United' ? (this.quote.equipment[i].in_out_charges * this.quote.equipment[i].quantity) : 0)
              this.quote.equipment[i].total_revenue = this.quote.equipment[i].total_cost + (this.quote.equipment[i].total_cost * (this.quote.equipment[i].owner === 'Mid-State' ? ms_markup : rental_markup))
            }
          }
        }else{
          this.quote.equipment[i].equipment_id = '-other-'
          this.quote.equipment[i].owner = 'Rental'
          this.quote.equipment[i].in_out_charges = this.quote.equipment[i].in_out_charges ? this.quote.equipment[i].in_out_charges : 0
          this.quote.equipment[i].total_cost = (this.quote.equipment[i].item_cost * this.quote.equipment[i].quantity * this.quote.equipment[i].duration) + (this.quote.equipment[i].in_out_charges * this.quote.equipment[i].quantity)
          this.quote.equipment[i].total_revenue = this.quote.equipment[i].total_cost + (this.quote.equipment[i].total_cost * rental_markup)
        }
      }


    },
    printJobReport(){
      var self = this
      self.$store.state.isLoading = true
      html2canvas(document.getElementById("summary-section")).then(function(canvas) {
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF('p', 'mm');
        var position = 10;
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight; // top padding for other pages
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save( 'job_report.pdf', {returnPromise: true}).then(self.$store.state.isLoading = false);
      });
    },
    setEmptyQuoteData(){
      if(!this.quote.rates){
        this.$set(this.rates, 'rates', {})
      }
      if(!this.quote.tasks){
        this.$set(this.quote, 'tasks', [])
      }
      if(!this.quote.subcontractors){
        this.$set(this.quote, 'subcontractors', [])
      }
      if(!this.quote.material){
        this.$set(this.quote, 'material', [])
      }
      if(!this.quote.equipment){
        this.$set(this.quote, 'equipment', [])
      }
      if(!this.quote.per_diem){
        this.$set(this.quote, 'per_diem', {})
      }
      if(!this.quote.travel){
        this.$set(this.quote, 'travel', {})
      }
    },
    saveQuote(save_type){
      var self = this

      if(self.quote.quote_title.trim() === ''){
        self.$store.state.notification = 'Please provide a title for this quote before saving.'
        return
      }

      var elmos = {
        phases: {},
        total_revenue: 0,
        total_cost: 0,
        total_hours: 0,
        ou_code: this.quote.job_id_code,
        ou_department: this.quote.department
      }

      for(var i = 1; i < 51; i++){
        elmos.phases['Phase ' + i] = {
          equipment : {revenue: 0, cost: 0},
          labor : {revenue: 0, cost: 0, hours: 0},
          material : {revenue: 0, cost: 0},
          other : {revenue: 0, cost: 0, per_diem_revenue: 0, per_diem_cost: 0, travel_revenue: 0, travel_cost: 0},
          subcontracting : {revenue: 0, cost: 0},
          total_revenue: 0,
          total_cost: 0
        }
      }

      // LABOR
      for(var i = 0; i < this.quote.tasks.length; i++){
        var task = this.quote.tasks[i]
        var project_manager_hours = (task.project_manager.number_of_shifts * task.project_manager.employees_per_shift) * (task.project_manager.standard_time + task.project_manager.overtime + task.project_manager.double_time)
        var general_superintendent_hours = (task.general_superintendent.number_of_shifts * task.general_superintendent.employees_per_shift) * (task.general_superintendent.standard_time + task.general_superintendent.overtime + task.general_superintendent.double_time)
        var foreman_hours = (task.foreman.number_of_shifts * task.foreman.employees_per_shift) * (task.foreman.standard_time + task.foreman.overtime + task.foreman.double_time)
        var journeymen_hours = (task.journeymen.number_of_shifts * task.journeymen.employees_per_shift) * (task.journeymen.standard_time + task.journeymen.overtime + task.journeymen.double_time)
        var helper_hours = (task.helper.number_of_shifts * task.helper.employees_per_shift) * (task.helper.standard_time + task.helper.overtime + task.helper.double_time)
        var safety_officer_hours = (task.safety_officer.number_of_shifts * task.safety_officer.employees_per_shift) * (task.safety_officer.standard_time + task.safety_officer.overtime + task.safety_officer.double_time)
        var qaqc_hours = (task.qaqc.number_of_shifts * task.qaqc.employees_per_shift) * (task.qaqc.standard_time + task.qaqc.overtime + task.qaqc.double_time)
        var specialty_craft_1_hours = (task.specialty_craft_1.number_of_shifts * task.specialty_craft_1.employees_per_shift) * (task.specialty_craft_1.standard_time + task.specialty_craft_1.overtime + task.specialty_craft_1.double_time)
        var specialty_craft_2_hours = (task.specialty_craft_2.number_of_shifts * task.specialty_craft_2.employees_per_shift) * (task.specialty_craft_2.standard_time + task.specialty_craft_2.overtime + task.specialty_craft_2.double_time)
        var specialty_craft_3_hours = (task.specialty_craft_3.number_of_shifts * task.specialty_craft_3.employees_per_shift) * (task.specialty_craft_3.standard_time + task.specialty_craft_3.overtime + task.specialty_craft_3.double_time)
        var task_hours = project_manager_hours + general_superintendent_hours + foreman_hours + journeymen_hours + helper_hours + safety_officer_hours + qaqc_hours + specialty_craft_1_hours + specialty_craft_2_hours + specialty_craft_3_hours
        elmos.phases['Phase ' + task.phase].labor.revenue += task.total_revenue
        elmos.phases['Phase ' + task.phase].labor.cost += task.total_cost
        elmos.phases['Phase ' + task.phase].labor.hours += task.total_number_of_hours
        elmos.phases['Phase ' + task.phase].total_revenue += task.total_revenue
        elmos.phases['Phase ' + task.phase].total_cost += task.total_cost
        elmos.total_revenue += task.total_revenue
        elmos.total_cost += task.total_cost
        elmos.total_hours += task_hours
      }

      // SUBCONTRACTING
      for(var i = 0; i < this.quote.subcontractors.length; i++){
        var sub = this.quote.subcontractors[i]
        elmos.phases['Phase ' + sub.phase].subcontracting.revenue += sub.total_revenue
        elmos.phases['Phase ' + sub.phase].subcontracting.cost += sub.total_cost
        elmos.phases['Phase ' + sub.phase].total_revenue += sub.total_revenue
        elmos.phases['Phase ' + sub.phase].total_cost += sub.total_cost
        elmos.total_revenue += sub.total_revenue
        elmos.total_cost += sub.total_cost
      }

      // MATERIAL
      for(var i = 0; i < this.quote.material.length; i++){
        var material = this.quote.material[i]
        elmos.phases['Phase ' + material.phase].material.revenue += material.total_revenue
        elmos.phases['Phase ' + material.phase].material.cost += material.total_cost
        elmos.phases['Phase ' + material.phase].total_revenue += material.total_revenue
        elmos.phases['Phase ' + material.phase].total_cost += material.total_cost
        elmos.total_revenue += material.total_revenue
        elmos.total_cost += material.total_cost
      }

      // MATERIAL - SMALL TOOLS AND CONSUMABERS
      for(var i = 1; i <= 50; i++){
        var revenue = this.quote.small_tools_consumables_material['phase_'+i].revenue
        var cost = this.quote.small_tools_consumables_material['phase_'+i].cost
        elmos.phases['Phase ' + i].material.revenue += revenue
        elmos.phases['Phase ' + i].material.cost += cost
        elmos.phases['Phase ' + i].total_revenue += revenue
        elmos.phases['Phase ' + i].total_cost += cost
        elmos.total_revenue += revenue
        elmos.total_cost += cost
      }

      // EQUIPMENT
      for(var i = 0; i < this.quote.equipment.length; i++){
        var equipment = this.quote.equipment[i]
        elmos.phases['Phase ' + equipment.phase].equipment.revenue += equipment.total_revenue
        elmos.phases['Phase ' + equipment.phase].equipment.cost += equipment.total_cost
        elmos.phases['Phase ' + equipment.phase].total_revenue += equipment.total_revenue
        elmos.phases['Phase ' + equipment.phase].total_cost += equipment.total_cost
        elmos.total_revenue += equipment.total_revenue
        elmos.total_cost += equipment.total_cost
      }
      elmos.phases['Phase ' + this.quote.welding_fuel_phase].equipment.revenue += this.quote.welding_fuel_revenue
      elmos.phases['Phase ' + this.quote.welding_fuel_phase].equipment.cost += this.quote.welding_fuel_cost
      elmos.phases['Phase ' + this.quote.welding_fuel_phase].total_revenue += this.quote.welding_fuel_revenue
      elmos.phases['Phase ' + this.quote.welding_fuel_phase].total_cost += this.quote.welding_fuel_cost
      elmos.total_revenue += this.quote.welding_fuel_revenue
      elmos.total_cost += this.quote.welding_fuel_cost

      elmos.phases['Phase ' + this.quote.forklift_fuel_phase].equipment.revenue += this.quote.forklift_fuel_revenue
      elmos.phases['Phase ' + this.quote.forklift_fuel_phase].equipment.cost += this.quote.forklift_fuel_cost
      elmos.phases['Phase ' + this.quote.forklift_fuel_phase].total_revenue += this.quote.forklift_fuel_revenue
      elmos.phases['Phase ' + this.quote.forklift_fuel_phase].total_cost += this.quote.forklift_fuel_cost
      elmos.total_revenue += this.quote.forklift_fuel_revenue
      elmos.total_cost += this.quote.forklift_fuel_cost

      elmos.phases['Phase ' + this.quote.air_compressor_fuel_phase].equipment.revenue += this.quote.air_compressor_fuel_revenue
      elmos.phases['Phase ' + this.quote.air_compressor_fuel_phase].equipment.cost += this.quote.air_compressor_fuel_cost
      elmos.phases['Phase ' + this.quote.air_compressor_fuel_phase].total_revenue += this.quote.air_compressor_fuel_revenue
      elmos.phases['Phase ' + this.quote.air_compressor_fuel_phase].total_cost += this.quote.air_compressor_fuel_cost
      elmos.total_revenue += this.quote.air_compressor_fuel_revenue
      elmos.total_cost += this.quote.air_compressor_fuel_cost

      elmos.phases['Phase ' + this.quote.manlift_fuel_phase].equipment.revenue += this.quote.manlift_fuel_revenue
      elmos.phases['Phase ' + this.quote.manlift_fuel_phase].equipment.cost += this.quote.manlift_fuel_cost
      elmos.phases['Phase ' + this.quote.manlift_fuel_phase].total_revenue += this.quote.manlift_fuel_revenue
      elmos.phases['Phase ' + this.quote.manlift_fuel_phase].total_cost += this.quote.manlift_fuel_cost
      elmos.total_revenue += this.quote.manlift_fuel_revenue
      elmos.total_cost += this.quote.manlift_fuel_cost

      for(var i = 1; i <= 50; i++){
        var revenue = (this.quote.pickup_trucks['phase_'+i].revenue + this.quote.service_trucks['phase_'+i].revenue)
        var cost = (this.quote.pickup_trucks['phase_'+i].cost + this.quote.service_trucks['phase_'+i].cost)
        elmos.phases['Phase ' + i].equipment.revenue += revenue
        elmos.phases['Phase ' + i].equipment.cost += cost
        elmos.phases['Phase ' + i].total_revenue += revenue
        elmos.phases['Phase ' + i].total_cost += cost
      }
      elmos.total_revenue += (this.quote.pickup_trucks.total_revenue + this.quote.service_trucks.total_revenue)
      elmos.total_cost += (this.quote.pickup_trucks.total_cost + this.quote.service_trucks.total_cost)

      // OTHER

      if(this.quote.require_per_diem){

        for(var p in this.quote.per_diem){
          var roles = ['project_manager','general_superintendent','foreman','journeymen','helper','safety_officer','qaqc','specialty_craft']
          for(var i = 0; i < roles.length; i++){
            var role = roles[i]
            var per_diem_markup = this.quote.per_diem_markup_percentage ? this.quote.per_diem_markup_percentage / 100 : 0
            elmos.phases['Phase ' + p.split('_')[1]].other.revenue += this.quote.per_diem[p][role] * per_diem_markup + this.quote.per_diem[p][role]
            elmos.phases['Phase ' + p.split('_')[1]].other.cost += this.quote.per_diem[p][role]
            elmos.phases['Phase ' + p.split('_')[1]].total_revenue += this.quote.per_diem[p][role] * per_diem_markup + this.quote.per_diem[p][role]
            elmos.phases['Phase ' + p.split('_')[1]].total_cost += this.quote.per_diem[p][role]

            elmos.phases['Phase ' + p.split('_')[1]].other.per_diem_revenue += this.quote.per_diem[p][role] * per_diem_markup + this.quote.per_diem[p][role]
            elmos.phases['Phase ' + p.split('_')[1]].other.per_diem_cost += this.quote.per_diem[p][role]

            elmos.total_revenue += this.quote.per_diem[p][role] * per_diem_markup + this.quote.per_diem[p][role]
            elmos.total_cost += this.quote.per_diem[p][role]
          }
        }
      }

      if(this.quote.require_travel){
        for(var t in this.quote.travel){
          var roles = ['project_manager','general_superintendent','foreman','journeymen','helper','safety_officer','qaqc','specialty_craft']
          for(var i = 0; i < roles.length; i++){
            var role = roles[i]
            var travel_markup = this.quote.travel_markup_percentage ? this.quote.travel_markup_percentage / 100 : 0
            elmos.phases['Phase ' + t.split('_')[1]].other.revenue += this.quote.travel[t][role] * travel_markup + this.quote.travel[t][role]
            elmos.phases['Phase ' + t.split('_')[1]].other.cost += this.quote.travel[t][role]
            elmos.phases['Phase ' + t.split('_')[1]].total_revenue += this.quote.travel[t][role] * travel_markup + this.quote.travel[t][role]
            elmos.phases['Phase ' + t.split('_')[1]].total_cost += this.quote.travel[t][role]
            elmos.phases['Phase ' + t.split('_')[1]].other.travel_revenue += this.quote.travel[t][role] * travel_markup + this.quote.travel[t][role]
            elmos.phases['Phase ' + t.split('_')[1]].other.travel_cost += this.quote.travel[t][role]
            elmos.total_revenue += this.quote.travel[t][role] * travel_markup + this.quote.travel[t][role]
            elmos.total_cost += this.quote.travel[t][role]
          }
        }
      }

      this.quote.elmos = elmos // SET ELMOS IN QUOTE

      Firebase.database().ref('opportunities').child(self.opportunityid).child('quote_last_modified').set(Date.now())
      
      // NEW
      if(self.isNew){
        Firebase.database().ref('quotes').child(self.opportunityid).push(self.quote).then(function(snapshot){
          Firebase.database().ref('totals').child(self.opportunityid).child(snapshot.key).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: true, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet}).then(function(){
            self.$emit('close')
          })
        })
      }

      // EDIT & REPLACE
      if(!self.isNew && save_type === 'replace'){
        this.quote.modified = Date.now()
        this.quote.modified_by = this.current_user
        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).set(self.quote).then(function(){
          Firebase.database().ref('totals').child(self.opportunityid).child(self.quoteid).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: self.quote.show_in_master, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet}).then(function(){
            self.$emit('close')
          })
        })

      }

      // EDIT & REVISE
      if(!self.isNew && save_type === 'revision'){
        var original_quote = JSON.parse(JSON.stringify(self.initial_quote))
        original_quote.archived = Date.now()
        original_quote.archived_by = this.current_user
        this.quote.modified = Date.now()
        this.quote.modified_by = this.current_user
        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).set(self.quote).then(function(){
          Firebase.database().ref('quotes_archive').child(self.opportunityid).child(self.quoteid).push(original_quote).then(function(){
            Firebase.database().ref('totals').child(self.opportunityid).child(self.quoteid).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: self.quote.show_in_master, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet})
          })
          self.initial_quote = JSON.parse(JSON.stringify(self.quote))
        })
      }

      if(!self.isNew && save_type === 'revert'){
        this.quote.reverted = Date.now()
        this.quote.reverted_by = this.current_user
        this.initial_quote.archived = Date.now()
        this.initial_quote.archived_by = this.current_user
        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).set(self.quote).then(function(){
          Firebase.database().ref('quotes_archive').child(self.opportunityid).child(self.quoteid).child(self.view_version).set(self.initial_quote).then(function(){
            Firebase.database().ref('totals').child(self.opportunityid).child(self.quoteid).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: self.quote.show_in_master, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet}).then(function(){
              self.$emit('close')
            })
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .section{
    text-align: left;
    margin: 10px;
  }

  .section-header{
    text-align: left;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .section-subheader{
    text-align: left;
    font-weight: bold;
    padding-top: 20px;
    font-size: 10px;
  }

  #summary-section{
    margin: 10px;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 20px;
    position: relative;
    background: #eeeeee;
    text-align: left;
    width: 850px;
  }

  textarea{
    height: 25px;
  }

  hr{
    margin-top: 20px;
  }

  thead td {
    line-height: 100% !important;
    text-align: center;
    font-size: 12px;
  }

  tr:hover {
    background-color: transparent !important;
  }

  .handle{
    position:absolute;
    top:0px;
    left:0px;
    cursor:pointer
  }
</style>


