<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-body">
              {{message}}
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$emit('cancel')">CANCEL</v-btn>
              <v-btn color="primary" @click="$emit('confirm')">CONFIRM</v-btn>
            </v-card-actions>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<script>

export default {
    name: 'confirm',
    components: {},
    props: {
      message: String
    },
    data(){
      return {

      }
    },
    computed: {

    },
    mounted(){

    },
    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "../styles/global.scss";

  .modal-mask {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    display: table;
  }

  .modal-wrapper {
    position: relative;
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    position: relative;
    max-width: 600px;
    margin: 0px auto;
    padding: 20px 10px 10px 10px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    font-family: Helvetica, Arial, sans-serif;
    overflow: hidden;
  }

  .modal-body {
    text-align: left;
    font-size: 18px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    line-height: 120%;
    padding-bottom: 20px;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }
</style>
