import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import fb_config from './firebase_config.js'
import Firebase from 'firebase/app'
// import Highcharts from 'highcharts';
// import HighchartsVue from "highcharts-vue";
// import LoadFunnel from 'highcharts/modules/funnel';
import moment from 'moment-timezone'
import VueCurrencyFilter from 'vue-currency-filter'
import _ from 'lodash';
import '@/assets/css/alertify.css'

Object.defineProperty(Vue.prototype, '$_', { value: _ });

moment.tz.setDefault('America/New_York')

// LoadFunnel(Highcharts);

// Vue.use(HighchartsVue, { Highcharts });

// Highcharts.setOptions({
//     lang: {
//         thousandsSep: ','
//     }
// });

Vue.config.productionTip = false

Vue.use(VueCurrencyFilter,
{
  symbol : '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
