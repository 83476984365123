<template>
  <v-dialog scrollable persistent v-model="dialog" width="1200px">
    <v-form ref="form" lazy-validation @keydown="$event.keyCode === 13 ? $event.preventDefault() : false">
      <v-card>
        <div class="form-header" v-if="db.field_rates.material_rates[materialkey]">{{db.field_rates.material_rates[materialkey].item}}</div>

        <v-card-text>
            <v-card style="">
              <v-card-text style="text-align:left">
              <div><v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Item Name" outlined v-model="material.item" v-on:keydown.enter.prevent=''></v-text-field></div>
              <div><span style="color:red" v-if="material_already">Material already exists.</span></div>
              <div style="margin-top:10px"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" label="Item Cost" outlined min="0" v-model.number="material.cost" v-on:keydown.enter.prevent='' prefix="$"></v-text-field></div>
              </v-card-text>
            </v-card>
        </v-card-text>

        <v-card-actions>
          <div style="color:blue;font-size:10px;margin-left:10px;cursor:pointer" @click="deleteMaterial()">delete material</div>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('close')" small>CANCEL</v-btn>
          <v-btn color="primary" @click="saveRate()" small :disabled="material.item.trim() === '' || material_already">SAVE</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import { formatCurrency } from '../mixins/formatCurrency.js'

export default {
  name: 'EditFieldMaterial',
  components: {},
  mixins: [formatCurrency],
  props: {
    materialkey: String
  },
  data(){
    return{
      dialog: true,
      existing_material: [],
      material_already: false,
      material: {
        item: '',
        cost: 0
      }
    }
  },
  beforeMount(){
    var self = this
    for(var id in self.db.field_rates.material_rates){
      var material = self.db.field_rates.material_rates[id].item.trim().toLocaleLowerCase()
      if(self.existing_material.indexOf(material) === -1 && material !== self.db.field_rates.material_rates[self.materialkey].item.trim().toLocaleLowerCase()){
        self.existing_material.push(material)
      }
    }
    self.material = JSON.parse( JSON.stringify(self.db.field_rates.material_rates[self.materialkey]) )
  },
  watch: {
    material: {
      deep: true,
      handler(){
        this.material_already = this.existing_material.indexOf(this.material.item.trim().toLocaleLowerCase()) !== -1 ? true : false
        this.material['cost'] = typeof(this.material['cost']) === 'number' ? this.material['cost'] : 0
      }
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user'
    })
  },
  methods:{
    deleteMaterial(){
      var self = this
      var confirm_delete = confirm('Are you sure you want to delete ' + self.db.field_rates.material_rates[self.materialkey].item + '?')
      if(confirm_delete){
        Firebase.database().ref('field_rates/material_rates').child(self.materialkey).remove().then(function(){
          self.$emit('close')
          self.$store.state.notification = 'Material successfully deleted.'
        })
      }
    },
    saveRate(){
      var self = this
      Firebase.database().ref('field_rates/material_rates').child(self.materialkey).set(self.material).then(function(){
        self.$emit('close')
      })
    }
  }
}
</script>

<style lang="scss" scoped>


</style>


