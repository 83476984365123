<template>
  <div class="contact">
    <v-container fluid>
      <v-card>
        <v-card-text>

          <v-row class="inputs-container" v-if="db.contacts[id]">

            <v-col cols="12">
              <v-icon large>mdi-card-account-mail</v-icon>
              <span style="font-size:16px;font-weight:bold;padding-left:10px">Contact: {{db.contacts[id].first_name + ' ' + db.contacts[id].middle_name + ' ' + db.contacts[id].last_name}}</span>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Created By</div>
              <div class="display-value">{{db.contacts[id].added_by ? (db.users[db.contacts[id].added_by].first_name + ' ' + db.users[db.contacts[id].added_by].last_name) : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Contact Owner</div>
              <div class="display-value">{{db.contacts[id].owner ? (db.users[db.contacts[id].owner].first_name + ' ' + db.users[db.contacts[id].owner].last_name) : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Account</div>
              <div class="display-value">{{db.contacts[id].account ? db.accounts[db.contacts[id].account].name : ''}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Email</div>
              <div class="display-value">{{db.contacts[id].email}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Phone</div>
              <div class="display-value">{{db.contacts[id].phone}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Mobile</div>
              <div class="display-value">{{db.contacts[id].mobile}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Fax</div>
              <div class="display-value">{{db.contacts[id].fax}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Title</div>
              <div class="display-value">{{db.contacts[id].title}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Department</div>
              <div class="display-value">{{db.contacts[id].department}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Mailing Address</div>
              <div class="display-value">{{db.contacts[id].mailing_address}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Mailing Street</div>
              <div class="display-value">{{db.contacts[id].mailing_street}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Mailing City</div>
              <div class="display-value">{{db.contacts[id].mailing_city}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Mailing State</div>
              <div class="display-value">{{db.contacts[id].mailing_state}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Mailing Country</div>
              <div class="display-value">{{db.contacts[id].mailing_country}}</div>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="display-label">Mailing Zip</div>
              <div class="display-value">{{db.contacts[id].mailing_zip}}</div>
            </v-col>

          </v-row>

        </v-card-text>
        <v-btn color="primary" small v-if="is_admin" @click="deleteContact()" style="margin:5px">Delete</v-btn>
        <v-btn color="primary" small @click="edit_form = true" style="margin:10px" v-if="true">Edit</v-btn>
      </v-card>

    </v-container>
    <ContactForm v-if="edit_form" :id="id" @close="edit_form = false" />
  </div>
</template>


<script>
import Firebase from 'firebase/app'
import Confirm from '../components/Confirm.vue'
import { mapGetters, mapActions } from 'vuex'
import ContactForm from '../components/forms/ContactForm.vue'

export default {
  name: 'contact',
  components: {ContactForm},
  data(){
    return{
      edit_form: false,
      id: this.$route.params['contactId']
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      is_admin: 'is_admin',
    })
  },
  methods:{
    deleteContact(){

      var self = this
      var linked_logged_calls = []
      var linked_opportunities = []
      var associated_record_types = []

      for(var call in self.db.logged_calls){
        if(self.db.logged_calls[call].contact === self.id){
          linked_logged_calls.push(call)
        }
      }

      Firebase.database().ref('opportunities').orderByChild('contact_to_send_quote').equalTo(self.id).once('value',function(snapshot){
        var opportunities = snapshot.val()

        for(var opp in opportunities){
          linked_opportunities.push(opportunities[opp].name)
          console.log(linked_opportunities)
        }

        if(linked_logged_calls.length){
          associated_record_types.push('Contact for one or more Logged Calls')
        }

        if(linked_opportunities.length){
          associated_record_types.push('Contact for one or more Opportunities')
        }

        if(associated_record_types.length){
          var str = '<div style="margin-bottom:25px;text-align:left">' + self.db.contacts[self.id].first_name + ' ' + self.db.contacts[self.id].last_name + ' cannot be deleted for the following reason(s):</div>'
          for(var i = 0; i < associated_record_types.length; i++){
            str += '<div style="margin-bottom:5px;text-align:left">' + associated_record_types[i] + '</div>'
          }
          self.$store.state.notification = str
        }else{
          var confirm_delete = confirm('Are you sure you wanted to delete contact ' + self.db.contacts[self.id].first_name + ' ' + self.db.contacts[self.id].last_name + '?')
          if(!confirm_delete){return}
          Firebase.database().ref('contacts').child(self.id).remove().then(function(){
            self.$router.push('/contacts')
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>


