<template>
  <div class="admin">

    <v-container fluid>
      <v-card>
        <v-card-text>
          <div style="font-size:18px">Administration</div>
          <v-row style="max-width:500px">
            <v-col cols="12" sm="12" md="12">
              <v-btn color="#243872" style="width:250px;" large class="mr-0 white--text foo" @click="$router.push('/users')">
                <v-icon large>mdi-table-account</v-icon> MANAGE USERS
              </v-btn>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-btn color="#243872" style="width:250px" large class="mr-0 white--text foo" @click="$router.push('/rates')">
                <v-icon large>mdi-table-edit</v-icon> MANAGE RATES
              </v-btn>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-btn color="#243872" style="width:250px" large class="mr-0 white--text foo" @click="$router.push('/manage-reports')">
                <v-icon large>mdi-table-search</v-icon> MANAGE REPORTS
              </v-btn>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-btn color="#243872" style="width:250px" large class="mr-0 white--text foo" @click="$router.push('/transfer')">
                <v-icon large>mdi-transfer</v-icon> TRANSFER OWNERSHIP
              </v-btn>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-btn color="#243872" style="width:250px" large class="mr-0 white--text foo" @click="addDepartment()">
                <v-icon large>mdi-account-box-multiple</v-icon> ADD DEPARTMENT
              </v-btn>
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
    </v-container>

    <AddDepartmentOU v-if="add_department_popup" @close="add_department_popup = false" />
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import AddDepartmentOU from '../components/AddDepartmentOU.vue';
export default {
  name: 'admin',
  components: {AddDepartmentOU},
  data(){
    return{
      add_department_popup: false
    }
  },
  beforeMount(){
    if(!this.is_admin){
      this.$router.push('/home')
    }
  },
  beforeDestroy(){

  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      is_admin: 'is_admin'
    })
  },
  methods:{
    addDepartment(){
      this.add_department_popup = true

    }
  }
}
</script>

<style lang="scss">
  .foo .v-btn__content{
    justify-content: left !important;
  }
</style>


