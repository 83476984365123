<template>
      <v-dialog scrollable persistent v-model="dialog" width="800">
        <v-form ref="form" lazy-validation>
          <v-card>
            <div class="form-header">Select Quote Sheet</div>
              <v-container style="position:relative">
                <v-row class="inputs-container">

                  <v-col cols="12" sm="12" md="12">
                    <v-select dense :items="[{text:'MSA'},{text:'Excel'},{text:'Field'},{text:'Civil'},{text:'Electrical'},{text:'ELMCO Fabrication'},{text:'ELMCO Field'},{text:'ELMCO Machine'},{text:'Engineering'},{text:'Transportation'},{text:'Mechanical'},{text:'Pump'},{text: 'Specialty'},{text:'Machine'}]" outlined label="Select Quote Sheet" v-model="quote_sheet"></v-select>
                  </v-col>

                </v-row>
              </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$emit('close')">Cancel</v-btn>
              <v-btn color="primary" @click="$emit('quote',quote_sheet)" :disabled="!quote_sheet">Start Quote</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Firebase from 'firebase/app'
  import _ from 'lodash';

  export default {
    name: 'QuoteSheetSelection',
    props: {

    },
    data(){
      return{
        dialog: true,
        quote_sheet: null
      }
    },
    mounted(){
    },
    computed: {
      ...mapGetters({
        db: 'db',
        current_user: 'current_user',
        active_accounts: 'active_accounts',
        active_contacts: 'active_contacts'
      })
    },
    methods: {

    }
  }
</script>
<style lang="scss">

</style>

