<template>
  <div>
    <div style="padding:0px 0px 10px 5px;font-size:18px">Logged Calls</div>
    <v-btn color="primary" small @click="log_call = true" style="position:absolute;top:10px;right:10px"><v-icon small class="mr-2">mdi-book-open</v-icon>LOG A CALL</v-btn>

    <v-expansion-panels accordion style="height:400px;overflow-y:auto">
      <v-expansion-panel v-for="(call,i) in getLoggedCalls" :key="i" v-if="db.logged_calls">
        <v-expansion-panel-header>
          <div style="font-size:12px;color:black">{{call.date}} {{call.name}} contacted {{call.account_name}}</div>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
        <div style="font-size:10px;line-height:120%">Date: {{call.date}} {{call.time}}</div>
        <div style="font-size:10px;line-height:120%">Contact Type: {{call.subject}}</div>
        <div style="font-size:10px;line-height:120%">Contact Name: {{call.contact_name}}</div>
        <div style="font-size:10px;line-height:120%">Comments: {{call.comments}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <LogCall v-if="log_call" @close="log_call = false"/>
  </div>
</template>

<script>
import LogCall from '../../components/LogCall.vue'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  props: {},
  components: {LogCall},
  created() {},
  data() {
    return {
      log_call: false
    };
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user'
    }),
    getLoggedCalls() {
        var calls_clone = JSON.parse(JSON.stringify(this.db.logged_calls))
        var calls_arr = []

        for(var call in calls_clone){
          calls_clone[call].name = this.db.users[calls_clone[call].added_by].first_name + ' ' + this.db.users[calls_clone[call].added_by].last_name
          calls_clone[call].date = moment(calls_clone[call].created_date).format('MM/DD/YY')
          calls_clone[call].time = moment(calls_clone[call].created_date).format('h:mma')
          calls_clone[call].contact_name = calls_clone[call].contact ? (this.db.contacts[calls_clone[call].contact].first_name + ' ' + this.db.contacts[calls_clone[call].contact].last_name) : 'not provided'
          calls_clone[call].account_name = this.db.accounts[calls_clone[call].account].name
          calls_arr.push(calls_clone[call])
        }

        return _.orderBy(calls_arr, ['created_date'],['desc']);
    }
  },
  watch:{

  },
  methods: {

  }
};
</script>
