<template>
  <div>

    <div style="padding:0px 0px 10px 5px;font-size:18px">Open Opportunities By Owner</div>

    <v-expansion-panels accordion  style="height: 420px;overflow-y:auto;padding:5px">
      <v-expansion-panel v-for="(item) in data" :key="item.id">
        <v-expansion-panel-header class="thin-panel-header">
          <div style="display: table">
            <div style="display: table-cell;width:35%;padding-right:20px;padding-left:15px">
              <span style='font-size:12px;'>{{item.name}}</span>
            </div>
            <div style="display: table-cell;width:65%;font-size:12px;color:#696969;text-align:right;padding-right:20px">
              <span style="font-weight:bold;font-size:12px">{{formatCurrency(item['total'])}}</span>
            </div>
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content style="">
          <v-simple-table dense font>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Opportunity</th>
                  <th class="text-left">Stage</th>
                  <th class="text-left">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="opp in item.open_opportunities">
                  <td style="font-size:10px"><span style="color:#223773;cursor: pointer;" @click="$router.push({name: 'Opportunity', params: {opportunityId: opp.id}})">{{opp.name}}</span></br><span style="font-size:10px;font-style: italic">{{opp.account}}</span></td>
                  <td style="font-size:10px">{{opp.stage}}</td>
                  <td style="font-size:10px">{{formatCurrency(opp.amount)}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>
</template>

<script>
import { formatCurrency } from '../../mixins/formatCurrency.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    data: Array,
    total: Number
  },
  components: {},
  created() {
    // this.updateData()
  },
  computed: {

  },
  mixins: [formatCurrency]
};
</script>


<style lang="scss" scoped>

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
   font-size: 10px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
   font-size: 10px !important;
/*   -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;*/
}

.v-expansion-panel-header.thin-panel-header{
  min-height: 22px !important;
  padding: 0px 2px !important;;
}

</style>
