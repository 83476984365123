<template>
  <v-dialog scrollable persistent v-model="dialog" width="100%">
    <v-form ref="form" lazy-validation>
      <v-card style="background:#eeeeee" v-if="form_mounted">
        <div class="form-header">Engineering Quote Sheet</div>
        <v-card-text>
          <!-- PREVIOUS VERSIONS -->
          <v-card style="margin-top:20px" v-if="quote_archive && !isNew">
            <v-card-text style="text-align:left">
              <div class="section-header">PREVIOUS VERSIONS</div>

                <v-simple-table dense style="width:600px;">
                  <tbody>
                    <tr v-for="(archived_quote) in sorted_archive">
                      <td style="width:75px" v-if="view_version !== archived_quote.key"><v-btn color="primary" @click="viewPreviousVersion(archived_quote.key)" x-small>VIEW</v-btn></td>
                      <td style="width:75px;text-align:center" v-if="view_version === archived_quote.key">viewing</td>
                      <td>{{archived_quote.quote_title}} (created on {{formatDate(archived_quote.created)}})</td>
                    </tr>
                  </tbody>
                </v-simple-table>
            </v-card-text>
          </v-card>

          <!-- GENERAL -->
          <v-card class="section">
            <v-card-text style="text-align:left">
              <div class="section-header">GENERAL</div>
              <v-row>
                <v-col cols="6">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Quote Title*" outlined v-model="quote.quote_title"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select dense hide-details="auto" :items="ou_departments" item-text="department" outlined label="Department*" v-model="quote.department">
                      <template slot="selection" slot-scope="data">{{data.item.department}} ({{ data.item.code }})</template>
                      <template slot="item" slot-scope="data">{{data.item.department}} ({{ data.item.code }})</template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" label="Straight Time Markup %" outlined v-model.number="quote.st_markup_percentage" @change="" suffix="%" disabled></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" label="Over-Time Markup %" outlined v-model.number="quote.ot_markup_percentage" oninput="if(Number(this.value) > 100) this.value = 100" @change="" suffix="%"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" label="Subcontracts Markup %" outlined v-model.number="quote.sub_markup_percentage" @change="" suffix="%"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Duration" class="number-field" min="0" v-model.number="quote.lead_time_shifts"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-textarea dense hide-details="auto" label="Scope of Work" outlined v-model="quote.scope_of_work" auto-grow></v-textarea>
                </v-col>
                <v-col cols="6">
                  <v-textarea dense hide-details="auto" label="Clarifications" outlined v-model="quote.clarifications" auto-grow></v-textarea>
                </v-col>
              </v-row>

              <hr>

              <v-row dense>
                <v-col cols="6">
                  <v-checkbox dense label="Use Master Phase" hide-details v-model="quote.use_master_phase"></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense v-if="quote.use_master_phase">
                <v-col cols="2">
                  <v-select dense hide-details="auto" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Master Phase" v-model="quote.master_phase"></v-select></v-col>
                </v-col>
              </v-row>

              <hr>

              <v-row>
                <v-col cols="3">
                  <v-checkbox dense label="Quote Completed" hide-detail v-model="quote.completed" style="margin:0px 0px 0px 10px"></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-checkbox dense label="Quote Reviewed" hide-detail v-model="quote.reviewed" style="margin:0px 0px 0px 10px"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- LABOR -->
          <v-card class="section">
            <v-card-text style="text-align:left">
              <div class="section-header">IN HOUSE LABOR</div>
              <div v-for="(labor, index) in quote.labor" style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:30px 20px;position:relative">
                <v-btn color="primary" @click="deleteLabor(index)" x-small fab style="position:absolute;top:5px;right:5px;width:20px;height:20px;"><v-icon x-small>mdi-close</v-icon></v-btn>
                <v-row dense>
                    <v-col cols="7">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Description" outlined v-model="quote.labor[index].description"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="In House Coordination Hours" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()"  min="0" v-model.number="quote.labor[index].coordination_hours" @change="setCostRevenue()"></v-text-field>
                      <div style="font-size:12px;line-height:150%">ST Cost: {{formatCurrency(quote.rates['in_house'].st_cost)}}, OT Cost: {{formatCurrency(quote.rates['in_house'].ot_cost)}}</div>
                      <div style="font-size:12px;line-height:150%">ST Revenue: {{formatCurrency(quote.rates['in_house'].st_revenue)}}, OT Revenue: {{formatCurrency(quote.rates['in_house'].ot_revenue)}}</div>
                    </v-col>
                    <v-col cols="2">
                      <v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.labor[index].phase"></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4">
                      <div style="line-height:150%">Total Labor Cost: {{formatCurrency(quote.labor[index].coordination_cost)}}</div>
                      <div style="line-height:150%">Total Labor Revenue: {{formatCurrency(quote.labor[index].coordination_revenue)}}</div>
                    </v-col>
                </v-row>

              </div>
              <v-btn color="primary" @click="addLabor()" small>ADD IN HOUSE LABOR</v-btn>
            </v-card-text>
          </v-card>


          <!-- SUBCONTRACTORS -->
          <v-card class="section">
            <v-card-text style="text-align:left">
              <div class="section-header">SUBCONTRACTORS</div>
              <div v-for="(subcontractor, index) in quote.subcontractors" style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:30px 20px;position:relative">
                <v-btn color="primary" @click="deleteSubcontractor(index)" x-small fab style="position:absolute;top:5px;right:5px;width:20px;height:20px;"><v-icon x-small>mdi-close</v-icon></v-btn>
                <v-row dense>
                    <v-col cols="7">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Subcontractor" outlined v-model="quote.subcontractors[index].subcontractor"></v-text-field>
                    </v-col>

                    <v-col cols="3">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Subcontractor Cost" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()"  min="0" v-model.number="quote.subcontractors[index].subcontractor_cost" prefix="$" @change="setCostRevenue()"></v-text-field>
                    </v-col>

                    <v-col cols="2">
                      <v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.subcontractors[index].phase"></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4">
                      <div style="line-height:150%">Total Subcontractor Cost: {{formatCurrency(quote.subcontractors[index].subcontractor_cost)}}</div>
                      <div style="line-height:150%">Total Subcontractor Revenue: {{formatCurrency(quote.subcontractors[index].subcontractor_revenue)}}</div>
                    </v-col>
                </v-row>

              </div>
              <v-btn color="primary" @click="addSubcontractor()" small>ADD SUBCONTRACTOR</v-btn>
            </v-card-text>
          </v-card>

          <!-- PER DIEM -->
          <v-card class="section">
            <v-card-text style="text-align:left">
              <div class="section-header">PER DIEM</div>

              <v-row>
                <v-col cols="2">
                  <v-select dense hide-details="auto" :items="[{text: 'YES', value: true},{text: 'NO', value: false}]" outlined label="Per Diem Available" v-model="quote.per_diem_required"></v-select>
                  <div v-if="quote.per_diem_required">
                    <div style="color:gray;line-height:150%;font-size:12px">Cost Per Meal: <span class="editable-rate" @click="editQuoteRate(['per_diem_rates','meals'])">{{formatCurrency(quote.rates.per_diem_rates.meals)}}</span></div>
                    <div style="color:gray;line-height:150%;font-size:12px">Cost Per Hotel: <span class="editable-rate" @click="editQuoteRate(['per_diem_rates','hotels'])">{{formatCurrency(quote.rates.per_diem_rates.hotels)}}</span></div>
                  </div>
                </v-col>

                <v-col cols="2" v-if="quote.per_diem_required">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Per Diem Days Needed" outlined min="0" v-model.number="quote.per_diem_days_needed"></v-text-field>
                </v-col>

                <v-col cols="2" v-if="quote.per_diem_required">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Per Diem Markup %" outlined  min="0"  v-model.number="quote.per_diem_markup_percentage" suffix="%"></v-text-field>
                </v-col>

                <v-col cols="2" v-if="quote.per_diem_required">
                  <v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.per_diem_phase"></v-select>
                </v-col>

                <v-col cols="4" v-if="quote.per_diem_required">
                  <div style="font-size:12px;line-height:150%">Total Per Diem Cost: {{formatCurrency(quote.per_diem_cost_revenue['Phase ' + quote.per_diem_phase].cost)}}</div>
                  <div style="font-size:12px;line-height:150%">Total Per Diem Revenue: {{formatCurrency(quote.per_diem_cost_revenue['Phase ' + quote.per_diem_phase].revenue)}}</div>
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>

          <div id="summary-section">
            <h4>Account: {{db.accounts[quote.account].name}}</h4>
            <h4>Opportunity: {{opportunity.name}}</h4>
            <h4>Quote: {{quote.quote_title}}</h4>
            <h4>Department: {{quote.department}}</h4>
            <h4>Duration: {{quote.lead_time_shifts}}</h4>

            <div class="section-subheader">LABOR SUMMARY</div>
            <div class="report-page" style="border:4px solid #223773;padding:10px;margin:10px 0px;background:white">
              <v-simple-table dense>
                <thead style="font-weight:bold">
                  <td>Description</td>
                  <td>Coordination Hours</td>
                  <td>Phase</td>
                  <td>Coordination Cost</td>
                  <td>Coordination Revenue</td>
                </thead>
                <tbody>
                  <tr v-for="(labor,index) in quote.labor">
                    <td style="text-align:left">{{labor.description}}</td>
                    <td style="text-align:center">{{labor.coordination_hours}}</td>
                    <td style="text-align:center">{{labor.phase}}</td>
                    <td style="text-align:center">{{formatCurrency(labor.coordination_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(labor.coordination_revenue)}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(laborTotalCost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(laborTotalRevenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="section-subheader">SUBCONTRACTOR SUMMARY</div>
            <div class="report-page" style="border:4px solid #223773;padding:10px;margin:10px 0px;background:white">
              <v-simple-table dense>
                <thead style="font-weight:bold">
                  <td>Subcontractor</td>
                  <td>Phase</td>
                  <td>Subcontractor Cost</td>
                  <td>Subcontractor Revenue</td>
                </thead>
                <tbody>
                  <tr v-for="(subcontractor,index) in quote.subcontractors">
                    <td style="text-align:left">{{subcontractor.subcontractor}}</td>
                    <td style="text-align:center">{{subcontractor.phase}}</td>
                    <td style="text-align:center">{{formatCurrency(subcontractor.subcontractor_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(subcontractor.subcontractor_revenue)}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(subcontractorsTotalCost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(subcontractorsTotalRevenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="section-subheader">PER DIEM SUMMARY</div>
            <div class="report-page" style="border:4px solid #223773;padding:10px;margin:10px 0px;background:white">
              <div style="font-weight:bold;font-style:italic" v-if="!quote.per_diem_required">Per Diem Not Required for this quote</div>
              <v-simple-table dense v-if="quote.per_diem_required">
                <thead style="font-weight:bold">
                  <td>Per Diem Days Needed</td>
                  <td>Phase</td>
                  <td>Per Diem Total Cost</td>
                  <td>Per Diem Total Revenue</td>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align:center">{{quote.per_diem_days_needed}}</td>
                    <td style="text-align:center">{{quote.per_diem_phase}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.per_diem_cost_revenue['Phase '+quote.per_diem_phase].cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.per_diem_cost_revenue['Phase '+quote.per_diem_phase].revenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

          </div>

        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="printJobReport()" small>PRINT JOB REPORT</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeForm()" small>CLOSE</v-btn>
          <v-btn color="primary" @click="saveQuote('replace')" small v-if="!view_version" :disabled="!unsavedChanges">SAVE</v-btn>
          <v-btn color="primary" @click="saveQuote('revision')" small v-if="!isNew && !view_version" :disabled="!unsavedChanges">SAVE AS REVISION</v-btn>
          <v-btn color="primary" @click="saveQuote('revert')" small v-if="view_version" :disabled="!unsavedChanges">REVERT BACK TO THIS VERSION</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
    <EditQuoteRate v-if='edit_quote_rate' @close='edit_quote_rate = false' @saveQuoteRate='saveQuoteRate' :initialrate='edit_quote_rate_initial_val'/>
  </v-dialog>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import { formatCurrency } from '../../mixins/formatCurrency.js'
import EditQuoteRate from '../EditQuoteRate.vue'
import { jsPDF } from "jspdf";
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas'

export default {
  name: 'Engineering',
  components: {EditQuoteRate},
  mixins: [formatCurrency],
  props:{
    opportunityid: String,
    quoteid: [String, Boolean]
  },
  data(){
    return{
      form_mounted: false,
      dialog: true,
      edit_quote_rate: false,
      edit_quote_rate_path_arr: [],
      edit_quote_rate_initial_val: null,
      quote: {
        st_markup_percentage: 80,
        ot_markup_percentage: 20,
        sub_markup_percentage: 18,
        quote_sheet: 'Engineering',
        department: 'MSM CF Engineering',
        job_id_code: '007',
        quote_type: 'New',
        quote_title: '',
        account: '',
        opportunity: '',
        created_by: '',
        created: '',
        scope_of_work: '',
        clarifications: '',
        total_cost: 0,
        total_revenue: 0,
        subcontractors: [],
        labor: [],
        per_diem_required: false,
        per_diem_markup_percentage: 0,
        per_diem_cost_revenue: {},
        per_diem_days_needed: 0,
        per_diem_phase: 1,
        rates: {},
        lead_time_shifts: 1,
        completed: false,
        reviewed: false,
        show_in_master: true,
        use_master_phase: false,
        master_phase: 1,        
      },
      quote_archive: {},
      initial_quote: {},
      isNew: true,
      view_version: false,
      opportunity: {}
    }
  },
  beforeMount(){
    var self = this

    Firebase.database().ref('opportunities').child(self.opportunityid).once('value',function(snapshot){
      self.opportunity = JSON.parse(JSON.stringify(snapshot.val()))
      self.isNew = self.quoteid ? false : true

      if(self.isNew){ // new quote
        self.quote.created_by = self.current_user
        self.quote.account = self.opportunity.account
        self.quote.opportunity = self.opportunityid
        self.quote.created = Date.now()
        self.quote.rates = JSON.parse(JSON.stringify(self.db.engineering_rates))
        self.initial_quote = JSON.parse(JSON.stringify(self.quote))
        self.form_mounted = true
      }

      if(!self.isNew){ // edit quote
        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).once('value',function(quote_snapshot){
          self.quote = JSON.parse(JSON.stringify(quote_snapshot.val()))
          self.setEmptyQuoteData()
        }).then(function(){

          Firebase.database().ref('quotes_archive').child(self.opportunityid).child(self.quoteid).once('value',function(quotes_archive_snapshot){
            self.quote_archive = quotes_archive_snapshot.val()
            self.initial_quote = JSON.parse(JSON.stringify(self.quote))
            self.form_mounted = true
          })
        })
      }
    })
  },
  watch: {
    'quote.use_master_phase': function(val){
      if(!this.quote.master_phase){
        this.quote.master_phase = 1
      }
      this.setCostRevenue()
      this.setPerDiemCostRevenue()
    },
    'quote.master_phase': function(val){
      this.setCostRevenue()
      this.setPerDiemCostRevenue()
    },    
    'quote.department': function(val){
      for(var i = 0; i < this.ou_departments.length; i++){
        if(this.ou_departments[i].department === val){
          this.quote.job_id_code = this.ou_departments[i].code
        }
      }
    },
    'quote.ot_markup_percentage': function(val){
      this.quote.st_markup_percentage = 100 - val
      this.setCostRevenue()
    },
    'quote.sub_markup_percentage': function(val){
      this.setCostRevenue()
    },
    'quote.subcontractors': {
      deep: true,
      handler(){
        this.setCostRevenue()
        this.setPerDiemCostRevenue()
      }
    },
    'quote.per_diem_required': function(val){
      this.setPerDiemCostRevenue()
    },
    'quote.per_diem_days_needed': function(val){
      this.setPerDiemCostRevenue()
    },
    'quote.per_diem_markup_percentage': function(val){
      this.setPerDiemCostRevenue()
    },
    'quote.per_diem_phase': function(val){
      this.setPerDiemCostRevenue()
    },
    'quote.rates': {
      deep: true,
      handler(){
        this.setCostRevenue()
        this.setPerDiemCostRevenue()
      }
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      active_accounts: 'active_accounts',
      active_users: 'active_users',
      ou_departments: 'ou_departments'
    }),
    sorted_archive(){
      var arr = []
      var archive = this.quote_archive
      for(var a in archive){
        archive[a].key = a
        arr.push(archive[a])
      }
      return _.orderBy(arr, ['archived'],['asc']);
    },
    unsavedChanges() {
      return JSON.stringify(this.initial_quote) !== JSON.stringify(this.quote)
    },
    subcontractorsTotalCost(){
      var total = 0
      for(var i = 0; i < this.quote.subcontractors.length; i++){
        total += this.quote.subcontractors[i].subcontractor_cost
      }
      return total
    },
    subcontractorsTotalRevenue(){
      var total = 0
      for(var i = 0; i < this.quote.subcontractors.length; i++){
        total += this.quote.subcontractors[i].subcontractor_revenue
      }
      return total
    },
    laborTotalCost(){
      var total = 0
      for(var i = 0; i < this.quote.labor.length; i++){
        total += this.quote.labor[i].coordination_cost
      }
      return total
    },
    laborTotalRevenue(){
      var total = 0
      for(var i = 0; i < this.quote.labor.length; i++){
        total += this.quote.labor[i].coordination_revenue
      }
      return total
    }
  },
  methods:{
    closeForm(){
      if(this.unsavedChanges){
        var confirm_close = confirm('You have unsaved changes. Are you sure you want to close this form? Any unsaved changes will be lost.')
        if(confirm_close){
          this.$emit('close')
        }
      }else{
        this.$emit('close')
      }
    },
    printJobReport(){
      var self = this
      self.$store.state.isLoading = true
      html2canvas(document.getElementById("summary-section")).then(function(canvas) {
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF('p', 'mm');
        var position = 10;
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight; // top padding for other pages
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save( 'job_report.pdf', {returnPromise: true}).then(self.$store.state.isLoading = false);
      });
    },
    editQuoteRate(rate_path_arr){
      this.edit_quote_rate_path_arr = rate_path_arr
      this.edit_quote_rate_initial_val = this.quote.rates[rate_path_arr[0]][rate_path_arr[1]]
      this.edit_quote_rate = true
    },
    saveQuoteRate(val){
      this.quote.rates[this.edit_quote_rate_path_arr[0]][this.edit_quote_rate_path_arr[1]] = val
      this.edit_quote_rate_path_arr = []
      this.edit_quote_rate = false
      this.edit_quote_rate_initial_val = null
    },
    setCostRevenue(){

      for(var i = 0; i < this.quote.labor.length; i++){
        var labor = this.quote.labor[i]
        var st_rate = this.quote.st_markup_percentage / 100
        var ot_rate = this.quote.ot_markup_percentage / 100
        var sub_rate = this.quote.sub_markup_percentage / 100

        if(this.quote.use_master_phase){
          this.quote.labor[i].phase = this.quote.master_phase
        }

        this.quote.labor[i].coordination_cost = (this.quote.labor[i].coordination_hours * st_rate * this.quote.rates['in_house'].st_cost) + (this.quote.labor[i].coordination_hours * ot_rate * this.quote.rates['in_house'].ot_cost)
        this.quote.labor[i].coordination_revenue = (this.quote.labor[i].coordination_hours * st_rate * this.quote.rates['in_house'].st_revenue) + (this.quote.labor[i].coordination_hours * ot_rate * this.quote.rates['in_house'].ot_revenue)
      }

      for(var i = 0; i < this.quote.subcontractors.length; i++){
        var subcontractor = this.quote.subcontractors[i]
        var sub_rate = this.quote.sub_markup_percentage / 100

        if(this.quote.use_master_phase){
          this.quote.subcontractors[i].phase = this.quote.master_phase
        }

        this.quote.subcontractors[i].subcontractor_cost = typeof(this.quote.subcontractors[i].subcontractor_cost) === 'number' ? this.quote.subcontractors[i].subcontractor_cost : 0
        this.quote.subcontractors[i].subcontractor_revenue = this.quote.subcontractors[i].subcontractor_cost * sub_rate +  this.quote.subcontractors[i].subcontractor_cost
      }

    },
    setEmptyQuoteData(){
      if(!this.quote.subcontractors){
        this.$set(this.quote, 'subcontractors', [])
      }
      if(!this.quote.labor){
        this.$set(this.quote, 'labor', [])
      }
      if(!this.quote.per_diem_cost_revenue){
        this.$set(this.quote, 'per_diem_cost_revenue', {})
      }
    },
    deleteSubcontractor(index){
      var confirm_delete = confirm('Are you sure you want to delete this subcontractor?')
      if(confirm_delete){
        this.quote.subcontractors.splice(index,1)
      }
    },
    deleteLabor(index){
      var confirm_delete = confirm('Are you sure you want to delete this in house labor?')
      if(confirm_delete){
        this.quote.labor.splice(index,1)
      }
    },
    viewPreviousVersion(id){
      var previous_quote = this.quote_archive[id]
      this.quote = previous_quote
      this.view_version = id
      this.setEmptyQuoteData()
    },
    formatDate(date){
      return Moment(date).format('MM/DD/YYYY')
    },
    addLabor(){
      this.quote.labor = this.quote.labor ? this.quote.labor : []
      var labor_obj = {
        description: '',
        coordination_hours: 0,
        coordination_cost: 0,
        coordination_revenue: 0,
        phase: this.quote.use_master_phase ? this.quote.master_phase : 1,
      }
      this.quote.labor.push(labor_obj)
      this.$forceUpdate();
    },
    addSubcontractor(){
      this.quote.subcontractors = this.quote.subcontractors ? this.quote.subcontractors : []
      var subcontractor_obj = {
        subcontractor: '',
        subcontractor_cost: 0,
        subcontractor_revenue: 0,
        phase: this.quote.use_master_phase ? this.quote.master_phase : 1,
      }
      this.quote.subcontractors.push(subcontractor_obj)
      this.$forceUpdate();
    },
    setPerDiemCostRevenue(){
      var per_diem = {}
      var per_diem_cost = (this.quote.rates.per_diem_rates.hotels + this.quote.rates.per_diem_rates.meals) * this.quote.per_diem_days_needed
      var per_diem_markup = this.quote.per_diem_markup_percentage ? this.quote.per_diem_markup_percentage / 100 : 0

      if(this.quote.use_master_phase){
        this.quote.per_diem_phase = this.quote.master_phase
      }

      per_diem['Phase ' + this.quote.per_diem_phase] = {}
      per_diem['Phase ' + this.quote.per_diem_phase].cost = per_diem_cost
      per_diem['Phase ' + this.quote.per_diem_phase].revenue = per_diem_cost * per_diem_markup + per_diem_cost
      this.quote.per_diem_cost_revenue = per_diem
    },
    saveQuote(save_type){
      var self = this

      if(self.quote.quote_title.trim() === ''){
        self.$store.state.notification = 'Please provide a title for this quote before saving.'
        return
      }

      var elmos = {
        phases: {},
        total_revenue: 0,
        total_cost: 0,
        total_hours: 0,
        ou_code: this.quote.job_id_code,
        ou_department: this.quote.department
      }

      for(var i = 1; i < 51; i++){
        elmos.phases['Phase ' + i] = {
          equipment : {revenue: 0, cost: 0},
          labor : {revenue: 0, cost: 0, hours: 0},
          material : {revenue: 0, cost: 0},
          other : {revenue: 0, cost: 0},
          subcontracting : {revenue: 0, cost: 0},
          total_revenue: 0,
          total_cost: 0
        }
      }

      // SUBCONTRACTORS
      for(var i = 0; i < this.quote.subcontractors.length; i++){
        var sub = this.quote.subcontractors[i]
        elmos.phases['Phase ' + sub.phase].subcontracting.revenue += sub.subcontractor_revenue
        elmos.phases['Phase ' + sub.phase].subcontracting.cost += sub.subcontractor_cost
        elmos.phases['Phase ' + sub.phase].total_revenue += sub.subcontractor_revenue
        elmos.phases['Phase ' + sub.phase].total_cost += sub.subcontractor_cost
        elmos.total_revenue += sub.subcontractor_revenue
        elmos.total_cost += sub.subcontractor_cost
      }

      // LABOR
      for(var i = 0; i < this.quote.labor.length; i++){
        var labor = this.quote.labor[i]
        elmos.phases['Phase ' + labor.phase].labor.revenue += labor.coordination_revenue
        elmos.phases['Phase ' + labor.phase].labor.cost += labor.coordination_cost
        elmos.phases['Phase ' + labor.phase].labor.hours += labor.coordination_hours
        elmos.phases['Phase ' + labor.phase].total_revenue += labor.coordination_revenue
        elmos.phases['Phase ' + labor.phase].total_cost += labor.coordination_cost
        elmos.total_revenue += labor.coordination_revenue
        elmos.total_cost += labor.coordination_cost
        elmos.total_hours += labor.coordination_hours
      }

      // OTHER
      if(this.quote.per_diem_required){
        for(var p in this.quote.per_diem_cost_revenue){
          elmos.phases[p].other.revenue += this.quote.per_diem_cost_revenue[p].revenue
          elmos.phases[p].other.cost += this.quote.per_diem_cost_revenue[p].cost
          elmos.phases[p].total_revenue += this.quote.per_diem_cost_revenue[p].revenue
          elmos.phases[p].total_cost += this.quote.per_diem_cost_revenue[p].cost
          elmos.total_revenue += this.quote.per_diem_cost_revenue[p].revenue
          elmos.total_cost += this.quote.per_diem_cost_revenue[p].cost
        }
      }

      this.quote.elmos = elmos
      Firebase.database().ref('opportunities').child(self.opportunityid).child('quote_last_modified').set(Date.now())
      // NEW
      if(self.isNew){
        Firebase.database().ref('quotes').child(self.opportunityid).push(self.quote).then(function(snapshot){
          Firebase.database().ref('totals').child(self.opportunityid).child(snapshot.key).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: true, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet}).then(function(){
            self.$emit('close')
          })
        })
      }

      // EDIT & REPLACE
      if(!self.isNew && save_type === 'replace'){
        this.quote.modified = Date.now()
        this.quote.modified_by = this.current_user

        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).set(self.quote).then(function(){
          Firebase.database().ref('totals').child(self.opportunityid).child(self.quoteid).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: self.quote.show_in_master, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet}).then(function(){
            self.$emit('close')
          })
        })
      }

      // EDIT & REVISE
      if(!self.isNew && save_type === 'revision'){
        var original_quote = JSON.parse(JSON.stringify(self.initial_quote))
        original_quote.archived = Date.now()
        original_quote.archived_by = this.current_user

        this.quote.modified = Date.now()
        this.quote.modified_by = this.current_user

        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).set(self.quote).then(function(){
          Firebase.database().ref('quotes_archive').child(self.opportunityid).child(self.quoteid).push(original_quote).then(function(){
            Firebase.database().ref('totals').child(self.opportunityid).child(self.quoteid).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: self.quote.show_in_master, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet})
          }) // archive old verison
          self.initial_quote = JSON.parse(JSON.stringify(self.quote))
        })
      }

      if(!self.isNew && save_type === 'revert'){
        this.quote.reverted = Date.now()
        this.quote.reverted_by = this.current_user
        this.initial_quote.archived = Date.now()
        this.initial_quote.archived_by = this.current_user

        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).set(self.quote).then(function(){
          Firebase.database().ref('quotes_archive').child(self.opportunityid).child(self.quoteid).child(self.view_version).set(self.initial_quote).then(function(){
            Firebase.database().ref('totals').child(self.opportunityid).child(self.quoteid).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: self.quote.show_in_master, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet}).then(function(){
              self.$emit('close')
            })
          })
        })
      }

    }
  }
}
</script>

<style lang="scss">

  .section{
    text-align: left;
    margin: 10px;
  }

  .section-header{
    text-align: left;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .section-subheader{
    text-align: left;
    font-weight: bold;
    padding-top: 20px;
    font-size: 10px;
  }

  #summary-section{
    margin: 10px;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 20px;
    position: relative;
    background: #eeeeee;
    text-align: left;
    width: 850px;
  }

  textarea{
    height: 25px;
  }

  hr{
    margin-top: 20px;
  }

  thead td {
    line-height: 100% !important;
    text-align: center;
    font-size: 12px;
  }

  tr:hover {
    background-color: transparent !important;
  }
</style>


