<template>
  <v-dialog scrollable persistent v-model="dialog" width="100%">
    <v-form ref="form" lazy-validation>
      <v-card style="background:#eeeeee" v-if="form_mounted">
        <div class="form-header">Pump Quote Sheet</div>
        <v-card-text>
          <!-- PREVIOUS VERSIONS -->
          <v-card style="margin-top:20px" v-if="quote_archive && !isNew">
            <v-card-text style="text-align:left">
              <div class="section-header">PREVIOUS VERSIONS</div>

                <v-simple-table dense style="width:600px;">
                  <tbody>
                    <tr v-for="(archived_quote) in sorted_archive">
                      <td style="width:75px" v-if="view_version !== archived_quote.key"><v-btn color="primary" @click="viewPreviousVersion(archived_quote.key)" x-small>VIEW</v-btn></td>
                      <td style="width:75px;text-align:center" v-if="view_version === archived_quote.key">viewing</td>
                      <td>{{archived_quote.quote_title}} (created on {{formatDate(archived_quote.created)}})</td>
                    </tr>
                  </tbody>
                </v-simple-table>
            </v-card-text>
          </v-card>

          <!-- GENERAL -->
          <v-card class="section">
            <v-card-text style="text-align:left">
              <div class="section-header">GENERAL</div>
              <v-row>
                <v-col cols="12" sm="5">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Quote Title*" outlined v-model="quote.quote_title"></v-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-select dense hide-details="auto" :items="ou_departments" item-text="department" outlined label="Department*" v-model="quote.department">
                    <template slot="selection" slot-scope="data">{{ data.item.department }} ({{ data.item.code }})</template>
                    <template slot="item" slot-scope="data">{{ data.item.department }} ({{ data.item.code }})</template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Duration" class="number-field" min="0" v-model.number="quote.lead_time_shifts"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-textarea dense hide-details="auto" label="Scope of Work" outlined v-model="quote.scope_of_work" auto-grow></v-textarea>
                </v-col>
                <v-col cols="6">
                  <v-textarea dense hide-details="auto" label="Clarifications" outlined v-model="quote.clarifications" auto-grow></v-textarea>
                </v-col>
              </v-row>

              <hr>

              <v-row dense>
                <v-col cols="6">
                  <v-checkbox dense label="Use Master Phase" hide-details v-model="quote.use_master_phase"></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense v-if="quote.use_master_phase">
                <v-col cols="2">
                  <v-select dense hide-details="auto" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Master Phase" v-model="quote.master_phase"></v-select></v-col>
                </v-col>
              </v-row>

              <hr>

              <v-row>
                <v-col cols="3">
                  <v-checkbox dense label="Quote Completed" hide-detail v-model="quote.completed" style="margin:0px 0px 0px 10px"></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-checkbox dense label="Quote Reviewed" hide-detail v-model="quote.reviewed" style="margin:0px 0px 0px 10px"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- LABOR -->
          <v-card class="section" v-if="!quote.auto_st_ot_markup">
            <v-card-text>
              <div class="section-header">LABOR</div>
              <draggable v-model="quote.tasks" handle=".handle">
                <transition-group>
                  <div v-for="(task, index) in quote.tasks" :key="index" style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:20px;position:relative;background:white">
                    <v-icon large class="handle">mdi-reorder-horizontal</v-icon>
                    <v-btn color="primary" @click="deleteTask(index)" x-small fab style="position:absolute;top:5px;right:5px;width:20px;height:20px;"><v-icon x-small>mdi-close</v-icon></v-btn>
                    <v-row style='margin:0px'>
                      <v-col cols="6"><v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Task Description" outlined v-model="quote.tasks[index].description" style=""></v-text-field></v-col>
                      <v-col cols="2"><v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.tasks[index].phase" @change="setLabor()"></v-select></v-col>
                      <v-col cols="2" style="text-align:right"><div>Total Cost</div><div>{{formatCurrency(task.total_cost)}}</div></v-col>
                      <v-col cols="2" style="text-align:right"><div>Total Revenue</div><div>{{formatCurrency(task.total_revenue)}}</div></v-col>
                    </v-row>

                    <v-simple-table v-if="task.show_tasks_detail">
                      <tbody>
                        <tr style="font-weight:bold">
                          <td>Labor Classification</td>
                          <td style="width:115px">ST Hours</td>
                          <td style="width:115px">OT Hours</td>
                          <td style="width:115px">Total Hours</td>
                          <td>ST Cost</td>
                          <td>ST Revenue</td>
                          <td>OT Cost</td>
                          <td>OT Revenue</td>
                          <td>Total Cost</td>
                          <td>Total Revenue</td>
                        </tr>
                        <tr v-for="lc in task.labor">
                          <td>{{lc.pr_class}} {{lc.classification}}</td>
                          <td><v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" class="number-field" min="0" v-model.number="quote.tasks[index].labor[lc.pr_class].st_hours" @change="setLabor()"></v-text-field></td>
                          <td><v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" class="number-field" min="0" v-model.number="quote.tasks[index].labor[lc.pr_class].ot_hours" @change="setLabor()"></v-text-field></td>
                          <td>{{quote.tasks[index].labor[lc.pr_class].total_hours}}</td>
                          <td>
                            <div>{{formatCurrency(lc.st_cost)}}</div>
                            <div style="font-size:10px;font-style:italic">Rate: {{formatCurrency(quote.rates.labor_rates[lc.pr_class].st_cost_rate)}}</div>
                          </td>
                          <td>
                            <div>{{formatCurrency(lc.st_revenue)}}</div>
                            <div style="font-size:10px;font-style:italic">Rate: {{formatCurrency(quote.rates.labor_rates[lc.pr_class].st_revenue_rate)}}</div>
                          </td>
                          <td>
                            <div>{{formatCurrency(lc.ot_cost)}}</div>
                            <div style="font-size:10px;font-style:italic">Rate: {{formatCurrency(quote.rates.labor_rates[lc.pr_class].ot_cost_rate)}}</div>
                          </td>
                          <td>
                            <div>{{formatCurrency(lc.ot_revenue)}}</div>
                            <div style="font-size:10px;font-style:italic">Rate:{{formatCurrency(quote.rates.labor_rates[lc.pr_class].ot_revenue_rate)}}</div>
                          </td>
                          <td>{{formatCurrency(lc.cost)}}</td>
                          <td>{{formatCurrency(lc.revenue)}}</td>
                        </tr>
                        <tr>
                          <td style="font-weight:bold;border-top:2px solid">TOTAL</td>
                          <td style="font-weight:bold;border-top:2px solid">{{task.total_st_hours}}</td>
                          <td style="font-weight:bold;border-top:2px solid">{{task.total_ot_hours}}</td>
                          <td style="font-weight:bold;border-top:2px solid">{{task.total_hours}}</td>
                          <td style="font-weight:bold;border-top:2px solid"></td>
                          <td style="font-weight:bold;border-top:2px solid"></td>
                          <td style="font-weight:bold;border-top:2px solid"></td>
                          <td style="font-weight:bold;border-top:2px solid"></td>
                          <td style="font-weight:bold;border-top:2px solid">{{formatCurrency(task.total_cost)}}</td>
                          <td style="font-weight:bold;border-top:2px solid">{{formatCurrency(task.total_revenue)}}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <v-btn color="white" @click="task.show_tasks_detail = !task.show_tasks_detail" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-{{!task.show_tasks_detail ? 'plus' : 'minus'}}</v-icon>{{!task.show_tasks_detail ? 'SHOW' : 'HIDE'}} TASK DETAIL</v-btn>
                  </div>
                </transition-group>
              </draggable>

              <div v-if="quote.tasks.length > 1 && !showingTaskDetail" style="margin-bottom:25px"><v-btn color="white" @click="showAllTaskDetail()" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-plus</v-icon>SHOW ALL TASK DETAIL</v-btn></div>
              <div v-if="quote.tasks.length > 1 && showingTaskDetail" style="margin-bottom:25px"><v-btn color="white" @click="hideAllTaskDetail()" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-minus</v-icon>HIDE ALL TASK DETAIL</v-btn></div>
              <v-btn color="primary" @click="addTask()" small>ADD TASK</v-btn>
            </v-card-text>
          </v-card>

          <v-card class="section" v-if="quote.auto_st_ot_markup">
            <v-card-text>
              <div class="section-header">LABOR</div>
              <draggable v-model="quote.tasks" handle=".handle">
                <transition-group>
                  <div v-for="(task, index) in quote.tasks" :key="index" style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:20px;position:relative;background:white">
                    <v-icon large class="handle">mdi-reorder-horizontal</v-icon>
                    <v-btn color="primary" @click="deleteTask(index)" x-small fab style="position:absolute;top:5px;right:5px;width:20px;height:20px;"><v-icon x-small>mdi-close</v-icon></v-btn>
                    <v-row style='margin:0px'>

                      <v-col cols="10"><v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Task Description" outlined v-model="quote.tasks[index].description" style=""></v-text-field></v-col>
                      <v-col cols="2"><v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.tasks[index].phase" @change="setLabor()"></v-select></v-col>

                      <v-col cols="2"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Multiplier" outlined min="1" v-model.number="quote.tasks[index].multiplier" @change="setLabor()"></v-text-field></v-col>
                      <v-col cols="3"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Straight Time Markup %" outlined min="0" v-model.number="quote.tasks[index].st_markup_percentage" suffix="%" disabled></v-text-field></v-col>
                      <v-col cols="3"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Over-Time Markup %" outlined min="0" v-model.number="quote.tasks[index].ot_markup_percentage" oninput="if(Number(this.value) > 100) this.value = 100" @change="setStandardMarkup(index)" suffix="%"></v-text-field></v-col>

                      <v-col cols="2" style="text-align:right"><div>Total Cost</div><div>{{formatCurrency(task.total_cost)}}</div></v-col>
                      <v-col cols="2" style="text-align:right"><div>Total Revenue</div><div>{{formatCurrency(task.total_revenue)}}</div></v-col>
                    </v-row>

                    <v-simple-table v-if="task.show_tasks_detail">
                      <tbody>
                        <tr style="font-weight:bold">
                          <td>Labor Classification</td>
                          <td style="width:115px">Total Hours</td>
                          <td style="width:115px">Total Hours</br>w/ Multiplier</td>
                          <td style="width:115px">ST Hours</td>
                          <td style="width:115px">OT Hours</td>
                          <td>ST Cost</td>
                          <td>ST Revenue</td>
                          <td>OT Cost</td>
                          <td>OT Revenue</td>
                          <td>Total Cost</td>
                          <td>Total Revenue</td>
                        </tr>
                        <tr v-for="lc in task.labor">
                          <td>{{lc.pr_class}} {{lc.classification}}</td>

                          <td><v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" class="number-field" min="0" v-model.number="quote.tasks[index].labor[lc.pr_class].total_hours" @change="setLabor()"></v-text-field></td>

                          <td>{{quote.tasks[index].multiplier ? (quote.tasks[index].labor[lc.pr_class].total_hours * quote.tasks[index].multiplier) : quote.tasks[index].labor[lc.pr_class].total_hours}}</td>
                          <td>{{quote.tasks[index].labor[lc.pr_class].st_hours}}</td>
                          <td>{{quote.tasks[index].labor[lc.pr_class].ot_hours}}</td>

                          <td>
                            <div>{{formatCurrency(lc.st_cost)}}</div>
                            <div style="font-size:10px;font-style:italic">Rate: {{formatCurrency(quote.rates.labor_rates[lc.pr_class].st_cost_rate)}}</div>
                          </td>
                          <td>
                            <div>{{formatCurrency(lc.st_revenue)}}</div>
                            <div style="font-size:10px;font-style:italic">Rate: {{formatCurrency(quote.rates.labor_rates[lc.pr_class].st_revenue_rate)}}</div>
                          </td>
                          <td>
                            <div>{{formatCurrency(lc.ot_cost)}}</div>
                            <div style="font-size:10px;font-style:italic">Rate: {{formatCurrency(quote.rates.labor_rates[lc.pr_class].ot_cost_rate)}}</div>
                          </td>
                          <td>
                            <div>{{formatCurrency(lc.ot_revenue)}}</div>
                            <div style="font-size:10px;font-style:italic">Rate:{{formatCurrency(quote.rates.labor_rates[lc.pr_class].ot_revenue_rate)}}</div>
                          </td>
                          <td>{{formatCurrency(lc.cost)}}</td>
                          <td>{{formatCurrency(lc.revenue)}}</td>
                        </tr>
                        <tr>
                          <td style="font-weight:bold;border-top:2px solid">TOTAL</td>
                          <td style="font-weight:bold;border-top:2px solid">{{task.total_hours}}</td>
                          <td style="font-weight:bold;border-top:2px solid">{{task.total_hours_multiplied ? task.total_hours_multiplied : task.total_hours}}</td>
                          <td style="font-weight:bold;border-top:2px solid">{{task.total_st_hours}}</td>
                          <td style="font-weight:bold;border-top:2px solid">{{task.total_ot_hours}}</td>
                          <td style="font-weight:bold;border-top:2px solid"></td>
                          <td style="font-weight:bold;border-top:2px solid"></td>
                          <td style="font-weight:bold;border-top:2px solid"></td>
                          <td style="font-weight:bold;border-top:2px solid"></td>
                          <td style="font-weight:bold;border-top:2px solid">{{formatCurrency(task.total_cost)}}</td>
                          <td style="font-weight:bold;border-top:2px solid">{{formatCurrency(task.total_revenue)}}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <v-btn color="white" @click="task.show_tasks_detail = !task.show_tasks_detail" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-{{!task.show_tasks_detail ? 'plus' : 'minus'}}</v-icon>{{!task.show_tasks_detail ? 'SHOW' : 'HIDE'}} TASK DETAIL</v-btn>
                  </div>
                </transition-group>
              </draggable>

              <div v-if="quote.tasks.length > 1 && !showingTaskDetail" style="margin-bottom:25px"><v-btn color="white" @click="showAllTaskDetail()" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-plus</v-icon>SHOW ALL TASK DETAIL</v-btn></div>
              <div v-if="quote.tasks.length > 1 && showingTaskDetail" style="margin-bottom:25px"><v-btn color="white" @click="hideAllTaskDetail()" x-small style="width:215px;margin-bottom:10px;color:#223773"><v-icon small class="mr-1">mdi-minus</v-icon>HIDE ALL TASK DETAIL</v-btn></div>
              <v-btn color="primary" @click="addTask()" small>ADD TASK</v-btn>
            </v-card-text>
          </v-card>

          <!-- MATERIAL -->
          <v-card class="section">
            <v-card-text>
              <div class="section-header">MATERIAL</div>
              <div v-for="(material, index) in quote.material" style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:30px 20px;position:relative">
                <v-btn color="primary" @click="deleteMaterial(index)" x-small fab style="position:absolute;top:5px;right:5px;width:20px;height:20px;"><v-icon x-small>mdi-close</v-icon></v-btn>
                <v-row dense>
                  <template>
                    <v-col cols="6">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Description" outlined v-model="quote.material[index].description"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Vendor" outlined v-model="quote.material[index].vendor"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Part Number" outlined v-model="quote.material[index].part_number"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Expected" outlined v-model="quote.material[index].expected"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.material[index].phase"></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Quantity" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.material[index].quantity" @change="setMaterial()"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Cost/EA" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.material[index].item_cost" @change="setMaterial()" prefix="$"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Markup %" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.material[index].markup_percentage" @change="setMaterial()" suffix="%">></v-text-field>
                    </v-col>
                    <v-col cols="2" style="text-align:right">
                      <div style="line-height:100%">Total Cost</div>
                      {{formatCurrency(quote.material[index].total_cost)}}
                    </v-col>
                    <v-col cols="2" style="text-align:right">
                      <div style="line-height:100%">Total Revenue</div>
                      {{formatCurrency(quote.material[index].total_revenue)}}
                    </v-col>
                  </template>
                </v-row>
              </div>
              <v-btn color="primary" @click="addMaterial()" small>ADD MATERIAL</v-btn>
            </v-card-text>
          </v-card>

          <!-- SUBCONTRACTORS -->
          <v-card class="section">
            <v-card-text>
              <div class="section-header">SUBCONTRACTORS</div>
              <div v-for="(subcontractor, index) in quote.subcontractors" style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:30px 20px;position:relative">
                <v-btn color="primary" @click="deleteSubcontractor(index)" x-small fab style="position:absolute;top:5px;right:5px;width:20px;height:20px;"><v-icon x-small>mdi-close</v-icon></v-btn>
                <v-row dense>
                  <template>
                    <v-col cols="9">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Description" outlined v-model="quote.subcontractors[index].description"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.subcontractors[index].phase"></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Markup %" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.subcontractors[index].markup_percentage" @change="setSubcontractors()" suffix="%">></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Total Cost" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.subcontractors[index].total_cost" @change="setSubcontractors()" prefix="$"></v-text-field>
                    </v-col>
                    <v-col cols="2" style="text-align:right">
                      <div style="line-height:100%">Total Cost</div>
                      {{formatCurrency(quote.subcontractors[index].total_cost)}}
                    </v-col>
                    <v-col cols="2" style="text-align:right">
                      <div style="line-height:100%">Total Revenue</div>
                      {{formatCurrency(quote.subcontractors[index].total_revenue)}}
                    </v-col>
                  </template>
                </v-row>
              </div>
              <v-btn color="primary" @click="addSubcontractor()" small>ADD SUBCONTRACTOR</v-btn>
            </v-card-text>
          </v-card>
          <div id="summary-section">
            <h4>Account: {{db.accounts[quote.account].name}}</h4>
            <h4>Opportunity: {{opportunity.name}}</h4>
            <h4>Quote: {{quote.quote_title}}</h4>
            <h4>Department: {{quote.department}}</h4>
            <h4>Duration: {{quote.lead_time_shifts}}</h4>

            <div class="section-subheader">LABOR SUMMARY</div>
            <div class="report-page" v-for="(task,index) in quote.tasks" style="border:1px solid #223773;padding:10px;margin:10px 0px;background:white">
              <div>Task {{task.description}}</div>
              <div>Phase {{task.phase}}</div>
              <v-simple-table dense style="margin-top:5px">
                <thead style="font-weight:bold;">
                  <td style="text-align:left;padding-left:15px">Labor Classification</td>
                  <td>ST Hours</td>
                  <td>OT Hours</td>
                  <td>Total Hours</td>
                  <td>TOTAL COST</td>
                  <td>TOTAL REVENUE</td>
                </thead>
                <tbody>
                  <tr v-for="lc in task.labor">
                    <td style="text-align:left">{{lc.pr_class}} {{lc.classification}}</td>
                    <td style="text-align:center">{{lc.st_hours}}</td>
                    <td style="text-align:center">{{lc.ot_hours}}</td>
                    <td style="text-align:center">{{lc.total_hours}}</td>
                    <td style="text-align:center">{{formatCurrency(lc.cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(lc.revenue)}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{task.total_st_hours}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{task.total_ot_hours}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{task.total_hours}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(task.total_cost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(task.total_revenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="report-page" style="border:4px solid #223773;font-weight:bold;padding:10px;margin:10px 0px;background:white">
              <div>Consolidated Tasks</div>
              <v-simple-table dense style="margin-top:5px">
                <thead style="font-weight:bold">
                  <td style="text-align:left;padding-left:15px">Labor Classification</td>
                  <td>ST Hours</td>
                  <td>OT Hours</td>
                  <td>Total Hours</td>
                  <td>Total Cost</td>
                  <td>Total Revenue</td>
                </thead>
                <tbody style="font-weight:normal">
                <tr v-for="(lc,x) in quote.consolidated_tasks.labor">
                  <td>{{x}} {{lc.classification}}</td>
                  <td style="text-align:center">{{lc.st_hours}}</td>
                  <td style="text-align:center">{{lc.ot_hours}}</td>
                  <td style="text-align:center">{{lc.total_hours}}</td>
                  <td style="text-align:center">{{formatCurrency(lc.total_cost)}}</td>
                  <td style="text-align:center">{{formatCurrency(lc.total_revenue)}}</td>
                </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.consolidated_tasks.total_st_hours}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.consolidated_tasks.total_ot_hours}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{quote.consolidated_tasks.total_hours}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.consolidated_tasks.total_cost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.consolidated_tasks.total_revenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="section-subheader">MATERIAL SUMMARY</div>
            <div class="report-page" style="border:4px solid #223773;padding:10px;margin:10px 0px;background:white">
              <v-simple-table dense>
                <thead style="font-weight:bold">
                  <td style="text-align:left;padding-left:15px">Description</td>
                  <td style="text-align:left;">Part Number</td>
                  <td>Phase</td>
                  <td>Quantity</td>
                  <td>Cost/EA</td>
                  <td>Total Cost</td>
                  <td>Total Revenue</td>
                </thead>
                <tbody>
                  <tr v-for="(material,index) in quote.material">
                    <td>{{material.description}}</br>Vendor: {{material.vendor}}</td>
                    <td style="padding-left:0px;text-align:left">{{material.part_number}}</td>
                    <td style="text-align:center">{{material.phase}}</td>
                    <td style="text-align:center">{{material.quantity}}</td>
                    <td style="text-align:center">{{formatCurrency(material.item_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(material.total_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(material.total_revenue)}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center"></td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center"></td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center"></td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center"></td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.consolidated_material.total_cost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.consolidated_material.total_revenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="section-subheader">SUBCONTRACTOR SUMMARY</div>
            <div class="report-page" style="border:4px solid #223773;font-weight:bold;padding:10px;margin:10px 0px;background:white">
              <v-simple-table dense>
                <thead style="font-weight:bold">
                  <td style="text-align:left;padding-left:15px">Description</td>
                  <td>Phase</td>
                  <td>Total Cost</td>
                  <td>Total Revenue</td>
                </thead>
                <tbody>
                  <tr v-for="(subcontractor,index) in quote.subcontractors" style="font-weight:normal">
                    <td>{{subcontractor.description}}</td>
                    <td style="text-align:center">{{subcontractor.phase}}</td>
                    <td style="text-align:center">{{formatCurrency(subcontractor.total_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(subcontractor.total_revenue)}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center"></td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.consolidated_subcontractors.total_cost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(quote.consolidated_subcontractors.total_revenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="printJobReport()" small>PRINT JOB REPORT</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeForm()" small>CLOSE</v-btn>
          <v-btn color="primary" @click="saveQuote('replace')" small v-if="!view_version" :disabled="!unsavedChanges">SAVE</v-btn>
          <v-btn color="primary" @click="saveQuote('revision')" small v-if="!isNew && !view_version" :disabled="!unsavedChanges">SAVE AS REVISION</v-btn>
          <v-btn color="primary" @click="saveQuote('revert')" small v-if="view_version" :disabled="!unsavedChanges">REVERT BACK TO THIS VERSION</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <EditQuoteRate v-if='edit_quote_rate' @close='edit_quote_rate = false' @saveQuoteRate='saveQuoteRate' :initialrate='edit_quote_rate_initial_val'/>
  </v-dialog>
</template>

<script>
import Firebase from 'firebase/app';
import { mapGetters } from 'vuex';
import Moment from 'moment';
import { formatCurrency } from '../../mixins/formatCurrency.js'
import EditQuoteRate from '../EditQuoteRate.vue'
import { jsPDF } from "jspdf";
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas'
import draggable from 'vuedraggable'

export default {
  name: 'Pump',
  components: {draggable,EditQuoteRate},
  mixins: [formatCurrency],
  props: {
    opportunityid: String,
    quoteid: [String, Boolean],
  },
  data() {
    return {
      dialog: true,
      edit_quote_rate: false,
      edit_quote_rate_path_arr: [],
      edit_quote_rate_initial_val: null,
      quote: {
        quote_sheet: 'Pump',
        department: 'MSM CF Pump',
        job_id_code: '002',
        quote_type: 'New',
        quote_title: '',
        account: '',
        opportunity: '',
        created_by: '',
        created: '',
        scope_of_work: '',
        clarifications: '',
        tasks: [],
        material: [],
        subcontractors: [],
        consolidated_tasks: {},
        consolidated_material: {},
        consolidated_subcontractors: {},
        lead_time_shifts: 1,
        completed: false,
        reviewed: false,
        show_in_master: true,
        auto_st_ot_markup: true,
        use_master_phase: false,
        master_phase: 1,        
      },
      initial_quote: {},
      quote_archive: {},
      isNew: true,
      view_version: false,
      opportunity: {},
      form_mounted: false
    };
  },
  beforeMount() {

    var self = this

    Firebase.database().ref('opportunities').child(self.opportunityid).once('value',function(snapshot){
      self.opportunity = JSON.parse(JSON.stringify(snapshot.val()))
      self.isNew = self.quoteid ? false : true

      if(self.isNew){ // new quote
        self.quote.created_by = self.current_user
        self.quote.account = self.opportunity.account
        self.quote.opportunity = self.opportunityid
        self.quote.created = Date.now()
        self.quote.rates = JSON.parse(JSON.stringify(self.db.pump_rates))
        self.initial_quote = JSON.parse(JSON.stringify(self.quote))
        self.form_mounted = true
      }

      if(!self.isNew){ // edit quote
        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).once('value',function(quote_snapshot){
          self.quote = JSON.parse(JSON.stringify(quote_snapshot.val()))
          self.setEmptyQuoteData()
        }).then(function(){
          Firebase.database().ref('quotes_archive').child(self.opportunityid).child(self.quoteid).once('value',function(quotes_archive_snapshot){
            self.quote_archive = quotes_archive_snapshot.val()
            self.initial_quote = JSON.parse(JSON.stringify(self.quote))
            self.form_mounted = true
          })
        })
      }
    })
  },
  watch: {
    'quote.use_master_phase': function(val){
      if(!this.quote.master_phase){
        this.quote.master_phase = 1
      }
      this.setLabor()
      this.setMaterial()
      this.setSubcontractors()
    },
    'quote.master_phase': function(val){
      this.setLabor()
      this.setMaterial()
      this.setSubcontractors()
    },    
    'quote.department': function(val){
      this.quote.job_id_code = _.find(this.ou_departments, {department: val}).code
    },
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      active_accounts: 'active_accounts',
      active_users: 'active_users',
      ou_departments: 'ou_departments',
    }),
    sorted_archive(){
      var arr = []
      var archive = this.quote_archive
      for(var a in archive){
        archive[a].key = a
        arr.push(archive[a])
      }
      return _.orderBy(arr, ['archived'],['asc']);
    },
    unsavedChanges() {
      return JSON.stringify(this.initial_quote) !== JSON.stringify(this.quote);
    },
    showingTaskDetail(){
      var has_task_showing = false
      for(var i = 0; i < this.quote.tasks.length; i++){
        if(this.quote.tasks[i].show_tasks_detail === true){
          has_task_showing = true
        }
      }
      return has_task_showing
    }
  },
  methods: {
    closeForm(){
      if(this.unsavedChanges){
        var confirm_close = confirm('You have unsaved changes. Are you sure you want to close this form? Any unsaved changes will be lost.')
        if(confirm_close){
          this.$emit('close')
        }
      }else{
        this.$emit('close')
      }
    },
    setStandardMarkup(index){
      this.quote.tasks[index].ot_markup_percentage = this.quote.tasks[index].ot_markup_percentage ? this.quote.tasks[index].ot_markup_percentage : 0
      this.quote.tasks[index].st_markup_percentage = 100 - this.quote.tasks[index].ot_markup_percentage
      this.setLabor()
    },
    editQuoteRate(rate_path_arr){
      this.edit_quote_rate_path_arr = rate_path_arr
      if(rate_path_arr.length === 1){
        this.edit_quote_rate_initial_val = this.quote.rates[rate_path_arr[0]]
      }
      if(rate_path_arr.length === 2){
        this.edit_quote_rate_initial_val = this.quote.rates[rate_path_arr[0]][rate_path_arr[1]]
      }
      if(rate_path_arr.length === 3){
        this.edit_quote_rate_initial_val = this.quote.rates[rate_path_arr[0]][rate_path_arr[1]][rate_path_arr[2]]
      }
      this.edit_quote_rate = true
    },
    setEmptyQuoteData() {
      if (!this.quote.tasks) {
        this.$set(this.quote, 'tasks', []);
      }
      if (!this.quote.material) {
        this.$set(this.quote, 'material', []);
      }
      if (!this.quote.subcontractors) {
        this.$set(this.quote, 'subcontractors', []);
      }
      if (!this.quote.consolidated_tasks) {
        this.$set(this.quote, 'consolidated_tasks', {});
      }
      if (!this.quote.consolidated_material) {
        this.$set(this.quote, 'consolidated_material', {});
      }
      if (!this.quote.consolidated_subcontractors) {
        this.$set(this.quote, 'consolidated_subcontractors', {});
      }
    },
    viewPreviousVersion(id) {
      var previous_quote = this.quote_archive[id]
      this.quote = previous_quote
      this.view_version = id
      this.setEmptyQuoteData()
    },
    saveQuoteRate(val){
      if(this.edit_quote_rate_path_arr.length === 1){
        this.quote.rates[this.edit_quote_rate_path_arr[0]] = val
      }
      if(this.edit_quote_rate_path_arr.length === 2){
        this.quote.rates[this.edit_quote_rate_path_arr[0]][this.edit_quote_rate_path_arr[1]] = val
      }
      if(this.edit_quote_rate_path_arr.length === 3){
        this.quote.rates[this.edit_quote_rate_path_arr[0]][this.edit_quote_rate_path_arr[1]][this.edit_quote_rate_path_arr[2]] = val
      }
      if(this.edit_quote_rate_path_arr.includes('labor_rates')){
        this.setLabor()
      }
      this.edit_quote_rate_path_arr = []
      this.edit_quote_rate = false
      this.edit_quote_rate_initial_val = null
    },
    formatDate(date) {
      return Moment(date).format('MM/DD/YYYY');
    },
    addTask(){
      var task_obj = {
                      description: '',
                      phase: this.quote.use_master_phase ? this.quote.master_phase : 1,
                      total_st_revenue: 0,
                      total_st_cost: 0,
                      total_ot_revenue: 0,
                      total_ot_cost: 0,
                      total_revenue: 0,
                      total_st_hours: 0,
                      total_ot_hours: 0,
                      total_hours: 0,
                      total_cost: 0,
                      show_tasks_detail: false,
                      labor: {
                        '0401': {classification: 'MECHANICAL - PROJ MGT', pr_class: '0401', st_hours: 0, ot_hours: 0, total_hours: 0, st_revenue: 0, ot_revenue: 0, st_cost: 0, ot_cost: 0, revenue: 0, cost: 0},
                        '0402': {classification: 'MECHANICAL - PUMP REPAIR', pr_class: '0402', st_hours: 0, ot_hours: 0, total_hours: 0, st_revenue: 0, ot_revenue: 0, st_cost: 0, ot_cost: 0, revenue: 0, cost: 0},
                        '0403': {classification: 'MECHANICAL - PRESS WORK', pr_class: '0403', st_hours: 0, ot_hours: 0, total_hours: 0, st_revenue: 0, ot_revenue: 0, st_cost: 0, ot_cost: 0, revenue: 0, cost: 0},
                        '0405': {classification: 'MECHANICAL - BALANCING', pr_class: '0405', st_hours: 0, ot_hours: 0, total_hours: 0, st_revenue: 0, ot_revenue: 0, st_cost: 0, ot_cost: 0, revenue: 0, cost: 0},
                        '0408': {classification: 'MECHANICAL - HANDLING', pr_class: '0408', st_hours: 0, ot_hours: 0, total_hours: 0, st_revenue: 0, ot_revenue: 0, st_cost: 0, ot_cost: 0, revenue: 0, cost: 0},
                        '0410': {classification: 'MECHANICAL - JOB CLEAN UP', pr_class: '0410', st_hours: 0, ot_hours: 0, total_hours: 0, st_revenue: 0, ot_revenue: 0, st_cost: 0, ot_cost: 0, revenue: 0, cost: 0},
                        '0449': {classification: 'MECHANICAL - INSPECTION', pr_class: '0449', st_hours: 0, ot_hours: 0, total_hours: 0, st_revenue: 0, ot_revenue: 0, st_cost: 0, ot_cost: 0, revenue: 0, cost: 0},
                        '0450': {classification: 'MECHANICAL - MAT/JOB PREP', pr_class: '0450', st_hours: 0, ot_hours: 0, total_hours: 0, st_revenue: 0, ot_revenue: 0, st_cost: 0, ot_cost: 0, revenue: 0, cost: 0},
                        '0451': {classification: 'MECHANICAL - GENERAL REPAIR HM/GB', pr_class: '0451', st_hours: 0, ot_hours: 0, total_hours: 0, st_revenue: 0, ot_revenue: 0, st_cost: 0, ot_cost: 0, revenue: 0, cost: 0},
                        '0453': {classification: 'MECHANICAL - PAINT/SANDBLAST', pr_class: '0453', st_hours: 0, ot_hours: 0, total_hours: 0, st_revenue: 0, ot_revenue: 0, st_cost: 0, ot_cost: 0, revenue: 0, cost: 0},
                      }
                     }

      if(this.quote.auto_st_ot_markup){
        task_obj.st_markup_percentage = 80,
        task_obj.ot_markup_percentage = 20,
        task_obj.multiplier = 1
      }

      this.quote.tasks = this.quote.tasks ? this.quote.tasks : []
      this.quote.tasks.push(task_obj)
    },
    deleteTask(index){
      var confirm_delete = confirm('Are you sure you want to delete this task?')
      if(confirm_delete){
        this.quote.tasks.splice(index,1)
        this.setLabor()
      }
    },
    addMaterial(){
      var material_obj = {
        description: '',
        vendor: '',
        expected: '',
        part_number: '',
        quantity: 0,
        item_cost: 0,
        total_cost: 0,
        total_revenue: 0,
        markup_percentage: 18,
        phase: this.quote.use_master_phase ? this.quote.master_phase : 1
      }
      this.quote.material = this.quote.material ? this.quote.material : []
      this.quote.material.push(material_obj)
    },
    deleteMaterial(index){
      var confirm_delete = confirm('Are you sure you want to delete this material?')
      if(confirm_delete){
        this.quote.material.splice(index,1)
        this.setMaterial()
      }
    },
    addSubcontractor(){
      var subcontractor_obj = {
        description: '',
        phase: this.quote.use_master_phase ? this.quote.master_phase : 1,
        markup_percentage: 18,
        total_cost: 0,
        total_revenue: 0
      }
      this.quote.subcontractors = this.quote.subcontractors ? this.quote.subcontractors : []
      this.quote.subcontractors.push(subcontractor_obj)
    },
    deleteSubcontractor(index){
      var confirm_delete = confirm('Are you sure you want to delete this subcontractor?')
      if(confirm_delete){
        this.quote.subcontractors.splice(index,1)
        this.setSubcontractors()
      }
    },
    showAllTaskDetail(){
      for(var i = 0; i < this.quote.tasks.length; i++){
        this.quote.tasks[i].show_tasks_detail = true
      }
    },
    hideAllTaskDetail(){
      for(var i = 0; i < this.quote.tasks.length; i++){
        this.quote.tasks[i].show_tasks_detail = false
      }
    },
    setLabor(){
      this.quote.consolidated_tasks = {
        labor: {
          '0401': {classification: 'MECHANICAL - PROJ MGT', st_hours: 0, ot_hours: 0, total_hours: 0, total_cost: 0, total_revenue: 0},
          '0402': {classification: 'MECHANICAL - PUMP REPAIR', st_hours: 0, ot_hours: 0, total_hours: 0, total_cost: 0, total_revenue: 0},
          '0403': {classification: 'MECHANICAL - PRESS WORK', st_hours: 0, ot_hours: 0, total_hours: 0, total_cost: 0, total_revenue: 0},
          '0405': {classification: 'MECHANICAL - BALANCING', st_hours: 0, ot_hours: 0, total_hours: 0, total_cost: 0, total_revenue: 0},
          '0408': {classification: 'MECHANICAL - HANDLING', st_hours: 0, ot_hours: 0, total_hours: 0, total_cost: 0, total_revenue: 0},
          '0410': {classification: 'MECHANICAL - JOB CLEAN UP', st_hours: 0, ot_hours: 0, total_hours: 0, total_cost: 0, total_revenue: 0},
          '0449': {classification: 'MECHANICAL - INSPECTION', st_hours: 0, ot_hours: 0, total_hours: 0, total_cost: 0, total_revenue: 0},
          '0450': {classification: 'MECHANICAL - MAT/JOB PREP', st_hours: 0, ot_hours: 0, total_hours: 0, total_cost: 0, total_revenue: 0},
          '0451': {classification: 'MECHANICAL - GENERAL REPAIR HM/GB', st_hours: 0, ot_hours: 0, total_hours: 0, total_cost: 0, total_revenue: 0},
          '0453': {classification: 'MECHANICAL - PAINT/SANDBLAST', st_hours: 0, ot_hours: 0, total_hours: 0, total_cost: 0, total_revenue: 0}
        },
        total_st_hours: 0,
        total_ot_hours: 0,
        total_hours: 0,
        total_cost: 0,
        total_revenue: 0
      }

      for(var i = 0; i < this.quote.tasks.length; i++){
        var task_total_st_revenue = 0
        var task_total_ot_revenue = 0
        var task_total_st_cost = 0
        var task_total_ot_cost = 0
        var task_total_revenue = 0
        var task_total_cost = 0
        var task_total_st_hours = 0
        var task_total_ot_hours = 0
        var task_total_hours = 0

        if(this.quote.use_master_phase){
          this.quote.tasks[i].phase = this.quote.master_phase
        }

        if(this.quote.auto_st_ot_markup){
          this.quote.tasks[i].multiplier = this.quote.tasks[i].multiplier ? Math.max(this.quote.tasks[i].multiplier, 1) : 1

          for(var lc in this.quote.tasks[i].labor){

            this.quote.tasks[i].labor[lc].total_hours = this.quote.tasks[i].labor[lc].total_hours ? this.quote.tasks[i].labor[lc].total_hours : 0
            this.quote.tasks[i].labor[lc].st_hours = (Math.round(((this.quote.tasks[i].labor[lc].total_hours * (this.quote.tasks[i].st_markup_percentage / 100)) + Number.EPSILON) * 100) / 100) * this.quote.tasks[i].multiplier
            this.quote.tasks[i].labor[lc].ot_hours = (Math.round(((this.quote.tasks[i].labor[lc].total_hours * (this.quote.tasks[i].ot_markup_percentage / 100)) + Number.EPSILON) * 100) / 100) * this.quote.tasks[i].multiplier

            this.quote.tasks[i].labor[lc].st_revenue = this.quote.tasks[i].labor[lc].st_hours * this.quote.rates.labor_rates[lc].st_revenue_rate
            this.quote.tasks[i].labor[lc].ot_revenue = this.quote.tasks[i].labor[lc].ot_hours * this.quote.rates.labor_rates[lc].ot_revenue_rate

            this.quote.tasks[i].labor[lc].st_cost = this.quote.tasks[i].labor[lc].st_hours * this.quote.rates.labor_rates[lc].st_cost_rate
            this.quote.tasks[i].labor[lc].ot_cost = this.quote.tasks[i].labor[lc].ot_hours * this.quote.rates.labor_rates[lc].ot_cost_rate
            this.quote.tasks[i].labor[lc].revenue = this.quote.tasks[i].labor[lc].st_revenue + this.quote.tasks[i].labor[lc].ot_revenue
            this.quote.tasks[i].labor[lc].cost = this.quote.tasks[i].labor[lc].st_cost + this.quote.tasks[i].labor[lc].ot_cost
            task_total_st_revenue += this.quote.tasks[i].labor[lc].st_revenue
            task_total_ot_revenue += this.quote.tasks[i].labor[lc].ot_revenue
            task_total_st_cost += this.quote.tasks[i].labor[lc].st_cost
            task_total_ot_cost += this.quote.tasks[i].labor[lc].ot_cost
            task_total_revenue += this.quote.tasks[i].labor[lc].revenue
            task_total_cost += this.quote.tasks[i].labor[lc].cost
            task_total_hours += this.quote.tasks[i].labor[lc].total_hours

            task_total_st_hours += this.quote.tasks[i].labor[lc].st_hours
            task_total_ot_hours += this.quote.tasks[i].labor[lc].ot_hours
            this.quote.consolidated_tasks.labor[lc].st_hours += this.quote.tasks[i].labor[lc].st_hours
            this.quote.consolidated_tasks.labor[lc].ot_hours += this.quote.tasks[i].labor[lc].ot_hours
            this.quote.consolidated_tasks.labor[lc].total_hours += this.quote.tasks[i].labor[lc].st_hours + this.quote.tasks[i].labor[lc].ot_hours
            this.quote.consolidated_tasks.labor[lc].total_cost += this.quote.tasks[i].labor[lc].cost
            this.quote.consolidated_tasks.labor[lc].total_revenue += this.quote.tasks[i].labor[lc].revenue
          }

          this.quote.tasks[i].total_st_revenue = task_total_st_revenue
          this.quote.tasks[i].total_ot_revenue = task_total_ot_revenue
          this.quote.tasks[i].total_st_cost = task_total_st_cost
          this.quote.tasks[i].total_ot_cost = task_total_ot_cost
          this.quote.tasks[i].total_revenue = task_total_revenue
          this.quote.tasks[i].total_cost = task_total_cost
          this.quote.tasks[i].total_st_hours = task_total_st_hours
          this.quote.tasks[i].total_ot_hours = task_total_ot_hours
          this.quote.tasks[i].total_hours = task_total_hours
          this.quote.tasks[i].total_hours_multiplied = task_total_hours * this.quote.tasks[i].multiplier
          this.quote.consolidated_tasks.total_st_hours += this.quote.tasks[i].total_st_hours
          this.quote.consolidated_tasks.total_ot_hours += this.quote.tasks[i].total_ot_hours
          this.quote.consolidated_tasks.total_hours += this.quote.tasks[i].total_st_hours + this.quote.tasks[i].total_ot_hours
          this.quote.consolidated_tasks.total_cost += this.quote.tasks[i].total_cost
          this.quote.consolidated_tasks.total_revenue += this.quote.tasks[i].total_revenue

        }else{
          for(var lc in this.quote.tasks[i].labor){
            this.quote.tasks[i].labor[lc].st_hours = this.quote.tasks[i].labor[lc].st_hours ? this.quote.tasks[i].labor[lc].st_hours : 0
            this.quote.tasks[i].labor[lc].ot_hours = this.quote.tasks[i].labor[lc].ot_hours ? this.quote.tasks[i].labor[lc].ot_hours : 0
            this.quote.tasks[i].labor[lc].total_hours = this.quote.tasks[i].labor[lc].st_hours + this.quote.tasks[i].labor[lc].ot_hours
            this.quote.tasks[i].labor[lc].st_revenue = this.quote.tasks[i].labor[lc].st_hours * this.quote.rates.labor_rates[lc].st_revenue_rate
            this.quote.tasks[i].labor[lc].ot_revenue = this.quote.tasks[i].labor[lc].ot_hours * this.quote.rates.labor_rates[lc].ot_revenue_rate
            this.quote.tasks[i].labor[lc].st_cost = this.quote.tasks[i].labor[lc].st_hours * this.quote.rates.labor_rates[lc].st_cost_rate
            this.quote.tasks[i].labor[lc].ot_cost = this.quote.tasks[i].labor[lc].ot_hours * this.quote.rates.labor_rates[lc].ot_cost_rate
            this.quote.tasks[i].labor[lc].revenue = this.quote.tasks[i].labor[lc].st_revenue + this.quote.tasks[i].labor[lc].ot_revenue
            this.quote.tasks[i].labor[lc].cost = this.quote.tasks[i].labor[lc].st_cost + this.quote.tasks[i].labor[lc].ot_cost
            task_total_st_revenue += this.quote.tasks[i].labor[lc].st_revenue
            task_total_ot_revenue += this.quote.tasks[i].labor[lc].ot_revenue
            task_total_st_cost += this.quote.tasks[i].labor[lc].st_cost
            task_total_ot_cost += this.quote.tasks[i].labor[lc].ot_cost
            task_total_revenue += this.quote.tasks[i].labor[lc].revenue
            task_total_cost += this.quote.tasks[i].labor[lc].cost
            task_total_st_hours += this.quote.tasks[i].labor[lc].st_hours
            task_total_ot_hours += this.quote.tasks[i].labor[lc].ot_hours
            task_total_hours += this.quote.tasks[i].labor[lc].st_hours + this.quote.tasks[i].labor[lc].ot_hours
            this.quote.consolidated_tasks.labor[lc].st_hours += this.quote.tasks[i].labor[lc].st_hours
            this.quote.consolidated_tasks.labor[lc].ot_hours += this.quote.tasks[i].labor[lc].ot_hours
            this.quote.consolidated_tasks.labor[lc].total_hours += this.quote.tasks[i].labor[lc].st_hours + this.quote.tasks[i].labor[lc].ot_hours
            this.quote.consolidated_tasks.labor[lc].total_cost += this.quote.tasks[i].labor[lc].cost
            this.quote.consolidated_tasks.labor[lc].total_revenue += this.quote.tasks[i].labor[lc].revenue
          }
          this.quote.tasks[i].total_st_revenue = task_total_st_revenue
          this.quote.tasks[i].total_ot_revenue = task_total_ot_revenue
          this.quote.tasks[i].total_st_cost = task_total_st_cost
          this.quote.tasks[i].total_ot_cost = task_total_ot_cost
          this.quote.tasks[i].total_revenue = task_total_revenue
          this.quote.tasks[i].total_cost = task_total_cost
          this.quote.tasks[i].total_st_hours = task_total_st_hours
          this.quote.tasks[i].total_ot_hours = task_total_ot_hours
          this.quote.tasks[i].total_hours = task_total_hours
          this.quote.consolidated_tasks.total_st_hours += this.quote.tasks[i].total_st_hours
          this.quote.consolidated_tasks.total_ot_hours += this.quote.tasks[i].total_ot_hours
          this.quote.consolidated_tasks.total_hours += this.quote.tasks[i].total_st_hours + this.quote.tasks[i].total_ot_hours
          this.quote.consolidated_tasks.total_cost += this.quote.tasks[i].total_cost
          this.quote.consolidated_tasks.total_revenue += this.quote.tasks[i].total_revenue
        }
      }
    },
    setMaterial(){
      this.quote.consolidated_material = {
        total_cost: 0,
        total_revenue: 0
      }
      for(var i = 0; i < this.quote.material.length; i++){
        if(this.quote.use_master_phase){
          this.quote.material[i].phase = this.quote.master_phase
        }        
        this.quote.material[i].quantity = this.quote.material[i].quantity ? this.quote.material[i].quantity : 0
        this.quote.material[i].item_cost = this.quote.material[i].item_cost ? this.quote.material[i].item_cost : 0
        this.quote.material[i].markup_percentage = this.quote.material[i].markup_percentage ? this.quote.material[i].markup_percentage : 0
        this.quote.material[i].total_cost = this.quote.material[i].quantity * this.quote.material[i].item_cost
        this.quote.material[i].total_revenue = (this.quote.material[i].total_cost * (this.quote.material[i].markup_percentage / 100)) + this.quote.material[i].total_cost
        this.quote.consolidated_material.total_cost += this.quote.material[i].total_cost
        this.quote.consolidated_material.total_revenue += this.quote.material[i].total_revenue
      }
    },
    setSubcontractors(){
      this.quote.consolidated_subcontractors = {
        total_cost: 0,
        total_revenue: 0
      }
      for(var i = 0; i < this.quote.subcontractors.length; i++){
        if(this.quote.use_master_phase){
          this.quote.subcontractors[i].phase = this.quote.master_phase
        }
        this.quote.subcontractors[i].markup_percentage = this.quote.subcontractors[i].markup_percentage ? this.quote.subcontractors[i].markup_percentage : 0
        this.quote.subcontractors[i].total_revenue = (this.quote.subcontractors[i].total_cost * (this.quote.subcontractors[i].markup_percentage / 100)) + this.quote.subcontractors[i].total_cost
        this.quote.consolidated_subcontractors.total_cost += this.quote.subcontractors[i].total_cost
        this.quote.consolidated_subcontractors.total_revenue += this.quote.subcontractors[i].total_revenue
      }
    },
    printJobReport(){
      var self = this
      self.$store.state.isLoading = true
      html2canvas(document.getElementById("summary-section")).then(function(canvas) {
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF('p', 'mm');
        var position = 10;
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight; // top padding for other pages
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save( 'job_report.pdf', {returnPromise: true}).then(self.$store.state.isLoading = false);
      });
    },
    saveQuote(save_type){
      var self = this

      if(self.quote.quote_title.trim() === ''){
        self.$store.state.notification = 'Please provide a title for this quote before saving.'
        return
      }

      var elmos = {
        phases: {},
        total_revenue: 0,
        total_cost: 0,
        total_hours: 0,
        ou_code: this.quote.job_id_code,
        ou_department: this.quote.department
      }

      for(var i = 1; i < 51; i++){
        elmos.phases['Phase ' + i] = {
          equipment : {revenue: 0, cost: 0},
          labor : {revenue: 0, cost: 0, hours: 0},
          material : {revenue: 0, cost: 0},
          other : {revenue: 0, cost: 0},
          subcontracting : {revenue: 0, cost: 0},
          total_revenue: 0,
          total_cost: 0
        }
      }

      // LABOR
      for(var i = 0; i < this.quote.tasks.length; i++){
        var task = this.quote.tasks[i]
        elmos.phases['Phase ' + task.phase].labor.revenue += task.total_revenue
        elmos.phases['Phase ' + task.phase].labor.cost += task.total_cost
        // elmos.phases['Phase ' + task.phase].labor.hours += task.total_hours
        elmos.phases['Phase ' + task.phase].labor.hours += task.total_hours_multiplied ? task.total_hours_multiplied : task.total_hours
        elmos.phases['Phase ' + task.phase].total_revenue += task.total_revenue
        elmos.phases['Phase ' + task.phase].total_cost += task.total_cost
        elmos.total_revenue += task.total_revenue
        elmos.total_cost += task.total_cost
        // elmos.total_hours += task.total_hours
        elmos.total_hours += task.total_hours_multiplied ? task.total_hours_multiplied : task.total_hours
      }

      // MATERIAL
      for(var i = 0; i < this.quote.material.length; i++){
        var material = this.quote.material[i]
        elmos.phases['Phase ' + material.phase].material.revenue += material.total_revenue
        elmos.phases['Phase ' + material.phase].material.cost += material.total_cost
        elmos.phases['Phase ' + material.phase].total_revenue += material.total_revenue
        elmos.phases['Phase ' + material.phase].total_cost += material.total_cost
        elmos.total_revenue += material.total_revenue
        elmos.total_cost += material.total_cost
      }

      // SUBCONTRACTING
      for(var i = 0; i < this.quote.subcontractors.length; i++){
        var sub = this.quote.subcontractors[i]
        elmos.phases['Phase ' + sub.phase].subcontracting.revenue += sub.total_revenue
        elmos.phases['Phase ' + sub.phase].subcontracting.cost += sub.total_cost
        elmos.phases['Phase ' + sub.phase].total_revenue += sub.total_revenue
        elmos.phases['Phase ' + sub.phase].total_cost += sub.total_cost
        elmos.total_revenue += sub.total_revenue
        elmos.total_cost += sub.total_cost
      }

      this.quote.elmos = elmos // SET ELMOS IN QUOTE
      Firebase.database().ref('opportunities').child(self.opportunityid).child('quote_last_modified').set(Date.now())
      // NEW
      if(self.isNew){
        Firebase.database().ref('quotes').child(self.opportunityid).push(self.quote).then(function(snapshot){
          Firebase.database().ref('totals').child(self.opportunityid).child(snapshot.key).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: true, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet}).then(function(){
            self.$emit('close')
          })
        })
      }

      // EDIT & REPLACE
      if(!self.isNew && save_type === 'replace'){
        this.quote.modified = Date.now()
        this.quote.modified_by = this.current_user
        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).set(self.quote).then(function(){
          Firebase.database().ref('totals').child(self.opportunityid).child(self.quoteid).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: self.quote.show_in_master, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet}).then(function(){
            self.$emit('close')
          })
        })
      }

      // EDIT & REVISE
      if(!self.isNew && save_type === 'revision'){
        var original_quote = JSON.parse(JSON.stringify(self.initial_quote))
        original_quote.archived = Date.now()
        original_quote.archived_by = this.current_user

        this.quote.modified = Date.now()
        this.quote.modified_by = this.current_user

        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).set(self.quote).then(function(){
          Firebase.database().ref('quotes_archive').child(self.opportunityid).child(self.quoteid).push(original_quote).then(function(){
            Firebase.database().ref('totals').child(self.opportunityid).child(self.quoteid).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: self.quote.show_in_master, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet})
          })
          self.initial_quote = JSON.parse(JSON.stringify(self.quote))
        })
      }

      if(!self.isNew && save_type === 'revert'){
        this.quote.reverted = Date.now()
        this.quote.reverted_by = this.current_user
        this.initial_quote.archived = Date.now()
        this.initial_quote.archived_by = this.current_user
        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).set(self.quote).then(function(){
          Firebase.database().ref('quotes_archive').child(self.opportunityid).child(self.quoteid).child(self.view_version).set(self.initial_quote).then(function(){
            Firebase.database().ref('totals').child(self.opportunityid).child(self.quoteid).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: self.quote.show_in_master, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet}).then(function(){
              self.$emit('close')
            })
          })
        })
      }
    }
  }
};

</script>

<style lang="scss" scoped>

  .section{
    text-align: left;
    margin: 10px;
  }

  .section-header{
    text-align: left;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .section-subheader{
    text-align: left;
    font-weight: bold;
    padding-top: 20px;
    font-size: 10px;
  }

  #summary-section{
    margin: 10px;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 20px;
    position: relative;
    background: #eeeeee;
    text-align: left;
    width: 850px;
  }

  textarea {
    height: 25px;
  }

  hr {
    margin-top: 20px;
  }


  tr:hover {
    background-color: transparent !important;
  }

  .handle{
    position:absolute;
    top:0px;
    left:0px;
    cursor:pointer
  }
</style>
