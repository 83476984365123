<template>
  <div class="opportunities">
    <v-container fluid>
      <v-card>
        <v-card-title>
          <v-row dense style="max-width:800px">
            <v-btn small color="primary" @click="form_id = 'new'" style='margin:10px'>New</v-btn>
            <v-col cols="12" sm="12" md="4">
              <v-text-field outlined dense v-model="search" append-icon="mdi-magnify" label="Search Opportunities..." single-line hide-details autocomplete="off"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-select outlined dense v-model="filter" :items="['All Open Opportunities', 'My Open Opportunities', 'My Assigned Estimates', 'All Opportunities']"></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <div style="position:absolute;top:70px;left:20px;"><v-btn color="primary" x-small style="" @click="buildTable()">refresh</v-btn><span style="font-size:10px;font-color:lightgray;padding-left:10px">last updated: {{last_updated}}</span></div>
          </v-row>
        </v-card-title>
        <v-data-table dense :mobile-breakpoint="0"  :no-data-text="loading_message" :search="search" must-sort :headers="!isMobile ? headers : $_.filter(headers, {show_mobile: true})" :items="opportunities_table_arr" :items-per-page="500" :footer-props="{'items-per-page-options': [25,50,100,500,-1]}" :height="$vuetify.breakpoint.height - 300 + 'px'" fixed-header>
          <template v-slot:item="row">
              <tr>
                <td style="min-width:100px">{{row.item.due_date ? formatDate(row.item.due_date) : ''}}</td>
                <td style="min-width:125px">{{row.item.due_time}}</td>
                <td style="min-width:130px">{{row.item.quote_number}}</td>
                <td style="min-width:120px">{{row.item.pre_bid_date ? formatDate(row.item.pre_bid_date) : ''}}</td>
                <td style="min-width:120px">{{row.item.pre_bid_time}}</td>
                <td style="min-width:120px">{{row.item.pre_bid_attendees}}</td>
                <td style="min-width:120px">{{row.item.stage !== '--None--' ? row.item.stage : ''}}</td>
                <td style="max-width:250px" class="table-link"  v-on:click="$router.push({name: 'Account', params: {accountId: row.item.account}})">{{row.item.account_name}}</td>
                <td style="max-width:250px"><span class="table-link" v-on:click="$router.push({name: 'Opportunity', params: {opportunityId: row.item.id}})">{{row.item.name}}</span></td>
                <td><span class="table-link" v-on:click="$router.push({name: 'User', params: {userId: row.item.owner}})">{{row.item.owner_name}}</span></td>
                <td>{{row.item.departments}}</td>
                <td>{{row.item.field_estimators}}</td>
                <td>{{row.item.shop_estimators}}</td>
              </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <OpportunityForm v-if="form_id" :id="form_id" @close="buildTable()" @save-new="saveNew"/>

  </div>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import OpportunityForm from '../components/forms/OpportunityForm.vue'
import { formatCurrency } from '../mixins/formatCurrency.js'
import Moment from 'moment'

export default {
  name: 'opportunities',
  components: {OpportunityForm},
  mixins: [formatCurrency],
  data(){
    return{
      loading_message: 'Loading Data...',
      form_id: null,
      search: '',
      filter: false,
      last_updated: '',
      headers: [
        { text: 'Due Date', align: 'start', value: 'due_date' },
        { text: 'Due Time', value: 'due_time_sortable' },
        { text: 'Quote Number', value: 'quote_number' },
        { text: 'Pre Bid Date', value: 'pre_bid_date' },
        { text: 'Pre Bid Time', value: 'pre_bid_time_sortable' },
        { text: 'Pre Bid Attendees', value: 'pre_bid_attendees',sortable: false },
        { text: 'Stage', value: 'stage' },
        { text: 'Account', value: 'account_name'},
        { text: 'Opportunity', value: 'name'},
        { text: 'Owner', value: 'owner_name' },
        { text: 'Departments', value: 'departments',sortable: false },
        { text: 'Field Estimators', value: 'field_estimators',sortable: false },
        { text: 'Shop Estimators', value: 'shop_estimators',sortable: false },
      ],
      opportunities_table_arr: []
    }
  },
  beforeMount(){
    var stored_filter = this.$store.state.opportunities_table_filter
    if(stored_filter){
      this.filter = stored_filter
    }else{
      var user_role = this.db.users[this.current_user].role
      if(user_role === 'Administrator'){
        this.filter = 'All Open Opportunities'
      }
      if(user_role === 'Sales'){
        this.filter = 'My Open Opportunities'
      }
      if(user_role === 'Estimating'){
        this.filter = 'My Assigned Estimates'
      }
    }
  },
  watch: {
    'filter': function (val) {
      this.buildTable()
      this.$store.state.opportunities_table_filter = val
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      isMobile: 'isMobile'
    })
  },
  methods:{
    attendeesString(attendees){
      var names = []
      for(var i = 0; i < attendees.length; i++){
        names.push(this.db.users[attendees[i]].first_name + ' ' + this.db.users[attendees[i]].last_name)
      }
      return names.join(', ')
    },
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    saveNew(id){
      this.form_id = null
      this.$router.push({name: 'Opportunity', params: {opportunityId: id}})
    },
    convertTime12to24(time12h){
      var [time, modifier] = time12h.split(' ');
      var [hours, minutes] = time.split(':');
      var sortable_string;
      if (hours === '12') {
        hours = '00';
      }
      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }
      sortable_string = String(hours) + String(minutes)
      return parseInt(sortable_string) !== 0 ? parseInt(sortable_string) : 1
    },

    buildTable(){
      var self = this
      self.form_id = null
      self.opportunities_table_arr = []
      self.loading_message = 'Loading Data...'
      self.$store.state.isLoading = true
      if(self.filter !== 'All Opportunities'){

        Firebase.database().ref('opportunities').orderByChild('status').equalTo('open').once('value',function(snapshot){
          var opportunities_db_clone = JSON.parse( JSON.stringify( snapshot.val() ) )
          for(var opp_id in opportunities_db_clone){
            var opp_object = opportunities_db_clone[opp_id]
            var table_row = {}
            if(!opp_object.disabled){
              table_row.name = opp_object.name
              table_row.id = opp_id
              table_row.owner = opp_object.owner
              table_row.owner_name = self.db.users[opp_object.owner].first_name + ' ' + self.db.users[opp_object.owner].last_name
              table_row.account = opp_object.account
              table_row.account_name = self.db.accounts[opp_object.account].name
              table_row.stage = opp_object.stage
              table_row.due_date = opp_object.due_date
              table_row.due_time = opp_object.due_time
              table_row.due_time_sortable = opp_object.due_time ? self.convertTime12to24(opp_object.due_time) : ''
              table_row.pre_bid_date = opp_object.pre_bid_date
              table_row.pre_bid_time = opp_object.pre_bid_time
              table_row.pre_bid_time_sortable = opp_object.pre_bid_time ? self.convertTime12to24(opp_object.pre_bid_time) : ''
              table_row.pre_bid_attendees = opp_object.pre_bid_attendees ? self.attendeesString(opp_object.pre_bid_attendees) : ''
              table_row.quote_number = opp_object.quote_number

              var departments = []
              var field_estimators = []
              var shop_estimators = []

              for(var dept in opp_object.estimating){
                if(opp_object.estimating[dept].required){

                  departments.push(opp_object.estimating[dept].title)

                  if(opp_object.estimating[dept].estimator.length > 0){
                    var estimator = self.db.users[opp_object.estimating[dept].estimator].first_name + ' ' + self.db.users[opp_object.estimating[dept].estimator].last_name
                    if(dept === 'fabrication' || dept === 'machine' || dept === 'mechanical' || dept === 'pump' || dept === 'transportation' || dept === 'engineering'){
                      if(shop_estimators.indexOf(estimator) === -1){
                        shop_estimators.push(estimator)
                      }
                    }else{
                      if(field_estimators.indexOf(estimator) === -1){
                        field_estimators.push(estimator)
                      }
                    }
                  }
                }
              }

              table_row.departments = departments.sort().join('; ')
              table_row.field_estimators = field_estimators.sort().join('; ')
              table_row.shop_estimators = shop_estimators.sort().join('; ')

              if(self.filter === 'All Open Opportunities'){
                self.opportunities_table_arr.push(table_row)
              }

              if(self.filter === 'My Open Opportunities' && table_row.owner === self.current_user){
                self.opportunities_table_arr.push(table_row)
              }

              if(self.filter === 'My Assigned Estimates'){
                var estimating = opp_object.estimating
                var assigned_to_user = false
                for(var dept in estimating){
                  if(estimating[dept].estimator === self.current_user && (opp_object.stage === 'Estimating' || opp_object.stage === 'Quote Revision')){
                    assigned_to_user = true
                  }
                }
                if(assigned_to_user){
                  self.opportunities_table_arr.push(table_row)
                }
              }

            }
          }
          self.last_updated = Moment().format("MM/DD/YY h:mm:ss A")
          self.loading_message = self.opportunities_table_arr.length > 0 ? 'Loading Data...' : 'No data available'
          self.$store.state.isLoading = false
        })
      }else{
        Firebase.database().ref('opportunities').once('value',function(snapshot){
          var opportunities_db_clone = JSON.parse( JSON.stringify( snapshot.val() ) )
          for(var opp_id in opportunities_db_clone){
            var opp_object = opportunities_db_clone[opp_id]
            var table_row = {}
            if(!opp_object.disabled){
              table_row.name = opp_object.name
              table_row.id = opp_id
              table_row.owner = opp_object.owner
              table_row.owner_name = self.db.users[opp_object.owner].first_name + ' ' + self.db.users[opp_object.owner].last_name
              table_row.account = opp_object.account
              table_row.account_name = self.db.accounts[opp_object.account].name
              table_row.stage = opp_object.stage
              table_row.due_date = opp_object.due_date
              table_row.due_time = opp_object.due_time
              table_row.due_time_sortable = opp_object.due_time ? self.convertTime12to24(opp_object.due_time) : ''
              table_row.pre_bid_date = opp_object.pre_bid_date
              table_row.pre_bid_time = opp_object.pre_bid_time
              table_row.pre_bid_time_sortable = opp_object.pre_bid_time ? self.convertTime12to24(opp_object.pre_bid_time) : ''
              table_row.quote_number = opp_object.quote_number

              var departments = []
              var field_estimators = []
              var shop_estimators = []

              for(var dept in opp_object.estimating){
                if(opp_object.estimating[dept].required){

                  departments.push(opp_object.estimating[dept].title)

                  if(opp_object.estimating[dept].estimator.length > 0){
                    var estimator = self.db.users[opp_object.estimating[dept].estimator].first_name + ' ' + self.db.users[opp_object.estimating[dept].estimator].last_name
                    if(dept === 'fabrication' || dept === 'machine' || dept === 'mechanical' || dept === 'pump' || dept === 'transportation' || dept === 'engineering'){
                      if(shop_estimators.indexOf(estimator) === -1){
                        shop_estimators.push(estimator)
                      }
                    }else{
                      if(field_estimators.indexOf(estimator) === -1){
                        field_estimators.push(estimator)
                      }
                    }
                  }
                }
              }

              table_row.departments = departments.sort().join('; ')
              table_row.field_estimators = field_estimators.sort().join('; ')
              table_row.shop_estimators = shop_estimators.sort().join('; ')
              self.opportunities_table_arr.push(table_row)
            }
          }
          self.last_updated = Moment().format("MM/DD/YY h:mm:ss A")
          self.loading_message = self.opportunities_table_arr.length > 0 ? 'Loading Data...' : 'No data available'
          self.$store.state.isLoading = false
        })
      }

    }
  }
}
</script>

<style lang="scss">
  td{
    line-height: 120%;
  }
</style>


