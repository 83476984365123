<template>
      <v-dialog v-model="id" scrollable persistent max-width="1100px;" style="z-index:9999999999999">

        <v-form ref="form" lazy-validation>
          <v-card>
            <div class="form-header">{{id === 'new' ? 'New' : 'Edit'}} Account</div>
            <v-card-text>
              <v-container style="position:relative">

                <div class="form-banner">Account Information</div>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense @focus="$event.target.select()" label="*Account Name" required outlined :rules="accountNameRules" v-model="form_data.name"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete sort="name" v-model="form_data.owner" :items="active_users" item-text="name" item-value="id" label="*Account Owner" dense outlined :disabled="!is_admin"></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete sort="name" v-model="form_data.parent_account" :items="active_accounts" item-text="name" item-value="id" label="Parent Account" dense outlined></v-autocomplete>
                  </v-col>


                  <v-col cols="12" sm="6" md="6">
                    <v-select dense :items="['Beverage','Chemicals','Construction','Building','Cement','Citrus','Food','Mining','Municipal','Packaging','Phosphate','Power','Pulp/Paper','Sand/Aggr','Sugar','Other']" outlined label="*Industry" :rules="industryRules" v-model="form_data.industry"></v-select>
                  </v-col>
                </v-row>

                <v-row class="inputs-container">

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense @focus="$event.target.select()" label="Phone" outlined v-model="form_data.phone"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense @focus="$event.target.select()" label="Website" outlined v-model="form_data.website"></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea dense label="Description" outlined rows="4" class="line-height-adjustment" v-model="form_data.description"></v-textarea>
                  </v-col>
                </v-row>

                <div class="form-banner">Address Information</div>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense @focus="$event.target.select()" label="Billing Address" outlined v-model="form_data.billing_address" autocomplete="off"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense @focus="$event.target.select()" label="Shipping Address" outlined v-model="form_data.shipping_address"></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="6" md="6">
                    <v-textarea dense label="Billing Street" outlined rows="4" class="line-height-adjustment" v-model="form_data.billing_street"></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-textarea dense label="Shipping Street" outlined rows="4" class="line-height-adjustment" v-model="form_data.shipping_street"></v-textarea>
                  </v-col>
                </v-row>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Billing City" outlined v-model="form_data.billing_city"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Billing State" outlined  v-model="form_data.billing_state"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Shipping City" outlined v-model="form_data.shipping_city"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Shipping State" outlined v-model="form_data.shipping_state"></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="inputs-container">
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Billing Zip Code" outlined v-model="form_data.billing_zip"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Billing Country" outlined v-model="form_data.billing_country"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Shipping Zip Code" outlined v-model="form_data.shipping_zip"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field dense @focus="$event.target.select()" label="Shipping Country" outlined v-model="form_data.shipping_country"></v-text-field>
                  </v-col>
                </v-row>

                <div style="text-align:left;font-size:12px">*indicates required field</div>

              </v-container>

            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$emit('close')">Cancel</v-btn>
              <v-btn color="primary" @click="saveAccount()">Save</v-btn>
            </v-card-actions>
          </v-card>

        </v-form>
      </v-dialog>


</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Firebase from 'firebase/app'
  import _ from 'lodash';

  export default {
    name: 'AccountForm',
    props: {
        id: String
    },
    data(){
      return{
    searchInput: "",
        form_data: {
          'name' : '',
          'owner' : '',
          'parent_account': '',
          'phone': '',
          'industry': '',
          'website': '',
          'description': '',
          'billing_address': '',
          'billing_street': '',
          'billing_city': '',
          'billing_state': '',
          'billing_zip': '',
          'billing_country': '',
          'shipping_address': '',
          'shipping_street': '',
          'shipping_city': '',
          'shipping_state': '',
          'shipping_zip': '',
          'shipping_country': ''
        },
        valid: false,
        selected: '--None--',
        accountNameRules: [
          v => !!v || 'Account Name is required'
        ],
        accountOwnerRules: [
          v => !!v || 'Account Owner is required'
        ],
        industryRules: [
          v => !!v || 'Industry is required'
        ],
      }
    },
    mounted(){
      if(this.id !== 'new'){
        this.form_data = JSON.parse( JSON.stringify( this.db.accounts[this.id] ) )
      }else{
        this.form_data.owner = this.current_user
      }
    },
    computed: {
      ...mapGetters({
        db: 'db',
        current_user: 'current_user',
        active_accounts: 'active_accounts',
        active_users: 'active_users',
        is_admin: 'is_admin'
      })
    },
    methods: {
      saveAccount(){
        var self = this
        var is_valid = self.$refs.form.validate()

        if(! is_valid){
          self.$store.state.notification = 'Please complete required fields.'
          return
        }

        self.$store.state.isLoading = true

        if(this.id === 'new'){
          self.form_data.added_by = self.current_user
          self.form_data.created_date = Date.now()
          Firebase.database().ref('accounts').push(self.form_data).then(function(snapshot){
            Firebase.database().ref('accounts').child(snapshot.key).child('id').set(snapshot.key).then(function(){
              self.$store.state.notification = 'Account successfully saved.'
              self.$store.state.isLoading = false
              self.$emit('close',snapshot.key)
            })
          })
        }

        if(this.id !== 'new'){
          self.form_data.edited_by = self.current_user
          self.form_data.edit_date = Date.now()
          Firebase.database().ref('accounts').child(self.id).set(self.form_data).then(function(snapshot){
            self.$store.state.notification = 'Account successfully saved.'
            self.$store.state.isLoading = false
            self.$emit('close')
          })
        }
      }
    }
  }
</script>
<style lang="scss">

</style>

