<template>
  <div class="user">
    <v-container fluid>
      <v-card style="max-width:500px">
        <v-card-text>
          <v-row class="inputs-container">

            <v-col cols="12" sm="12" md="12" lg="12">
              <v-icon large>mdi-account</v-icon>
              <span style="font-size:16px;font-weight:bold;padding-left:10px">{{db.users[id].first_name + ' ' + db.users[id].last_name}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12">
              <div class="display-label">Email</div>
              <div class="display-value">{{db.users[id].email}}</div>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12">
              <div class="display-label">Role</div>
              <div class="display-value">{{db.users[id].role}}</div>
            </v-col>

          </v-row>
        </v-card-text>
        <v-btn color="primary" small @click="edit_form = true" style="margin:10px">Edit</v-btn>
      </v-card>
    </v-container>
    <UserForm v-if="edit_form" :id="id" @close="edit_form = false" />
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import Confirm from '../components/Confirm.vue'
import { mapGetters, mapActions } from 'vuex'
import UserForm from '../components/forms/UserForm.vue'

export default {
  name: 'user',
  components: {UserForm},
  data(){
    return{
      edit_form: false,
      id: this.$route.params['userId']
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user'
    })
  },
  methods:{}
}
</script>

<style lang="scss">

</style>


