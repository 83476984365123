<template>
  <div class="login-page">

          <div class="login-form">
            <div style="text-align:center;padding:0px;"><img src="../assets/logo_new.svg" style="width:200px;"></div>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Email"
                required
                @keyup="clearError"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="Password"
                required
                type="password"
                @keyup="clearError"
              ></v-text-field>

              <v-btn
                :disabled="false"
                color="primary"
                class="mr-4"
                @click="login"
              >
                LOG IN
              </v-btn>

            </v-form>
              <div class="forgot-password-link" @click="forgot_password = true">Forgot Password?</div>
              <div class="login-error" style="min-height:50px">{{loginError}}</div>
          </div>

          <ForgotPassword v-if="forgot_password" @close="forgot_password = false"/>
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import ForgotPassword from '../components/ForgotPassword.vue'

export default {
  name: 'login',
  components: {ForgotPassword},
  data(){
    return{
      forgot_password: false,
      valid: false,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      password: '',
      passwordRules: [
        v => !!v || 'Password is required'
      ],
      loginError: null
    }
  },
  mounted(){
    var vm = this;
    document.addEventListener('keydown',vm.isEnter, true);
  },
  beforeDestroy(){
    var vm = this;
    document.removeEventListener('keydown',vm.isEnter, true);
  },
  methods:{
    isEnter(event){
      event.key === 'Enter' ? this.login() : false
    },
    clearError(event){
      event.key !== 'Enter' ? this.loginError = null : false
    },
    login() {
      var self = this

      self.$refs.form.validate()

      if(!self.valid || self.email === '' || self.password === ''){
        return
      }

      Firebase.auth().signInWithEmailAndPassword(self.email, self.password).then(user => {

      }).catch(err => {
        self.loginError = err["message"]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../styles/global.scss";

  .login-page{
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
    background: #243872;
  }

  .login-form{
    position: relative;
    top: 50px;
    width: 500px;
    height: 400px;
    margin: 0 auto;
    padding: 50px;
    background: $secondary;
  }

  .logo-container{
    margin-bottom: 50px;
  }

  .change-password-link{
    margin: 0 auto;
    text-align: center;
    /*color: $primary;*/
    cursor: pointer;
    padding-top: 25px;
  }

  .tm{
    position: absolute;
    right: -15px;
    top: 10px;
    color: #696969;
    font-size: 14px;
  }

  .login-error{
    padding: 10px;
    color: #ff5252;
    line-height: 100%;
    font-size: 14px;
    text-align: center;
  }

  .banner-title{
    font-family: 'Goldman';
    color: white;
    display: flex;
    width: 160px;
    margin: 0 auto;
    position: relative;
    top: 70px;
    font-size: 32px;
  }

  .banner-title-left{
    background: $ms-red;
    padding: 2px 4px;
  }

  .banner-title-right{
    padding: 2px;
  }

  .forgot-password-link{
    position: relative;
    right: 5px;
    color: #243872;
    cursor: pointer;
    margin: 0 auto;
    width: 125px;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;

  }
</style>


