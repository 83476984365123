import Vue from 'vue';
import Vuex from 'vuex';
import Firebase from 'firebase/app';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // users_function_url: 'https://us-central1-ms-ops-dev.cloudfunctions.net/',
    // users_function_url: 'https://us-central1-ms-ops-test-86dbc.cloudfunctions.net/',
    users_function_url: 'https://us-central1-ms-ops-production.cloudfunctions.net/',
    version: '20.5.0',
    current_user: null,
    db: {
      users: null,
      accounts: null,
      contacts: null,
      estimates: null,
      logged_calls: null,
      field_rates: null,
      msa_rates: null,
      msa_rates_last_upload: null,
      msa_rates_last_upload_by: null,
      transportation_rates: null,
      engineering_rates: null,
      fabrication_rates: null,
      mechanical_rates: null,
      pump_rates: null,
      specialty_rates: null,
      machine_rates: null,
      elmco_machine_rates: null,
      elmco_fabrication_rates: null,
      daily_opportunities_due_max: null,
      notes: null,
    },
    include_closed_opportunities: false,
    opportunities_table_filter: false,
    accounts_table_filter: false,
    contacts_table_filter: false,
    selected_report: 'template_report',
    report_sort_by: 'name',
    report_sort_desc: false,
    isMobile: false,
    isLoading: false,
    notification: false,
    ou_departments: [],
    last_viewed_quote: null,
    revenue_categories: [
      'Concrete Work',
      'Dragline',
      'Dredge',
      'Electrical',
      'Filters',
      'Gearbox',
      'Maintenance Support',
      'Pipe',
      'Plate Work',
      'Pumps',
      'Rotating Equipment',
      'Structural Steel',
      'Tanks',
      'Boiler',
      'Other'
    ],
    work_schedule: [
      'Monday Thru Friday Excluding Holidays',
      'Monday Thru Saturday Excluding Holidays',
      'Monday Thru Thursday Excluding Holidays',
      'Monday Thru Sunday Excluding Holidays',
      'Around The Clock Monday Thru Sunday',
      'Around The Clock Monday Thru Saturday',
      'Around The Clock Monday Thru Friday',
      'Around The Clock Monday Thru Sunday Excluding Holidays',
      'Around The Clock Monday Thru Saturday Excluding Holidays',
      'Around The Clock Monday Thru Friday Excluding Holidays',
    ],
    work_hours: ['8 Hours Per Shift', '10 Hours Per Shift', '12 Hours Per Shift', '16 Hours Per Shift'],
  },
  getters: {
    selected_report: (state) => state.selected_report,
    users_function_url: (state) => state.users_function_url,
    current_user: (state) => state.current_user,
    db: (state) => state.db,
    isLoading: (state) => state.isLoading,
    isMobile: (state) => state.isMobile,
    ou_departments: (state) => state.ou_departments,
    revenue_categories: (state) => state.revenue_categories,
    work_schedule: (state) => state.work_schedule,
    work_hours: (state) => state.work_hours,
    msas: (state, getters) => {
      var msas_db_clone = JSON.parse(JSON.stringify(state.db.msa_rates));
      var arr = [];
      for (var msa_id in msas_db_clone) {
        var msa_object = msas_db_clone[msa_id];
        if (!msa_object.inactive) {
          console.log(msa_id)
          arr.push({id: msa_id, name: msa_object.name});
        }
      }
      return arr;
    },

    active_accounts: (state, getters) => {
      var accounts_db_clone = JSON.parse(JSON.stringify(state.db.accounts));
      var arr = [];

      for (var account_id in accounts_db_clone) {
        var account_object = accounts_db_clone[account_id];
        account_object.owner_name = state.db.users[account_object.owner].first_name + ' ' + state.db.users[account_object.owner].last_name;
        account_object.parent_account_name = account_object.parent_account ? state.db.accounts[account_object.parent_account].name : '';
        if (!account_object.disabled) {
          arr.push(account_object);
        }
      }
      return arr;
    },
    active_contacts: (state) => {
      var contacts_db_clone = JSON.parse(JSON.stringify(state.db.contacts));
      var arr = [];

      for (var contact_id in contacts_db_clone) {
        var contact_object = contacts_db_clone[contact_id];
        contact_object.name = contact_object.first_name + ' ' + contact_object.last_name;
        contact_object.owner_name = state.db.users[contact_object.owner].first_name + ' ' + state.db.users[contact_object.owner].last_name;
        contact_object.account_name = state.db.accounts[contact_object.account].name;
        contact_object.name_and_account_name = contact_object.name + ' (' + contact_object.account_name + ')';
        contact_object.id = contact_id;

        if (!contact_object.disabled) {
          arr.push(contact_object);
        }
      }
      return arr;
    },
    all_users: (state) => {
      var users_db_clone = JSON.parse(JSON.stringify(state.db.users));
      var arr = [];

      for (var user_id in users_db_clone) {
        var user_object = users_db_clone[user_id];
        user_object.name = user_object.first_name + ' ' + user_object.last_name;
        user_object.owner_name = state.db.users[user_object.owner].first_name + ' ' + state.db.users[user_object.owner].last_name;
        user_object.id = user_id;
        arr.push(user_object);
      }
      return arr;
    },
    active_users: (state) => {
      var users_db_clone = JSON.parse(JSON.stringify(state.db.users));
      var arr = [];

      for (var user_id in users_db_clone) {
        var user_object = users_db_clone[user_id];
        user_object.name = user_object.first_name + ' ' + user_object.last_name;
        user_object.owner_name = state.db.users[user_object.owner].first_name + ' ' + state.db.users[user_object.owner].last_name;
        user_object.id = user_id;

        if (!user_object.disabled) {
          arr.push(user_object);
        }
      }
      return arr;
    },
    inactive_users: (state) => {
      var users_db_clone = JSON.parse(JSON.stringify(state.db.users));
      var arr = [];

      for (var user_id in users_db_clone) {
        var user_object = users_db_clone[user_id];
        user_object.name = user_object.first_name + ' ' + user_object.last_name;
        user_object.owner_name = state.db.users[user_object.owner].first_name + ' ' + state.db.users[user_object.owner].last_name;
        user_object.id = user_id;

        if (user_object.disabled) {
          arr.push(user_object);
        }
      }
      return arr;
    },
    is_admin: (state) => {
      return state.db.users[state.current_user].role === 'Administrator';
    },
    is_msa_admin: (state) => {
      return state.db.users[state.current_user].is_msa_admin;
    },    
    is_sales: (state) => {
      return state.db.users[state.current_user].role === 'Sales';
    },
    is_estimating: (state) => {
      return state.db.users[state.current_user].role === 'Estimating';
    },
  },
  mutations: {
    retrieveDatabaseSnapshot(state, uid) {
      state.current_user = uid;

      // daily_opportunities_due_max

      Firebase.database()
        .ref('daily_opportunities_due_max')
        .on('value', function(snapshot) {
          state.db.daily_opportunities_due_max = snapshot.val();
        });

      Firebase.database()
        .ref('users')
        .child(uid)
        .on('value', function(snapshot) {
          // force user logout if deactivated
          var u = snapshot.val();
          if (u.disabled) {
            state.current_user = null;
          }
        });

      Firebase.database()
        .ref('ou_departments')
        .on('value', function(snapshot) {
          var current_codes = []
          var ouds = snapshot.val();
          for(var i = 0; i < state.ou_departments.length;i++){
            current_codes.push(state.ou_departments[i].code)
          }
          for(var key in ouds){
            var ou_code = ouds[key].code
            if(current_codes.indexOf(ou_code) === -1){
              state.ou_departments.push(ouds[key])
            }
          }
        });

      Firebase.database()
        .ref('users')
        .on('value', function(snapshot) {
          state.db.users = snapshot.val();
        });

      Firebase.database()
        .ref('accounts')
        .on('value', function(snapshot) {
          state.db.accounts = snapshot.val();
        });

      Firebase.database()
        .ref('contacts')
        .on('value', function(snapshot) {
          state.db.contacts = snapshot.val();
        });

      Firebase.database()
        .ref('estimates')
        .on('value', function(snapshot) {
          state.db.estimates = snapshot.val();
        });

      Firebase.database()
        .ref('logged_calls')
        .on('value', function(snapshot) {
          state.db.logged_calls = snapshot.val();
        });

      Firebase.database()
        .ref('field_rates')
        .on('value', function(snapshot) {
          state.db.field_rates = snapshot.val();
        });

      Firebase.database()
        .ref('msa_rates')
        .on('value', function(snapshot) {
          state.db.msa_rates = snapshot.val();
        });

      Firebase.database()
        .ref('msa_rates_last_upload')
        .on('value', function(snapshot) {
          state.db.msa_rates_last_upload = snapshot.val();
        });

      Firebase.database()
        .ref('msa_rates_last_upload_by')
        .on('value', function(snapshot) {
          state.db.msa_rates_last_upload_by = snapshot.val();
        });

      Firebase.database()
        .ref('transportation_rates')
        .on('value', function(snapshot) {
          state.db.transportation_rates = snapshot.val();
        });

      Firebase.database()
        .ref('engineering_rates')
        .on('value', function(snapshot) {
          state.db.engineering_rates = snapshot.val();
        });

      Firebase.database()
        .ref('fabrication_rates')
        .on('value', function(snapshot) {
          state.db.fabrication_rates = snapshot.val();
        });

      Firebase.database()
        .ref('mechanical_rates')
        .on('value', function(snapshot) {
          state.db.mechanical_rates = snapshot.val();
        });

      Firebase.database()
        .ref('pump_rates')
        .on('value', function(snapshot) {
          state.db.pump_rates = snapshot.val();
        });

      Firebase.database()
        .ref('specialty_rates')
        .on('value', function(snapshot) {
          state.db.specialty_rates = snapshot.val();
        });

      Firebase.database()
        .ref('machine_rates')
        .on('value', function(snapshot) {
          state.db.machine_rates = snapshot.val();
        });

      Firebase.database()
        .ref('elmco_machine_rates')
        .on('value', function(snapshot) {
          state.db.elmco_machine_rates = snapshot.val();
        });

      Firebase.database()
        .ref('elmco_fabrication_rates')
        .on('value', function(snapshot) {
          state.db.elmco_fabrication_rates = snapshot.val();
        });

      Firebase.database()
        .ref('notes')
        .on('value', function(snapshot) {
          state.db.notes = snapshot.val();
        });        
    },
    setIsMobile(state) {
      var available_width = document.getElementById('content').offsetWidth;
      state.isMobile = available_width >= 675 ? false : true;
    },
  },
  actions: {
    retrieveDatabaseSnapshot: ({ commit }, uid) => commit('retrieveDatabaseSnapshot', uid),
    setIsMobile: ({ commit }) => commit('setIsMobile'),
  },
});
