<template>
  <v-dialog scrollable persistent v-model="dialog" width="100%">
    <v-form ref="form" lazy-validation>
      <v-card style="background:#eeeeee" v-if="form_mounted">
        <div class="form-header">Transportation Quote Sheet</div>


        <v-card-text>

          <!-- PREVIOUS VERSIONS -->
          <v-card style="margin-top:20px" v-if="quote_archive && !isNew">
            <v-card-text style="text-align:left">
              <div class="section-header">PREVIOUS VERSIONS</div>

                <v-simple-table dense style="width:600px;">
                  <tbody>
                    <tr v-for="(archived_quote) in sorted_archive">
                      <td style="width:75px" v-if="view_version !== archived_quote.key"><v-btn color="primary" @click="viewPreviousVersion(archived_quote.key)" x-small>VIEW</v-btn></td>
                      <td style="width:75px;text-align:center" v-if="view_version === archived_quote.key">viewing</td>
                      <td>{{archived_quote.quote_title}} (created on {{formatDate(archived_quote.created)}})</td>
                    </tr>
                  </tbody>
                </v-simple-table>
            </v-card-text>
          </v-card>

          <!-- GENERAL -->
          <v-card class="section">
            <v-card-text style="text-align:left">
              <div class="section-header">GENERAL</div>
              <v-row>
                <v-col cols="6">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Quote Title*" outlined v-model="quote.quote_title"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select dense hide-details="auto" :items="ou_departments" item-text="department" outlined label="Department*" v-model="quote.department">
                      <template slot="selection" slot-scope="data">{{data.item.department}} ({{ data.item.code }})</template>
                      <template slot="item" slot-scope="data">{{data.item.department}} ({{ data.item.code }})</template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Straight Time Markup %" outlined min="0" v-model.number="quote.st_markup_percentage" @change="" suffix="%" disabled></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Over-Time Markup %" outlined min="0" v-model.number="quote.ot_markup_percentage" oninput="if(Number(this.value) > 100) this.value = 100" @change="" suffix="%"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Subcontracts Markup %" outlined min="0" v-model.number="quote.sub_markup_percentage" @change="" suffix="%"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-textarea dense hide-details="auto" label="Scope of Work" outlined v-model="quote.scope_of_work" auto-grow></v-textarea>
                </v-col>
                <v-col cols="6">
                  <v-textarea dense hide-details="auto" label="Clarifications" outlined v-model="quote.clarifications" auto-grow></v-textarea>
                </v-col>
              </v-row>

              <hr>

              <v-row dense>
                <v-col cols="6">
                  <v-checkbox dense label="Use Master Phase" hide-details v-model="quote.use_master_phase"></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense v-if="quote.use_master_phase">
                <v-col cols="2">
                  <v-select dense hide-details="auto" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Master Phase" v-model="quote.master_phase"></v-select></v-col>
                </v-col>
              </v-row>

              <hr>

              <v-row>
                <v-col cols="3">
                  <v-checkbox dense label="Quote Completed" hide-detail v-model="quote.completed" style="margin:0px 0px 0px 10px"></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-checkbox dense label="Quote Reviewed" hide-detail v-model="quote.reviewed" style="margin:0px 0px 0px 10px"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- SHIPMENTS -->
          <v-card class="section">
            <v-card-text style="text-align:left">
              <div class="section-header">SHIPMENTS</div>

              <div v-for="(shipment, index) in quote.shipments" style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:20px;position:relative">
                <v-btn color="primary" @click="deleteShipment(index)" x-small fab style="position:absolute;top:5px;right:5px;width:20px;height:20px;"><v-icon x-small>mdi-close</v-icon></v-btn>

                <div>Shipment {{index+1}}</div>

                <v-row style='margin:0px'>
                  <v-col cols="4"><v-select dense hide-details="auto" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Number of Loads" v-model="quote.shipments[index].number_of_loads"></v-select></v-col>
                  <v-col cols="4"><v-select dense hide-details="auto" :items="['Legal','Oversize']" outlined label="Load Criteria" v-model="quote.shipments[index].load_criteria"></v-select></v-col>
                  <v-col cols="4"><v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Description" outlined v-model="quote.shipments[index].description" style=""></v-text-field></v-col>
                </v-row>

                <v-row style='margin:0px'>
                  <v-col cols="4"><v-select dense hide-details="auto" :items="['Mid-State','3rd Party']" outlined label="Truck Owner" v-model="quote.shipments[index].truck_owner" @change="setChargeType();setDefaultDispatchHours(index)"></v-select></v-col>

                  <v-col cols="4">
                    <v-select dense hide-details="auto" :items="truck_types" outlined label="Truck Type" v-model="quote.shipments[index].truck_type" @change="setChargeType()"></v-select>
                    <div style="font-size:12px;line-height:150%" v-if="quote.shipments[index].truck_owner === 'Mid-State' && quote.shipments[index].charge_type === 'Hourly Rate'">ST Cost: <span class="editable-rate" @click="editQuoteRate([quote.shipments[index].truck_type, 'st_cost'])">{{formatCurrency(quote.rates[quote.shipments[index].truck_type].st_cost)}}</span>, OT Cost: <span class="editable-rate" @click="editQuoteRate([quote.shipments[index].truck_type, 'ot_cost'])">{{formatCurrency(quote.rates[quote.shipments[index].truck_type].ot_cost)}}</span></div>
                    <div style="font-size:12px;line-height:150%" v-if="quote.shipments[index].truck_owner === 'Mid-State' && quote.shipments[index].charge_type === 'Hourly Rate'">ST Revenue: <span class="editable-rate" @click="editQuoteRate([quote.shipments[index].truck_type,'st_revenue'])">{{formatCurrency(quote.rates[quote.shipments[index].truck_type].st_revenue)}}</span>, OT Revenue: <span class="editable-rate" @click="editQuoteRate([quote.shipments[index].truck_type, 'ot_revenue'])">{{formatCurrency(quote.rates[quote.shipments[index].truck_type].ot_revenue)}}</span></div>
                    <div style="font-size:12px" v-if="quote.shipments[index].truck_owner === 'Mid-State' && quote.shipments[index].charge_type === 'Mileage'">Per Mile Cost: <span class="editable-rate" @click="editQuoteRate([quote.shipments[index].truck_type,'mile_cost'])">{{formatCurrency(quote.rates[quote.shipments[index].truck_type].mile_cost)}}</span></div>
                    <div style="font-size:12px" v-if="quote.shipments[index].truck_owner === 'Mid-State' && quote.shipments[index].charge_type === 'Mileage'">Per Mile Revenue: <span class="editable-rate" @click="editQuoteRate([quote.shipments[index].truck_type,'mile_revenue'])">{{formatCurrency(quote.rates[quote.shipments[index].truck_type].mile_revenue)}}</span></div>
                    <div style="font-size:12px" v-if="quote.shipments[index].truck_owner === '3rd Party'">Cost: Lump Sum</div>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" class="number-field" label="Roundtrip Hours" min="0" v-model.number="quote.shipments[index].roundtrip_hours" v-if="quote.shipments[index].charge_type === 'Hourly Rate'" suffix="hours"></v-text-field>
                    <v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" class="number-field" label="Roundtrip Mileage" min="0" v-model.number="quote.shipments[index].roundtrip_mileage" v-if="quote.shipments[index].charge_type === 'Mileage'" suffix="miles"></v-text-field>
                    <v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" class="number-field" label="Lump Sum Amount" min="0" v-model.number="quote.shipments[index].subcontractor_lump_sum" v-if="quote.shipments[index].charge_type === 'Lump Sum'" prefix="$"></v-text-field>
                  </v-col>
                </v-row>

                <v-row style='margin:0px'>

                  <v-col cols="4">
                    <v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" class="number-field" label="Loading Hours" min="0" v-model.number="quote.shipments[index].loading_hours" suffix="hours"></v-text-field>

                    <div style="font-size:12px;line-height:150%">ST Cost: <span class="editable-rate" @click="editQuoteRate(['Loading', 'st_cost'])">{{formatCurrency(quote.rates['Loading'].st_cost)}}</span>, OT Cost: <span class="editable-rate" @click="editQuoteRate(['Loading', 'ot_cost'])">{{formatCurrency(quote.rates['Loading'].ot_cost)}}</span></div>
                    <div style="font-size:12px;line-height:150%">ST Revenue: <span class="editable-rate" @click="editQuoteRate(['Loading','st_revenue'])">{{formatCurrency(quote.rates['Loading'].st_revenue)}}</span>, OT Revenue: <span class="editable-rate" @click="editQuoteRate(['Loading', 'ot_revenue'])">{{formatCurrency(quote.rates['Loading'].ot_revenue)}}</span></div>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field dense @focus="$event.target.select()" outlined hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" class="number-field" label="Dispatch Hours" min="0" v-model.number="quote.shipments[index].dispatch_hours" suffix="hours"></v-text-field>
                    <div style="font-size:12px;line-height:150%">ST Cost: <span class="editable-rate" @click="editQuoteRate(['Dispatch', 'st_cost'])">{{formatCurrency(quote.rates['Dispatch'].st_cost)}}</span>, OT Cost: <span class="editable-rate" @click="editQuoteRate(['Dispatch', 'ot_cost'])">{{formatCurrency(quote.rates['Dispatch'].ot_cost)}}</span></div>
                    <div style="font-size:12px;line-height:150%">ST Revenue: <span class="editable-rate" @click="editQuoteRate(['Dispatch','st_revenue'])">{{formatCurrency(quote.rates['Dispatch'].st_revenue)}}</span>, OT Revenue: <span class="editable-rate" @click="editQuoteRate(['Dispatch', 'ot_revenue'])">{{formatCurrency(quote.rates['Dispatch'].ot_revenue)}}</span></div>
                  </v-col>
                  <v-col cols="4"><v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.shipments[index].phase"></v-select></v-col>
                </v-row>

                <v-row>
                  <v-col cols="3">
                    <div style="font-size:12px;line-height:150%">Total Shipment Cost: {{formatCurrency(quote.shipments[index].cost)}}</div>
                    <div style="font-size:12px;line-height:150%">Total Shipment Revenue: {{formatCurrency(quote.shipments[index].revenue)}}</div>
                  </v-col>
                </v-row>
              </div>

              <v-btn color="primary" @click="addShipment()" small>ADD SHIPMENT</v-btn>
            </v-card-text>
          </v-card>


          <!-- EQUIPMENT -->
          <v-card class="section">
            <v-card-text>
              <div class="section-header">EQUIPMENT</div>

              <v-row style="width: 500px">
                <v-col sm="6"><v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" label="Rental Markup %" outlined v-model.number="quote.rental_equipment_markup_percentage" @change="setEquipment()" suffix="%"></v-text-field></v-col>
              </v-row>

              <div v-for="(equipment, index) in quote.equipment" style="margin-bottom:10px;border:2px solid #223773;border-radius:4px;padding:30px 20px;position:relative">
                <v-btn color="primary" @click="deleteEquipment(index)" x-small fab style="position:absolute;top:5px;right:5px;width:20px;height:20px;"><v-icon x-small>mdi-close</v-icon></v-btn>
                <v-row dense>

                  <v-row dense>
                    <v-col cols="4">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Equipment" outlined v-model="quote.equipment[index].description"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Price Per Unit" prefix="$" min="0" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" v-model.number="quote.equipment[index].item_cost" @change="setEquipment()"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-select dense hide-details="auto" :items="['Daily','Weekly','Monthly','Lot']" outlined label="Rate Type" v-model="quote.equipment[index].rate_type" @change="setEquipment()"></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Quantity" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.equipment[index].quantity" @change="setEquipment()"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Duration" outlined type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" min="0" v-model.number="quote.equipment[index].duration" @change="setEquipment()"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="4">
                      <v-text-field dense @focus="$event.target.select()" hide-details="auto" label="Vendor" outlined v-model="quote.equipment[index].vendor"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.equipment[index].phase" @change="setEquipment()"></v-select>
                    </v-col>
                    <v-col cols="2"></v-col>
                    <v-col cols="2"></v-col>
                    <v-col cols="1" style="text-align:right">
                      <div style="line-height:100%">Total Cost</div>
                      {{formatCurrency(quote.equipment[index].total_cost)}}
                    </v-col>
                    <v-col cols="1" style="text-align:right">
                      <div style="line-height:100%">Total Revenue</div>
                      {{formatCurrency(quote.equipment[index].total_revenue)}}
                    </v-col>
                  </v-row>

                </v-row>
              </div>

              <v-btn color="primary" @click="addEquipment()" small>ADD EQUIPMENT</v-btn>
            </v-card-text>
          </v-card>

          <!-- PER DIEM -->
          <v-card class="section">
            <v-card-text style="text-align:left">
              <div class="section-header">PER DIEM</div>

              <v-row>
                <v-col cols="2">
                  <v-select dense hide-details="auto" :items="[{text: 'YES', value: true},{text: 'NO', value: false}]" outlined label="Per Diem Available" v-model="quote.per_diem_required"></v-select>
                  <div v-if="quote.per_diem_required">
                    <div style="color:gray;line-height:150%;font-size:12px">Cost Per Meal: <span class="editable-rate" @click="editQuoteRate(['per_diem_rates','meals'])">{{formatCurrency(quote.rates.per_diem_rates.meals)}}</span></div>
                    <div style="color:gray;line-height:150%;font-size:12px">Cost Per Hotel: <span class="editable-rate" @click="editQuoteRate(['per_diem_rates','hotels'])">{{formatCurrency(quote.rates.per_diem_rates.hotels)}}</span></div>
                  </div>
                </v-col>

                <v-col cols="2" v-if="quote.per_diem_required">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Per Diem Days Needed" outlined min="0" v-model.number="quote.per_diem_days_needed"></v-text-field>
                </v-col>

                <v-col cols="2" v-if="quote.per_diem_required">
                  <v-text-field dense @focus="$event.target.select()" hide-details="auto" type="number" v-on:keydown.up="$event.preventDefault()" v-on:keydown.down="$event.preventDefault()" @wheel="$event.target.blur()" label="Per Diem Markup %" outlined min="0" v-model.number="quote.per_diem_markup_percentage" suffix="%"></v-text-field>
                </v-col>

                <v-col cols="2" v-if="quote.per_diem_required">
                  <v-select dense hide-details="auto" :disabled="quote.use_master_phase" :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]" outlined label="Phase" v-model="quote.per_diem_phase"></v-select>
                </v-col>

                <v-col cols="4" v-if="quote.per_diem_required">
                  <div style="font-size:12px;line-height:150%">Total Per Diem Cost: {{formatCurrency(quote.per_diem_cost_revenue['Phase ' + quote.per_diem_phase].cost)}}</div>
                  <div style="font-size:12px;line-height:150%">Total Per Diem Revenue: {{formatCurrency(quote.per_diem_cost_revenue['Phase ' + quote.per_diem_phase].revenue)}}</div>
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>

          <div id="summary-section">
            <h4>Account: {{db.accounts[quote.account].name}}</h4>
            <h4>Opportunity: {{opportunity.name}}</h4>
            <h4>Quote: {{quote.quote_title}}</h4>
            <h4>Department: {{quote.department}}</h4>

            <div class="section-subheader">LABOR SUMMARY</div>
            <div class="report-page" style="border:4px solid #223773;padding:10px;margin:10px 0px;background:white">
              <v-simple-table dense>
                <thead style="font-weight:bold">
                  <td style="text-align:left">Truck</td>
                  <td style="vertical-align:top">Phase</td>
                  <td>Dispatch</br>Hours</td>
                  <td>Loading</br>Hours</td>

                  <td>Roundtrip</br>Hours</td>
                  <td>Roundtrip</br>Mileage</td>

                  <td>Shipment</br>Cost</td>
                  <td>Shipment</br>Revenue</td>
                </thead>
                <tbody>
                  <tr v-for="shipment in quote.shipments">
                    <td style="padding:5px 0px 0px 0px">
                      <div>{{shipment.truck_owner}} {{shipment.truck_type}}</div>
                      <div>{{shipment.number_of_loads}} {{shipment.load_criteria}} Load{{shipment.number_of_loads > 1 ? 's' : ''}}</div>
                      <div v-if="shipment.charge_type === 'Lump Sum'">{{formatCurrency(shipment.subcontractor_lump_sum)}} Lump Sum</div>
                      <div style="font-style:italic;max-width:200px">{{shipment.description}}</div>
                    </td>
                    <td style="text-align:center">{{shipment.phase}}</td>
                    <td style="text-align:center">{{shipment.dispatch_hours}}</td>
                    <td style="text-align:center">{{shipment.loading_hours}}</td>

                    <td style="text-align:center">{{shipment.charge_type === 'Hourly Rate' ? shipment.roundtrip_hours : '-'}}</td>
                    <td style="text-align:center">{{shipment.charge_type === 'Mileage' ? shipment.roundtrip_mileage : '-'}}</td>

                    <td style="text-align:center">{{formatCurrency(shipment.cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(shipment.revenue)}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid;padding:0">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{totalDispatchHours}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{totalLoadingHours}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{totalRoundtripHours}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{totalRoundtripMileage}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(totalShipmentCost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(totalShipmentRevenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="section-subheader">EQUIPMENT SUMMARY</div>
            <div class="report-page" style="border:4px solid #223773;padding:10px;margin:10px 0px;background:white">
              <v-simple-table dense>
                <thead style="font-weight:bold">
                  <td>Equipment</td>
                  <td>Vendor</td>
                  <td>Rate Type</td>
                  <td>Quantity</td>
                  <td>Duration</td>
                  <td>Phase</td>
                  <td>Total Cost</td>
                  <td>Total Revenue</td>
                </thead>
                <tbody>
                  <tr v-for="(equipment,index) in quote.equipment">
                    <td>{{equipment.description}}</td>
                    <td>{{equipment.vendor}}</td>
                    <td>{{equipment.rate_type}}</td>
                    <td>{{equipment.quantity}}</td>
                    <td>{{equipment.duration}}</td>
                    <td>{{equipment.phase}}</td>
                    <td style="text-align:center">{{formatCurrency(equipment.total_cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(equipment.total_revenue)}}</td>
                  </tr>
                  <tr>
                    <td style="font-weight:bold;border-top:1px solid">TOTAL</td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid"></td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(equipmentTotalCost)}}</td>
                    <td style="font-weight:bold;border-top:1px solid;text-align:center">{{formatCurrency(equipmentTotalRevenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

            <div class="section-subheader">PER DIEM SUMMARY</div>
            <div class="report-page" style="border:4px solid #223773;padding:10px;margin:10px 0px;background:white">
              <div style="font-weight:bold;font-style:italic" v-if="!quote.per_diem_required">Per Diem Not Required for this quote</div>
              <v-simple-table dense v-if="quote.per_diem_required">
                <thead style="font-weight:bold">
                  <td>Per Diem Days Needed</td>
                  <td>Phase</td>
                  <td>Per Diem Total Cost</td>
                  <td>Per Diem Total Revenue</td>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align:center">{{quote.per_diem_days_needed}}</td>
                    <td style="text-align:center">{{quote.per_diem_phase}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.per_diem_cost_revenue['Phase '+quote.per_diem_phase].cost)}}</td>
                    <td style="text-align:center">{{formatCurrency(quote.per_diem_cost_revenue['Phase '+quote.per_diem_phase].revenue)}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>

          </div>

        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="printJobReport()" small>PRINT JOB REPORT</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeForm()" small>CLOSE</v-btn>
          <v-btn color="primary" @click="saveQuote('replace')" small v-if="!view_version" :disabled="!unsavedChanges">SAVE</v-btn>
          <v-btn color="primary" @click="saveQuote('revision')" small v-if="!isNew && !view_version" :disabled="!unsavedChanges">SAVE AS REVISION</v-btn>
          <v-btn color="primary" @click="saveQuote('revert')" small v-if="view_version" :disabled="!unsavedChanges">REVERT BACK TO THIS VERSION</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
    <EditQuoteRate v-if='edit_quote_rate' @close='edit_quote_rate = false' @saveQuoteRate='saveQuoteRate' :initialrate='edit_quote_rate_initial_val'/>
  </v-dialog>

</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import { formatCurrency } from '../../mixins/formatCurrency.js'
import EditQuoteRate from '../EditQuoteRate.vue'
import { jsPDF } from "jspdf";
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas'

export default {
  name: 'Transportation',
  components: {EditQuoteRate},
  mixins: [formatCurrency],
  props:{
    opportunityid: String,
    quoteid: [String, Boolean]
  },
  data(){
    return{
      dialog: true,
      edit_quote_rate: false,
      edit_quote_rate_path_arr: [],
      edit_quote_rate_initial_val: null,
      quote: {
        st_markup_percentage: 80,
        ot_markup_percentage: 20,
        sub_markup_percentage: 18,
        rental_equipment_markup_percentage: 11,
        quote_sheet: 'Transportation',
        department: 'MSM CF Transportation',
        job_id_code: '005',
        quote_type: 'New',
        quote_title: '',
        account: '',
        opportunity: '',
        created_by: '',
        created: '',
        scope_of_work: '',
        clarifications: '',
        phases: {},
        total_cost: 0,
        total_revenue: 0,
        shipments: [],
        equipment: [],
        per_diem_required: false,
        per_diem_markup_percentage: 0,
        per_diem_cost_revenue: {},
        per_diem_days_needed: 0,
        per_diem_phase: 1,
        rates: {},
        use_master_phase: false,
        master_phase: 1,        
        completed: false,
        reviewed: false,
        show_in_master: true
      },
      quote_archive: {},
      initial_quote: {},
      isNew: true,
      view_version: false,
      opportunity: {},
      truck_types: ['Local Small Truck', 'Local Semi-Flat', 'Local Semi-Loboy', 'Local Other', 'Out of State Small Truck', 'Out of State Semi-Flat', 'Out of State Semi-Loboy', 'Out of State Other'],
      form_mounted: false
    }
  },
  beforeMount(){

    var self = this

    Firebase.database().ref('opportunities').child(self.opportunityid).once('value',function(snapshot){
      self.opportunity = JSON.parse(JSON.stringify(snapshot.val()))
      self.isNew = self.quoteid ? false : true

      if(self.isNew){ // new quote
        self.quote.created_by = self.current_user
        self.quote.account = self.opportunity.account
        self.quote.opportunity = self.opportunityid
        self.quote.created = Date.now()
        self.quote.rates = JSON.parse(JSON.stringify(self.db.transportation_rates))
        self.initial_quote = JSON.parse(JSON.stringify(self.quote))
        self.form_mounted = true
      }

      if(!self.isNew){ // edit quote
        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).once('value',function(quote_snapshot){
          self.quote = JSON.parse(JSON.stringify(quote_snapshot.val()))
          self.setEmptyQuoteData()
        }).then(function(){
          Firebase.database().ref('quotes_archive').child(self.opportunityid).child(self.quoteid).once('value',function(quotes_archive_snapshot){
            self.quote_archive = quotes_archive_snapshot.val()
            self.initial_quote = JSON.parse(JSON.stringify(self.quote))
            self.form_mounted = true
          })
        })
      }
    })
  },
  watch: {
    'quote.use_master_phase': function(val){
      if(!this.quote.master_phase){
        this.quote.master_phase = 1
      }
      this.setShipmentsCostRevenue()
      this.setEquipment()
      this.setPerDiemCostRevenue()
    },
    'quote.master_phase': function(val){
      this.setShipmentsCostRevenue()
      this.setEquipment()
      this.setPerDiemCostRevenue()
    },    
    'quote.department': function(val){
      for(var i = 0; i < this.ou_departments.length; i++){
        if(this.ou_departments[i].department === val){
          this.quote.job_id_code = this.ou_departments[i].code
        }
      }
    },
    'quote.ot_markup_percentage': function(val){
      this.quote.st_markup_percentage = 100 - val
      this.setShipmentsCostRevenue()
    },
    'quote.sub_markup_percentage': function(val){
      this.setShipmentsCostRevenue()
    },
    'quote.shipments': {
      deep: true,
      handler(){
        this.setShipmentsCostRevenue()
        this.setPerDiemCostRevenue()
      }
    },
    'quote.per_diem_required': function(val){
      this.setPerDiemCostRevenue()
    },
    'quote.per_diem_days_needed': function(val){
      this.setPerDiemCostRevenue()
    },
    'quote.per_diem_markup_percentage': function(val){
      this.setPerDiemCostRevenue()
    },
    'quote.per_diem_phase': function(val){
      this.setPerDiemCostRevenue()
    },
    'quote.rates': {
      deep: true,
      handler(){
        this.setShipmentsCostRevenue()
        this.setPerDiemCostRevenue()
      }
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      current_user: 'current_user',
      active_accounts: 'active_accounts',
      active_users: 'active_users',
      ou_departments: 'ou_departments'
    }),
    sorted_archive(){
      var arr = []
      var archive = this.quote_archive
      for(var a in archive){
        archive[a].key = a
        arr.push(archive[a])
      }
      return _.orderBy(arr, ['archived'],['asc']);
    },
    unsavedChanges() {
      return JSON.stringify(this.initial_quote) !== JSON.stringify(this.quote)
    },
    equipmentTotalCost(){
      var total = 0
      for(var i = 0; i < this.quote.equipment.length; i++){
        total += this.quote.equipment[i].total_cost
      }
      return total
    },
    equipmentTotalRevenue(){
      var total = 0
      for(var i = 0; i < this.quote.equipment.length; i++){
        total += this.quote.equipment[i].total_revenue
      }
      return total
    },
    totalDispatchHours(){
      var total = 0
      for(var i = 0; i < this.quote.shipments.length; i++){
        total += this.quote.shipments[i].dispatch_hours
      }
      return total
    },
    totalLoadingHours(){
      var total = 0
      for(var i = 0; i < this.quote.shipments.length; i++){
        total += this.quote.shipments[i].loading_hours
      }
      return total
    },
    totalRoundtripHours(){
      var total = 0
      for(var i = 0; i < this.quote.shipments.length; i++){
        total += this.quote.shipments[i].roundtrip_hours
      }
      return total
    },
    totalRoundtripMileage(){
      var total = 0
      for(var i = 0; i < this.quote.shipments.length; i++){
        total += this.quote.shipments[i].roundtrip_mileage
      }
      return total
    },
    totalShipmentCost(){
      var total = 0
      for(var i = 0; i < this.quote.shipments.length; i++){
        total += this.quote.shipments[i].cost
      }
      return total
    },
    totalShipmentRevenue(){
      var total = 0
      for(var i = 0; i < this.quote.shipments.length; i++){
        total += this.quote.shipments[i].revenue
      }
      return total
    }
  },
  methods:{
    closeForm(){
      if(this.unsavedChanges){
        var confirm_close = confirm('You have unsaved changes. Are you sure you want to close this form? Any unsaved changes will be lost.')
        if(confirm_close){
          this.$emit('close')
        }
      }else{
        this.$emit('close')
      }
    },
    printJobReport(){
      var self = this
      self.$store.state.isLoading = true
      html2canvas(document.getElementById("summary-section")).then(function(canvas) {
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF('p', 'mm');
        var position = 10;
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight; // top padding for other pages
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save( 'job_report.pdf', {returnPromise: true}).then(self.$store.state.isLoading = false);
      });
    },
    editQuoteRate(rate_path_arr){
      this.edit_quote_rate_path_arr = rate_path_arr
      this.edit_quote_rate_initial_val = this.quote.rates[rate_path_arr[0]][rate_path_arr[1]]
      this.edit_quote_rate = true
    },
    saveQuoteRate(val){
      this.quote.rates[this.edit_quote_rate_path_arr[0]][this.edit_quote_rate_path_arr[1]] = val
      this.edit_quote_rate_path_arr = []
      this.edit_quote_rate = false
      this.edit_quote_rate_initial_val = null
    },
    setEmptyQuoteData(){
      if(!this.quote.shipments){
        this.$set(this.quote, 'shipments', [])
      }
      if(!this.quote.equipment){
        this.$set(this.quote, 'equipment', [])
      }
      if(!this.quote.per_diem_cost_revenue){
        this.$set(this.quote, 'per_diem_cost_revenue', {})
      }
    },
    addEquipment(){
      var equipment_obj = {
        description: '',
        vendor: '',
        phase: this.quote.use_master_phase ? this.quote.master_phase : 1,
        quantity: 1,
        duration: 1,
        rate_type: 'Daily',
        item_cost: 0,
        total_cost: 0,
        total_revenue: 0
      }
      this.quote.equipment = this.quote.equipment ? this.quote.equipment : []
      this.quote.equipment.push(equipment_obj)
    },
    deleteEquipment(index){
      var confirm_delete = confirm('Are you sure you want to delete this equipment?')
      if(confirm_delete){
        this.quote.equipment.splice(index,1)
        this.setEquipment()
      }
    },
    setEquipment(){
      var rental_markup = this.quote.rental_equipment_markup_percentage / 100
      var total_cost = 0
      var total_revenue = 0

      for(var i = 0; i < this.quote.equipment.length; i++){
        if(this.quote.use_master_phase){
          this.quote.equipment[i].phase = this.quote.master_phase
        }
        this.quote.equipment[i].total_cost = this.quote.equipment[i].item_cost * this.quote.equipment[i].quantity * this.quote.equipment[i].duration
        this.quote.equipment[i].total_revenue = this.quote.equipment[i].total_cost + (this.quote.equipment[i].total_cost * rental_markup)
      }
    },
    deleteShipment(index){
      var confirm_delete = confirm('Are you sure you want to delete this shipment?')
      if(confirm_delete){
        this.quote.shipments.splice(index,1)
        this.setPerDiemCostRevenue()
      }
    },
    viewPreviousVersion(id) {
      var previous_quote = this.quote_archive[id]
      this.quote = previous_quote
      this.view_version = id
      this.setEmptyQuoteData()
    },
    setShipmentsCostRevenue(){
      for(var i = 0; i < this.quote.shipments.length; i++){
        var shipment = this.quote.shipments[i]
        var cost = 0
        var revenue = 0
        var st_rate = this.quote.st_markup_percentage / 100
        var ot_rate = this.quote.ot_markup_percentage / 100
        var sub_rate = this.quote.sub_markup_percentage / 100

        if(this.quote.use_master_phase){
          this.quote.shipments[i].phase = this.quote.master_phase
        }

        if(shipment.charge_type === 'Hourly Rate'){
          cost += shipment.number_of_loads * shipment.roundtrip_hours * (this.quote.rates[shipment.truck_type].st_cost * st_rate)
          cost += shipment.number_of_loads * shipment.roundtrip_hours * (this.quote.rates[shipment.truck_type].ot_cost * ot_rate)
          revenue += shipment.number_of_loads * shipment.roundtrip_hours * (this.quote.rates[shipment.truck_type].st_revenue * st_rate)
          revenue += shipment.number_of_loads * shipment.roundtrip_hours * (this.quote.rates[shipment.truck_type].ot_revenue * ot_rate)
        }

        if(shipment.charge_type === 'Mileage'){
          cost += shipment.number_of_loads * shipment.roundtrip_mileage * this.quote.rates[shipment.truck_type].mile_cost
          revenue += shipment.number_of_loads * shipment.roundtrip_mileage * this.quote.rates[shipment.truck_type].mile_revenue
        }

        if(shipment.charge_type === 'Lump Sum'){
          cost += shipment.number_of_loads * shipment.subcontractor_lump_sum
          revenue += (shipment.number_of_loads * shipment.subcontractor_lump_sum * sub_rate) + (shipment.number_of_loads * shipment.subcontractor_lump_sum)
        }

        cost += shipment.number_of_loads * shipment.dispatch_hours * this.quote.rates['Dispatch'].st_cost * st_rate
        cost += shipment.number_of_loads * shipment.dispatch_hours * this.quote.rates['Dispatch'].ot_cost * ot_rate
        revenue += shipment.number_of_loads * shipment.dispatch_hours * this.quote.rates['Dispatch'].st_revenue * st_rate
        revenue += shipment.number_of_loads * shipment.dispatch_hours * this.quote.rates['Dispatch'].ot_revenue * ot_rate

        cost += shipment.number_of_loads * shipment.loading_hours * this.quote.rates['Loading'].st_cost * st_rate
        cost += shipment.number_of_loads * shipment.loading_hours * this.quote.rates['Loading'].ot_cost * ot_rate
        revenue += shipment.number_of_loads * shipment.loading_hours * this.quote.rates['Loading'].st_revenue * st_rate
        revenue += shipment.number_of_loads * shipment.loading_hours * this.quote.rates['Loading'].ot_revenue * ot_rate


        this.quote.shipments[i].cost = cost
        this.quote.shipments[i].revenue = revenue
      }

    },
    formatDate(date){
      return Moment(date).format('MM/DD/YYYY')
    },
    setDefaultDispatchHours(index){
      this.quote.shipments[index].dispatch_hours = this.quote.shipments[index].truck_owner === 'Mid-State' ? 1 : 1.5
    },
    setChargeType(){

      for(var i = 0; i < this.quote.shipments.length; i++){
        var shipment = this.quote.shipments[i]
        var truck_owner = shipment.truck_owner
        var truck_type = shipment.truck_type

        if(truck_owner === '3rd Party'){
          this.quote.shipments[i].charge_type = 'Lump Sum'
          this.quote.shipments[i].roundtrip_hours = 0
          this.quote.shipments[i].roundtrip_mileage = 0
        }

        if(truck_owner === 'Mid-State' && truck_type.indexOf('Local') === 0){
          this.quote.shipments[i].charge_type = 'Hourly Rate'
          this.quote.shipments[i].roundtrip_mileage = 0
          this.quote.shipments[i].subcontractor_lump_sum = 0
        }

        if(truck_owner === 'Mid-State' && truck_type.indexOf('Out of State') === 0){
          this.quote.shipments[i].charge_type = 'Mileage'
          this.quote.shipments[i].roundtrip_hours = 0
          this.quote.shipments[i].subcontractor_lump_sum = 0
        }
      }
    },
    addShipment(){
      this.quote.shipments = this.quote.shipments ? this.quote.shipments : []

      var shipment_obj = {
                      load_criteria: 'Legal',
                      number_of_loads: 1,
                      description: '',
                      truck_owner: 'Mid-State',
                      truck_type: 'Local Semi-Loboy',
                      charge_type: 'Hourly Rate',
                      roundtrip_hours: 0,
                      roundtrip_mileage: 0,
                      subcontractor_lump_sum: 0,
                      dispatch_hours: 1,
                      loading_hours: 1.5,
                      phase: this.quote.use_master_phase ? this.quote.master_phase : 1,
                      cost: 0,
                      revenue: 0
                     }

      this.quote.shipments.push(shipment_obj)

    },
    setPerDiemCostRevenue(){
      var per_diem = {}
      var per_diem_cost = (this.quote.rates.per_diem_rates.hotels + this.quote.rates.per_diem_rates.meals) * this.quote.per_diem_days_needed
      var per_diem_markup = this.quote.per_diem_markup_percentage ? this.quote.per_diem_markup_percentage / 100 : 0

      if(this.quote.use_master_phase){
        this.quote.per_diem_phase = this.quote.master_phase
      }

      per_diem['Phase ' + this.quote.per_diem_phase] = {}
      per_diem['Phase ' + this.quote.per_diem_phase].cost = per_diem_cost
      per_diem['Phase ' + this.quote.per_diem_phase].revenue = per_diem_cost * per_diem_markup + per_diem_cost
      this.quote.per_diem_cost_revenue = per_diem
    },
    saveQuote(save_type){
      var self = this

      if(self.quote.quote_title.trim() === ''){
        self.$store.state.notification = 'Please provide a title for this quote before saving.'
        return
      }

      var elmos = {
        phases: {},
        total_revenue: 0,
        total_cost: 0,
        total_hours: 0,
        ou_code: this.quote.job_id_code,
        ou_department: this.quote.department
      }

      for(var i = 1; i < 51; i++){
        elmos.phases['Phase ' + i] = {
          equipment : {revenue: 0, cost: 0},
          labor : {revenue: 0, cost: 0, hours: 0},
          material : {revenue: 0, cost: 0},
          other : {revenue: 0, cost: 0},
          subcontracting : {revenue: 0, cost: 0},
          total_revenue: 0,
          total_cost: 0
        }
      }

      // LABOR
      for(var i = 0; i < this.quote.shipments.length; i++){
        var shipment = this.quote.shipments[i]
        elmos.phases['Phase ' + shipment.phase].labor.revenue += shipment.revenue
        elmos.phases['Phase ' + shipment.phase].labor.cost += shipment.cost
        elmos.phases['Phase ' + shipment.phase].labor.hours += (shipment.roundtrip_hours + shipment.dispatch_hours + shipment.loading_hours) * shipment.number_of_loads
        elmos.phases['Phase ' + shipment.phase].total_revenue += shipment.revenue
        elmos.phases['Phase ' + shipment.phase].total_cost += shipment.cost
        elmos.total_revenue += shipment.revenue
        elmos.total_cost += shipment.cost
        elmos.total_hours += (shipment.roundtrip_hours + shipment.dispatch_hours + shipment.loading_hours) * shipment.number_of_loads
      }

      // EQUIPMENT
      for(var i = 0; i < this.quote.equipment.length; i++){
        var equipment = this.quote.equipment[i]
        elmos.phases['Phase ' + equipment.phase].equipment.revenue += equipment.total_revenue
        elmos.phases['Phase ' + equipment.phase].equipment.cost += equipment.total_cost
        elmos.phases['Phase ' + equipment.phase].total_revenue += equipment.total_revenue
        elmos.phases['Phase ' + equipment.phase].total_cost += equipment.total_cost
        elmos.total_revenue += equipment.total_revenue
        elmos.total_cost += equipment.total_cost
      }

      // OTHER
      if(this.quote.per_diem_required){
        for(var p in this.quote.per_diem_cost_revenue){
          elmos.phases[p].other.revenue += this.quote.per_diem_cost_revenue[p].revenue
          elmos.phases[p].other.cost += this.quote.per_diem_cost_revenue[p].cost
          elmos.phases[p].total_revenue += this.quote.per_diem_cost_revenue[p].revenue
          elmos.phases[p].total_cost += this.quote.per_diem_cost_revenue[p].cost
          elmos.total_revenue += this.quote.per_diem_cost_revenue[p].revenue
          elmos.total_cost += this.quote.per_diem_cost_revenue[p].cost
        }
      }

      this.quote.elmos = elmos
      Firebase.database().ref('opportunities').child(self.opportunityid).child('quote_last_modified').set(Date.now())
      // NEW
      if(self.isNew){
        Firebase.database().ref('quotes').child(self.opportunityid).push(self.quote).then(function(snapshot){
          Firebase.database().ref('totals').child(self.opportunityid).child(snapshot.key).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: true, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet}).then(function(){
            self.$emit('close')
          })
        })
      }

      // EDIT & REPLACE
      if(!self.isNew && save_type === 'replace'){
        this.quote.modified = Date.now()
        this.quote.modified_by = this.current_user

        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).set(self.quote).then(function(){
          Firebase.database().ref('totals').child(self.opportunityid).child(self.quoteid).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: self.quote.show_in_master, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet}).then(function(){
            self.$emit('close')
          })
        })
      }

      // EDIT & REVISE
      if(!self.isNew && save_type === 'revision'){
        var original_quote = JSON.parse(JSON.stringify(self.initial_quote))
        original_quote.archived = Date.now()
        original_quote.archived_by = this.current_user

        this.quote.modified = Date.now()
        this.quote.modified_by = this.current_user

        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).set(self.quote).then(function(){
          Firebase.database().ref('quotes_archive').child(self.opportunityid).child(self.quoteid).push(original_quote).then(function(){
            Firebase.database().ref('totals').child(self.opportunityid).child(self.quoteid).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: self.quote.show_in_master, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet})
          })
          self.initial_quote = JSON.parse(JSON.stringify(self.quote))
        })
      }

      if(!self.isNew && save_type === 'revert'){
        this.quote.reverted = Date.now()
        this.quote.reverted_by = this.current_user
        this.initial_quote.archived = Date.now()
        this.initial_quote.archived_by = this.current_user
        Firebase.database().ref('quotes').child(self.opportunityid).child(self.quoteid).set(self.quote).then(function(){
          Firebase.database().ref('quotes_archive').child(self.opportunityid).child(self.quoteid).child(self.view_version).set(self.initial_quote).then(function(){
            Firebase.database().ref('totals').child(self.opportunityid).child(self.quoteid).set({revenue: self.quote.elmos.total_revenue, cost: self.quote.elmos.total_cost, show_in_master: self.quote.show_in_master, completed: self.quote.completed, reviewed: self.quote.reviewed, quote_sheet: self.quote.quote_sheet}).then(function(){
              self.$emit('close')
            })
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .section{
    text-align: left;
    margin: 10px;
  }

  .section-header{
    text-align: left;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .section-subheader{
    text-align: left;
    font-weight: bold;
    padding-top: 20px;
    font-size: 10px;
  }

  #summary-section{
    margin: 10px;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 20px;
    position: relative;
    background: #eeeeee;
    text-align: left;
    width: 850px;
  }

  textarea{
    height: 25px;
  }

  hr{
    margin-top: 20px;
  }

  thead td {
    line-height: 100% !important;
    text-align: center;
    font-size: 12px;
  }

  tr:hover {
    background-color: transparent !important;
  }
</style>


