<template>
  <div class="transfer_ownership">
    <v-container fluid>
      <v-card>
        <v-card-title>TRANSFER OWNERSHIP</v-card-title>
        <div style="padding:0px 25px">
          <v-checkbox dense v-model="transfer.accounts" hide-details="true" label="Transfer Accounts"></v-checkbox>
          <v-checkbox dense v-model="transfer.contacts" hide-details="true" label="Transfer Contacts"></v-checkbox>
          <v-checkbox dense v-model="transfer.opportunities" hide-details="true" label="Transfer Opportunities"></v-checkbox>
          <v-autocomplete style="margin-top:20px;width:260px" sort="name" v-model="transfer.from" :items="active_and_inactive_users" item-text="name" item-value="id" label="From" dense outlined></v-autocomplete>
          <v-autocomplete style="width:260px" sort="name" v-model="transfer.to" :items="active_users" item-text="name" item-value="id" label="To" dense outlined></v-autocomplete>
          <v-card-actions style="width:260px">
            <v-spacer></v-spacer>
            <v-btn color="primary" small @click="$router.push('/admin')">Cancel</v-btn>
            <v-btn color="primary" small @click="startTransfer()" :disabled="!valid_transfer">Transfer</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'transfer_ownership',
  components: {},
  data(){
    return{
      transfer: {
        accounts: false,
        contacts: false,
        opportunities: false,
        from: null,
        to: null
      }
    }
  },
  beforeMount(){
    if(!this.is_admin){
      this.$router.push('/home')
    }
  },
  computed: {
    ...mapGetters({
      db: 'db',
      active_users: 'active_users',
      is_admin: 'is_admin'
    }),
    valid_transfer: function(){
      var valid = true
      if(!this.transfer.accounts && !this.transfer.contacts && !this.transfer.opportunities){
        valid = false
      }
      if(!this.transfer.from || !this.transfer.to){
        valid = false
      }
      return valid
    },
    active_and_inactive_users: function(){
      var users_db_clone = JSON.parse( JSON.stringify( this.db.users) )
      var arr = []

      for(var user_id in users_db_clone){
        var user_object = users_db_clone[user_id]
        user_object.disabled = null
        user_object.name = user_object.first_name + ' ' + user_object.last_name
        user_object.owner_name = this.db.users[user_object.owner].first_name + ' ' + this.db.users[user_object.owner].last_name
        user_object.id = user_id
        arr.push(user_object)
      }
      return arr
    }
  },
  methods:{
    transferOwnership(node,key,to){
      Firebase.database().ref(node).child(key).child('owner').set(to).then(function(){})
    },
    startTransfer(){
      var self = this
      var confirm_transfer = confirm('Are you sure you wanted to transfer ownership from ' + self.db.users[self.transfer.from].first_name + ' ' + self.db.users[self.transfer.from].last_name + ' to ' + self.db.users[self.transfer.to].first_name + ' ' + self.db.users[self.transfer.to].last_name + '?')

      if(!confirm_transfer){return}

      if(self.transfer.opportunities){
        Firebase.database().ref('opportunities').once('value',function(snapshot){

          var opportunities = snapshot.val()

          for(var opp in opportunities){
            if(opportunities[opp].owner === self.transfer.from){
              self.transferOwnership('opportunities',opp,self.transfer.to)
            }
          }

          if(self.transfer.accounts){
            for(var account in self.db.accounts){
              if(self.db.accounts[account].owner === self.transfer.from){
                self.transferOwnership('accounts',account,self.transfer.to)
              }
            }
          }

          if(self.transfer.contacts){
            for(var contact in self.db.contacts){
              if(self.db.contacts[contact].owner === self.transfer.from){
                self.transferOwnership('contacts',contact,self.transfer.to)
              }
            }
          }

          self.$store.state.notification = 'Ownership transfer completed.'
          self.transfer = {accounts: false,contacts: false,opportunities: false,from: null,to: null}
        })
      }else{

        if(self.transfer.accounts){
          for(var account in self.db.accounts){
            if(self.db.accounts[account].owner === self.transfer.from){
              self.transferOwnership('accounts',account,self.transfer.to)
            }
          }
        }

        if(self.transfer.contacts){
          for(var contact in self.db.contacts){
            if(self.db.contacts[contact].owner === self.transfer.from){
              self.transferOwnership('contacts',contact,self.transfer.to)
            }
          }
        }

        self.$store.state.notification = 'Ownership transfer completed.'
        self.transfer = {accounts: false,contacts: false,opportunities: false,from: null,to: null}
      }

    }
  }
}
</script>

<style lang="scss" scoped>


</style>


